import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PageTabItem } from '../../../models/page-tab-item';
import { Pathway } from '../../../models/pathway';
import { DataService } from '../../../services/data.service';
import { Chart } from 'angular-highcharts';
import { AuthenticationService } from '../../../services/authentication.service';
import { GoalService } from '../../../services/goal.service';
import { HcpService } from '../../../services/hcp.service';
import { GoalGraphType, GoalInstance } from '../../../models/goal-instance';
import moment, { Moment } from 'moment';
import 'moment/min/locales';
import { TranslateService } from '@ngx-translate/core';
import { LocaleService } from '../../../services/locale.service';
import { UserTaskService } from '../../../services/user-task.service';
import { UserTaskBasic } from '../../../models/user-task-basic';
import { UserTaskModalService } from '../../../services/user-task-modal.service';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-goal-detail',
  templateUrl: './goal-detail.component.html',
  styleUrls: ['./goal-detail.component.scss']
})
export class GoalDetailComponent implements OnInit {
  public isLoadingGoal: boolean;
  public isLoadingGoalEntries: boolean;
  public pageTabItems: PageTabItem[] = [];

  public pathway: Pathway;
  public patientUid: string;
  public patientPathwayUid: string;
  public goalId: string;

  public goal: GoalInstance;
  public descriptionContent: any;

  public timeScope: Moment;

  public view_type: string;
  public historyChart: Chart;

  public goalUserTaskBasic: UserTaskBasic;

  constructor(
    public activatedRoute: ActivatedRoute,
    public authService: AuthenticationService,
    public dataService: DataService,
    public goalService: GoalService,
    public hcpService: HcpService,
    public translateService: TranslateService,
    public localeService: LocaleService,
    public router: Router,
    public userTaskService: UserTaskService,
    public userTaskModalService: UserTaskModalService
  ) {
  }

  ngOnInit() {
    this.setPageTabItems();
    this.setScopes();

    this.activatedRoute.params.subscribe(params => {
      this.patientUid = params.patientUid;
      this.patientPathwayUid = params.patientPathwayUid;
      this.goalId = params.goalId;

      this.switchViewType();
      this.getGoalUserTasks();
    });
  }

  setPageTabItems() {
    this.pageTabItems = [
      new PageTabItem('Goal', 'zone_goal'),
      new PageTabItem('Details', 'zone_details'),
      new PageTabItem('History', 'zone_history')
    ];
  }

  setScopes() {
    this.localeService.doLocaleConfiguration();
    this.timeScope = moment();
  }

  switchViewType(view_type: string = 'YEAR') {
    if (this.view_type === view_type) {
      return;
    }

    this.view_type = view_type;

    if (this.view_type === 'YEAR') {
      this.timeScope = moment();
    }

    this.getGoal();
  }

  getGoal() {
    if (!this.goal) {
      this.isLoadingGoal = true;
    }

    this.isLoadingGoalEntries = true;

    const viewDate = this.timeScope.toDate();

    const goalObservable = this.authService.hasCcRole()
      ? this.goalService.getDashboardGoalByHospital(this.hcpService.getCurrentStoredHospitalUid(), this.patientUid, this.patientPathwayUid, this.goalId, viewDate, this.view_type)
      : this.goalService.getDashboardGoalByHcp(this.hcpService.getCurrentStoredHcpUid(), this.patientUid, this.patientPathwayUid, this.goalId, viewDate, this.view_type);

    goalObservable.subscribe(result => {
      this.goalLoaded(result);
    }, () => {

    });
  }

  goalLoaded(result) {
    this.goal = result.goal;
    this.dataService.set(DataService.BreadCrumbGoalTitle, this.goal.translationTitleKey);

    this.isLoadingGoal = false;
    this.isLoadingGoalEntries = false;
    this.buildViewtype();

    this.getContent();
  }

  getGoalUserTasks() {
    this.userTaskService.getUserTasks(this.hcpService.getCurrentStoredHcpUid(), {
      goal_id: this.goalId,
      min_priority: 0,
      max_priority: 100
    }).subscribe(result => {
      if (result?.items?.length) {
        this.goalUserTaskBasic = result.items[0];
      }
    });
  }

  getContent() {
    this.goalService.fetchContent(this.goal.description).subscribe(response => {
      this.descriptionContent = response;
    });
  }

  actionGoalUserTask(event) {
    event.preventDefault();
    const modal: BsModalRef = this.userTaskModalService.openUserTask(this.goalUserTaskBasic);

    modal?.content?.taskSubmitSuccess?.subscribe(() => {
      this.goalUserTaskSubmitSuccess();
    });
  }

  goalUserTaskSubmitSuccess() {
    this.goalUserTaskBasic = undefined;
    this.getGoalUserTasks(); // refresh
    this.getGoal(); // refresh
  }

  getTimeScopeLabel() {
    if (!this.view_type || !this.timeScope) {
      return '...';
    }

    if (this.view_type === 'YEAR') {
      return this.timeScope.year();
    } else if (['MONTH', 'WEEK'].includes(this.view_type)) {
      const month = this.timeScope.format('MMM').toLowerCase();
      const monthTranslated = this.translateService.instant(`dates.months_short.${month}`);
      return `${monthTranslated} ${this.timeScope.year()}`;
    }
  }

  nextTimeScope() {
    if (this.view_type === 'YEAR') {
      this.timeScope = moment(this.timeScope).add(1, 'y');
    } else if (this.view_type === 'MONTH') {
      this.timeScope = moment(this.timeScope).add(1, 'M');
    } else if (this.view_type === 'WEEK') {
      this.timeScope = moment(this.timeScope).add(7, 'd');
    }

    this.getGoal();
  }

  prevTimeScope() {
    if (this.view_type === 'YEAR') {
      this.timeScope = moment(this.timeScope).subtract(1, 'y');
    } else if (this.view_type === 'MONTH') {
      this.timeScope = moment(this.timeScope).subtract(1, 'M');
    } else if (this.view_type === 'WEEK') {
      this.timeScope = moment(this.timeScope).subtract(7, 'd');
    }

    this.getGoal();
  }

  buildViewtype() {
    switch (this.view_type) {
      case 'YEAR':
        this.buildHistoryYearChart();
        break;
      case 'MONTH':
        this.buildHistoryMonthChart();
        break;
      case 'WEEK':
        this.buildHistoryWeekChart();
        break;
    }
  }

  getChartBaseParams() {
    const docDirIsRTL = document?.documentElement?.dir === 'rtl';

    const baseParams: any = {
      chart: {
        backgroundColor: 'transparent'
      },

      title: {
        text: null
      },

      credits: {
        enabled: false
      },

      legend: {
        rtl: docDirIsRTL
      },

      yAxis: {
        title: {
          text: null
        },
        opposite: docDirIsRTL
      },

      plotOptions: {
        column: {
          threshold: null,
          softThreshold: true
        },
        series: {
          states: {
            inactive: {
              opacity: 1
            }
          }
        }
      },

      xAxis: this.goalService.getGraphXaxis(docDirIsRTL),
      series: this.goalService.getSeries(this.goal)
    };

    if (this.goal.target_graph === GoalGraphType.LINE) {
      baseParams.plotOptions.column.grouping = false;
    }

    return baseParams;
  }

  buildHistoryYearChart() {
    const params: any = this.getChartBaseParams();
    params.plotOptions.column.maxPointWidth = 16;

    this.historyChart = new Chart(params);
  }

  buildHistoryMonthChart() {
    const params: any = this.getChartBaseParams();
    params.plotOptions.column.maxPointWidth = 16;

    const dayInterval = 86400000;
    params.xAxis.tickInterval = dayInterval;

    const minDate = moment(this.timeScope).startOf('month').toDate();
    const maxDate = moment(this.timeScope).endOf('month').toDate();

    params.xAxis.min = minDate.valueOf();
    params.xAxis.max = maxDate.valueOf();

    this.historyChart = new Chart(params);
  }

  buildHistoryWeekChart() {
    const params: any = this.getChartBaseParams();
    params.plotOptions.column.maxPointWidth = 32;

    const dayInterval = 86400000;
    params.xAxis.tickInterval = dayInterval;

    const minDate = moment(this.timeScope).startOf('week').toDate();
    const maxDate = moment(this.timeScope).endOf('week').toDate();

    params.xAxis.min = minDate.valueOf();
    params.xAxis.max = maxDate.valueOf();

    this.historyChart = new Chart(params);
  }
}
