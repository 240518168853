<div class="pagination-footer d-flex align-items-center">
  <app-pagination-range
    [currentRange]="pagination?.page_size"
    [rangeOptions]="rangeOptions"
    [totalElements]="pagination?.total_elements"
    [currentPage]="pagination?.current_page"
    (rangeChange)="onPaginationRangeChanged($event)"
  ></app-pagination-range>

  <app-pagination
    class="m-start-auto"
    [totalPages]="pagination?.total_pages"
    [activePage]="pagination?.current_page"
    (pageChange)="onPaginationPageChanged($event)"
  ></app-pagination>

</div>
