import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest } from 'rxjs';
import { QueryList, QueryListStatus } from '../../../models/query-list';
import { HcpService } from '../../../services/hcp.service';
import { QueryListService } from '../../../services/query-list.service';
import { TranslateNumberPipe } from '../../../pipes/translate-number.pipe';
import { LanguageService } from '../../../services/language.service';
import { PaginationInterface } from '../../../interfaces/pagination.interface';

@Component({
  selector: 'app-query-list-overview',
  templateUrl: './query-list-overview.component.html'
})
export class QueryListOverviewComponent implements OnInit {
  public patientPathwayUid: string;
  public patientUid: string;
  public hcpUid;
  public filter: string;
  public isLoading = false;
  public queryLists: Array<QueryList> = [];
  public QueryListStatus = QueryListStatus;
  public pagination = {
    current_page: 0,
    page_size: 10
  } as PaginationInterface;
  public sorting = 'status,desc,changed_at,desc';

  constructor(
    public queryListService: QueryListService,
    private route: ActivatedRoute,
    private hcpService: HcpService,
    public translateNumber: TranslateNumberPipe,
    public translateService: TranslateService,
    public languageService: LanguageService
  ) {

  }

  ngOnInit(): void {
    this.hcpUid = this.hcpService.getCurrentStoredHcpUid();

    combineLatest([this.route.params, this.route.queryParams]).subscribe(result => {
      this.patientPathwayUid = result[0].patientPathwayUid;
      this.patientUid = result[0].patientUid;
      this.filter = result[1].filter;
      this.getQueryLists();
    });
  }

  getQueryLists() {
    this.isLoading = true;

    this.queryListService.getQueryLists(
      this.hcpUid, this.patientUid, this.patientPathwayUid, { query_list_type: this.filter }, this.sorting, this.pagination.current_page, this.pagination.page_size)
      .subscribe(result => {
        this.isLoading = false;
        this.queryLists = result.items;

        if (result.pagination) {
          this.pagination = result.pagination;
        }

        if (this.pagination?.total_pages && this.pagination?.current_page && this.pagination?.total_pages <= this.pagination?.current_page) {
          this.pagination.current_page = 0;
          this.getQueryLists();
        }
      });
  }

  grayedOut(form: QueryList) {
    return (form.status === QueryListStatus.COMPLETED || form.status === QueryListStatus.TIMED_OUT);
  }

  onPaginationPageChanged(page: number) {
    if (page !== this.pagination.current_page) {
      this.pagination.current_page = page;
      this.getQueryLists();
    }
  }

  onPaginationRangeChanged(range: number) {
    if (range !== this.pagination.page_size) {
      this.pagination.current_page = 0; // also reset the currentPage
      this.pagination.page_size = range;
      this.getQueryLists();
    }
  }
}
