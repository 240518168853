import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {HospitalService} from '../../services/hospital.service';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';
import {LanguageService} from '../../services/language.service';
import {HcpService} from '../../services/hcp.service';
import {GeneralService} from '../../services/general.service';
import {HcpFunction} from '../../models/hcp-function';
import { LanguageCode } from '../../models/language-code';

@Component({
  selector: 'app-add-hcp-modal',
  templateUrl: './add-hcp-modal.component.html'
})
export class AddHcpModalComponent implements OnInit {
  @Output() onPatientCreated = new EventEmitter<void>();

  languageOptions: LanguageCode[] = [];
  functionOptions: HcpFunction[] = [];

  form: UntypedFormGroup;
  validationVisible = false;
  isSaving = false;
  locationLink = false;

  public dialCodeOptions = [];

  private _closeAnyway = false;
  public get showModalInterceptor(): boolean {
    if (this._closeAnyway) {
      return false;
    }

    return this.form?.dirty;
  }

  constructor(
    public readonly bsModalRef: BsModalRef,
    public readonly hospitalService: HospitalService,
    public readonly hcpService: HcpService,
    public readonly languageService: LanguageService,
    private readonly formBuilder: UntypedFormBuilder,
    public readonly toastService: ToastrService,
    private readonly translate: TranslateService,
    private readonly generalService: GeneralService
  ) {
  }

  ngOnInit(): void {
    this.formSetup();

    const hospitalUid = this.hcpService.getCurrentStoredHospitalUid();
    this.hcpService.getFunctions(hospitalUid).subscribe(functions => {
      this.functionOptions = functions;
    });
    this.languageService.getSupportedAppLanguages().subscribe(languages => {
      this.languageOptions = languages;
    });

    this.dialCodeOptions = this.generalService.dialCodes;
  }

  customSearchFn(term: string, item: any) {
    return true; // always return, searching is done at the backend
  }

  formSetup(): void {
    if (this.form) {
      return;
    }

    this.form = this.formBuilder.group({
      gender: ['', [Validators.required]],
      first_name: ['', [Validators.required]],
      last_name: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      language: ['', [Validators.required]],
      function: ['', [Validators.required]],
      job_title: ['', [Validators.required]],
      phone_number: new UntypedFormGroup({
        code: new UntypedFormControl('', [Validators.required]),
        number: new UntypedFormControl('', [Validators.required])
      }),
      location: new UntypedFormGroup({
        name: new UntypedFormControl('', [Validators.maxLength(50)]),
        url: new UntypedFormControl(''),
        locationLink: new UntypedFormControl(false)
      }),

    });
  }

  onHandleSubmit(): void {
    if (this.isSaving) {
      return;
    }

    if (!this.form.valid) {
      this.validationVisible = true;
      return;
    }

    this.isSaving = true;
    const hospitalUid = this.hcpService.getCurrentStoredHospitalUid();

    const params = this.form.value;

    params.location.name = params.location?.name || null;
    if (!params.location?.locationLink) {
      params.location.url = params.location?.url || null;
    }

    if (params.location.name === null) {
      delete params.location.name;
    }

    if (params.location?.url === null) {
      delete params.location.url;
    }

    if (!params.location.url && !params.location.name) {
      delete params.location;
    }

    this.hospitalService.createHcp(hospitalUid, params).subscribe(() => {
      this.onCreateSuccess();
    }, (error) => {
      this.onRequestError(error);
    });
  }

  onHandleClose(): void {
    return this.bsModalRef.hide();
  }

  onCreateSuccess() {
    this.isSaving = false;

    this.toastService.success(this.translate.instant('modals.add_hcp.hcp_created'));

    this._closeAnyway = true;
    this.bsModalRef.hide();

    this.onPatientCreated.emit();
  }

  onRequestError(error) {
    this.isSaving = false;

    const errorArray = error?.error?.errors;

    if (errorArray) {
      this.validationVisible = true;

      errorArray.forEach( err => {
        const errData = {
          backend_errors: true,
          message: err.key
        };

        switch (err.field) {
          case 'phone_number':
            this.form.get('phone_number').get('number').setErrors(errData);
          break;
          default:
            this.form.get(err.field).setErrors(errData);
        }
      });
    }
  }
}
