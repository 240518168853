import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AuthenticationService } from './authentication.service';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { BulkApproval } from '../models/bulk-approval';
import { TranslateService } from '@ngx-translate/core';
import { BulkAssignmentPost } from '../interfaces/bulk-assignment-post.interface';
import { PagingDto } from '../interfaces/paging-dto.interface';
import { HcpService } from './hcp.service';

@Injectable({
  providedIn: 'root'
})
export class BulkApprovalService extends ApiService {
  private readonly platformUrl: string;

  constructor(
    http: HttpClient,
    public authenticationService: AuthenticationService,
    public translateService: TranslateService,
    public readonly hcpService: HcpService

  ) {
    super(http, authenticationService);
    this.platformUrl = environment.platformUrl;
  }

  getBulkApprovals(
    filters: any = {approved: false, status: [], last_name: '', care_module_uid: []},
    sort = 'submission_date,asc',
    page = 0,
    size = 30): Observable<PagingDto<BulkApproval>> {
    return new Observable(observer => {

      const paramBits = [
        `page=${String(page)}`,
        `size=${String(size)}`
      ];


      /*
      ** Filter - Status - Start
      */
      if (filters.hasOwnProperty('approval_status') && filters.approval_status ) {
        if (Array.isArray(filters.approval_status)) {
          filters.approval_status.forEach(s => {
            paramBits.push(`approval_status=${s}`);
          });
        }
      }
      /*
      ** Filter - Status - End
      */


      /*
      ** Filter - Last Name - Start
      */
      if (filters.hasOwnProperty('last_name') && filters.last_name.length) {
        paramBits.push(`last_name=${filters.last_name}`);
      }
      /*
      ** Filter - Last Name - End
      */


      /*
      ** Filter - Care Module Uid(s) - Start
      */
      const care_module_uid = filters.care_module_uid || filters.care_module_uids;
      if (care_module_uid) {
        if (Array.isArray(care_module_uid)) {
          care_module_uid.forEach(cmu => {
            paramBits.push(`care_module_uid=${cmu}`);
          });
        } else {
          paramBits.push(`care_module_uid=${care_module_uid}`);
        }
      }
      /*
      ** Filter - Care Module Uid(s) - End
      */


      if (sort) {
        paramBits.push(`sort=${sort}`);
      }

      const paramsString = paramBits.join('&');
      const url = `${this.platformUrl}/hospitals/${this.hcpService.getCurrentStoredHospitalUid()}/patients/bulk-approvals?${paramsString}`;

      this.authenticatedGet(url).subscribe(result => {
        observer.next({
          items: result.items?.map(item => new BulkApproval(item)),
          pagination: result.pagination
        });
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }

  updateBulkApprovals(
    params: BulkAssignmentPost
    ): Observable<any> {
    return new Observable(observer => {
      const url = `${this.platformUrl}/hospitals/${this.hcpService.getCurrentStoredHospitalUid()}/patients/bulk-approvals`;

      if (!params.care_module_uid) {
        delete params.care_module_uid;
      }

      if (!params.clinical_lead_uid) {
        delete params.clinical_lead_uid;
      }

      if (!params.case_manager_uid) {
        delete params.case_manager_uid;
      }

      if (!params.mdt_uids) {
        delete params.mdt_uids;
      }

      if (!params.hcp_uids) {
        delete params.hcp_uids;
      }

      this.authenticatedPost(url, params).subscribe(() => {
        setTimeout(() => {
          observer.next();
          observer.complete();
        }, 1000);
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }
}
