import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { Attachment } from '../models/attachment';
import { Note } from '../models/note';
import { ApiService } from './api.service';
import { AuthenticationService } from './authentication.service';
import { PagingDto } from '../interfaces/paging-dto.interface';

@Injectable({
  providedIn: 'root'
})
export class NotesService extends ApiService {
  constructor(
    http: HttpClient,
    authenticationService: AuthenticationService,
  ) {
    super(http, authenticationService);
  }

  // Acces: ROLE_HCP
  getNotesByHcp(
    hcpUid: string,
    patientUid: string,
    patientPathwayUid: string,
    filters: any = {},
    sort = 'changed_at,desc',
    page = 0,
    size = 30): Observable<PagingDto<Note>> {
      const paramBits = [
        `page=${String(page)}`,
        `size=${String(size)}`
      ];

      if (sort) {
        paramBits.push(`sort=${sort}`);
      }

      if (filters.hasOwnProperty('hcp_name') && filters.hcp_name !== null) {
        paramBits.push(`hcp_name=${filters.hcp_name}`);
      }

      const paramsString = paramBits.join('&');
      const url = `${environment.platformUrl}/hcps/${hcpUid}/patients/${patientUid}/patient-pathways/${patientPathwayUid}/consultation-notes?${paramsString}`;

      return new Observable(observer => {
        this.authenticatedGet(url).subscribe(result => {
          observer.next({
            items: result.items?.map(item => new Note(item)),
            pagination: result.pagination
          });
          observer.complete();
        }, error => {
          observer.error(error);
          observer.complete();
        });
      });
  }

  // Acces: ROLE_CC
  getNotesByCc(
    hospitalUid: string,
    patientUid: string,
    patientPathwayUid: string,
    filters: any = {},
    sort = 'changed_at,desc',
    page = 0,
    size = 30): Observable<PagingDto<Note>> {
      const paramBits = [
        `page=${String(page)}`,
        `size=${String(size)}`
      ];

      if (sort) {
        paramBits.push(`sort=${sort}`);
      }

      if (filters.hasOwnProperty('hcp_name') && filters.hcp_name?.length) {
        paramBits.push(`hcp_name=${filters.hcp_name}`);
      }


      // tslint:disable-next-line:max-line-length
      const paramsString = paramBits.join('&');
      const url = `${environment.platformUrl}/hospitals/${hospitalUid}/patients/${patientUid}/patient-pathways/${patientPathwayUid}/consultation-notes?${paramsString}`;

      return new Observable(observer => {
        this.authenticatedGet(url).subscribe(result => {
          observer.next({
            items: result.items?.map(item => new Note(item)),
            pagination: result.pagination
          });
          observer.complete();
        }, error => {
          observer.error(error);
          observer.complete();
        });
      });
  }

   // Acces: ROLE_HCP
   getNoteByHcp(hcpUid: string, patientUid: string, patientPathwayUid: string, noteUid: string): Observable<Note> {
    // tslint:disable-next-line:max-line-length
    const url = `${environment.platformUrl}/hcps/${hcpUid}/patients/${patientUid}/patient-pathways/${patientPathwayUid}/consultation-notes/${noteUid}`;

    return new Observable(observer => {
      this.authenticatedGet(url).subscribe(result => {
        observer.next(new Note(result));
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }

  // Acces: ROLE_CC
  getNoteByCc(hospitalUid: string, patientUid: string, patientPathwayUid: string, noteUid: string): Observable<Note> {
    // tslint:disable-next-line:max-line-length
    const url = `${environment.platformUrl}/hospitals/${hospitalUid}/patients/${patientUid}/patient-pathways/${patientPathwayUid}/consultation-notes/${noteUid}`;

    return new Observable(observer => {
      this.authenticatedGet(url).subscribe(result => {
        observer.next(new Note(result));
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }

  // Access: ROLE HCP
  postNoteByHcp(hcpUid: string, patientUid: string, patientPathwayUid: string, title: string, note: string): Observable<Note> {
    const url = `${environment.platformUrl}/hcps/${hcpUid}/patients/${patientUid}/patient-pathways/${patientPathwayUid}/consultation-notes`;

    const data =  {
      title,
      note
    };

    return new Observable(observer => {
      this.authenticatedPost(url, data).subscribe(result => {
        observer.next(new Note(result));
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }

  // Access: ROLE CC
  postNoteByCc(hospitalUid: string, patientUid: string, patientPathwayUid: string, title: string, note: string): Observable<Note> {
    // tslint:disable-next-line:max-line-length
    const url = `${environment.platformUrl}/hospitals/${hospitalUid}/patients/${patientUid}/patient-pathways/${patientPathwayUid}/consultation-notes`;

    const data =  {
      title,
      note
    };

    return new Observable(observer => {
      this.authenticatedPost(url, data).subscribe(result => {
        observer.next(new Note(result));
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }


   // Access: ROLE HCP
   // tslint:disable-next-line:max-line-length
   updateNoteByHcp(hcpUid: string, patientUid: string, patientPathwayUid: string, noteUid: string,  title: string, note: string): Observable<Note> {
    // tslint:disable-next-line:max-line-length
    const url = `${environment.platformUrl}/hcps/${hcpUid}/patients/${patientUid}/patient-pathways/${patientPathwayUid}/consultation-notes/${noteUid}`;

    const data =  {
      title,
      note
    };

    return new Observable(observer => {
      this.authenticatedPut(url, data).subscribe(result => {
        observer.next(new Note(result));
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }

  // Access: ROLE CC
  // tslint:disable-next-line:max-line-length
  updateNoteByCc(hospitalUid: string, patientUid: string, patientPathwayUid: string, noteUid: string, title: string, note: string): Observable<Note> {
    // tslint:disable-next-line:max-line-length
    const url = `${environment.platformUrl}/hospitals/${hospitalUid}/patients/${patientUid}/patient-pathways/${patientPathwayUid}/consultation-notes/${noteUid}`;

    const data =  {
      title,
      note
    };

    return new Observable(observer => {
      this.authenticatedPut(url, data).subscribe(result => {
        observer.next(new Note(result));
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }

  // Access: ROLE HCP
   // tslint:disable-next-line:max-line-length
   deleteNoteByHcp(hcpUid: string, patientUid: string, patientPathwayUid: string, noteUid: string, reason: string): Observable<Note> {
    // tslint:disable-next-line:max-line-length
    const url = `${environment.platformUrl}/hcps/${hcpUid}/patients/${patientUid}/patient-pathways/${patientPathwayUid}/consultation-notes/${noteUid}/delete`;

    const data =  {
      reason
    };

    return new Observable(observer => {
      this.authenticatedPost(url, data).subscribe(result => {
        observer.next(new Note(result));
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }

  // Access: ROLE CC
  // tslint:disable-next-line:max-line-length
  deleteNoteByCc(hospitalUid: string, patientUid: string, patientPathwayUid: string, noteUid: string, reason: string): Observable<Note> {
    // tslint:disable-next-line:max-line-length
    const url = `${environment.platformUrl}/hospitals/${hospitalUid}/patients/${patientUid}/patient-pathways/${patientPathwayUid}/consultation-notes/${noteUid}/delete`;

    const data =  {
      reason
    };

    return new Observable(observer => {
      this.authenticatedPost(url, data).subscribe(result => {
        observer.next(new Note(result));
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }

  // Attachment
  postAttachment(hcpUid: string, patientUid: string, patientPathwayUid: string, file) {
    const url = `${environment.platformUrl}/hcps/${hcpUid}/patients/${patientUid}/patient-pathways/${patientPathwayUid}/consultation-notes/files`;
    const data =  {
      file
    };

    const formData: FormData = new FormData();
    formData.append('file', file, file.name);

    return new Observable(observer => {
      this.authenticatedPost(url, formData, null, {'content-type': 'multipart/form'}).subscribe(result => {
        observer.next(result);
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      });
    })
  }

  attachAttachmentByHcp(hcpUid: string, patientUid: string, patientPathwayUid: string, noteUid: string, fileUid: string) {
    const url = `${environment.platformUrl}/hcps/${hcpUid}/patients/${patientUid}/patient-pathways/${patientPathwayUid}/consultation-notes/${noteUid}/attachments`;
    const data = {file_uid: fileUid};

    return new Observable(observer => {
      this.authenticatedPost(url, data).subscribe(result => {
        observer.next(new Attachment(result));
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }

  attachAttachmentByHospital(hospitalUid: string, patientUid: string, patientPathwayUid: string, noteUid: string, fileUid: string) {
    const url = `${environment.platformUrl}/hospitals/${hospitalUid}/patients/${patientUid}/patient-pathways/${patientPathwayUid}/consultation-notes/${noteUid}/attachments`;
    const data = {file_uid: fileUid};

    return new Observable(observer => {
      this.authenticatedPost(url, data).subscribe(result => {
        observer.next(new Attachment(result));
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }

  downloadAttachmentByHcp(hcpUid: string, patientUid: string, patientPathwayUid: string, noteUid: string, attachmentUid: string) {
    const url = `${environment.platformUrl}/hcps/${hcpUid}/patients/${patientUid}/patient-pathways/${patientPathwayUid}/consultation-notes/${noteUid}/attachments/${attachmentUid}/download-link`;

    return new Observable(observer => {
      this.authenticatedGet(url).subscribe(result => {
        observer.next(result);
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }

  downloadAttachmentByHospital(hospitalUid: string, patientUid: string, patientPathwayUid: string, noteUid: string, attachmentUid: string) {
    const url = `${environment.platformUrl}/hospitals/${hospitalUid}/patients/${patientUid}/patient-pathways/${patientPathwayUid}/consultation-notes/${noteUid}/attachments/${attachmentUid}/download-link`;

    return new Observable(observer => {
      this.authenticatedGet(url).subscribe(result => {
        observer.next(result);
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }

  deleteAttachmentByHcp(hcpUid: string, patientUid: string, patientPathwayUid: string, noteUid: string, attachmentUid: string)  {
    const url = `${environment.platformUrl}/hcps/${hcpUid}/patients/${patientUid}/patient-pathways/${patientPathwayUid}/consultation-notes/${noteUid}/attachments/${attachmentUid}`;

    return new Observable(observer => {
      this.authenticatedDelete(url).subscribe(result => {
        observer.next();
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }

  deleteAttachmentByHospital(hospitalUid: string, patientUid: string, patientPathwayUid: string, noteUid: string, attachmentUid: string)  {
    const url = `${environment.platformUrl}/hospitals/${hospitalUid}/patients/${patientUid}/patient-pathways/${patientPathwayUid}/consultation-notes/${noteUid}/attachments/${attachmentUid}`;

    return new Observable(observer => {
      this.authenticatedDelete(url).subscribe(result => {
        observer.next();
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }
}
