import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Observable } from "rxjs";
import { environment } from '../../environments/environment';

import { Filters } from "../models/filters";
import { Kpi } from "../models/kpi";
import { PatientDna } from "../models/patient-dna";
import { PhaseDna } from "../models/phase-dna";

import { ApiService } from "./api.service";
import { AuthenticationService } from "./authentication.service";
import { LanguageService } from "./language.service";
import { DnaOnboardingDashboard } from "../models/dna-onboarding-dashboard";
import { AnalyticsPeriod } from "../enums/analytics-period";
import { PagingDto } from '../interfaces/paging-dto.interface';

export enum AnalyticsType {
  ONBOARDING = 'onboarding',
  PATHWAYS_OVERVIEW = 'pathways-overview'
}

export enum AnalyticsData {
  KPI = 'kpis',
  PHASE_PROGRESS = 'phase-progress',
  PATIENT_DETAILS = 'patient-details',
  PATIENT_ONBOARDING = 'patient-onboarding',
  PATIENT_STATUS_OVER_TIME = 'patient-status-over-time'
}

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService extends ApiService {
  private readonly platformUrl: string;

  constructor(
    http: HttpClient,
    public authenticationService: AuthenticationService,
    public translateService: TranslateService,
    public languageService: LanguageService

  ) {
    super(http, authenticationService);
  }

  filterValues(hospital_uid: string, type: AnalyticsType): Observable<Filters> {
    const url = `${environment.platformUrl}/analytics/hospitals/${hospital_uid}/${type}/filter-values`;

    return new Observable(observer => {
      this.authenticatedGet(url).subscribe(result => {
        observer.next(new Filters(result));
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }

  onboarding(
    chart_name: AnalyticsData,
    hospital_uid: string,
    period_type: AnalyticsPeriod,
    period_amount: number,
    period_from: string,
    filters = null,
  ): Observable<DnaOnboardingDashboard> {
    const paramBits = [];

    // period options
    if (period_type) {
      paramBits.push(`period_type=${period_type}`);
    }

    if (period_amount) {
      paramBits.push(`number_of_periods=${period_amount}`);
    }

    if (period_from) {
      paramBits.push(`period_from=${period_from}`);
    }

    // filter options
    if(filters) {
      for (const [key, value] of Object.entries(filters)) {
        const filterLabel = this.getFilterLabel(key);

        if(Array.isArray(value)) {
          value.forEach(v => {
            paramBits.push(`${filterLabel}=${v}`);
          });
        } else {
          paramBits.push(`${filterLabel}=${value}`);
        }
      }
    }

    const paramsString = paramBits.join('&');
    const url = `${environment.platformUrl}/analytics/hospitals/${hospital_uid}/${AnalyticsType.ONBOARDING}/${chart_name}?${paramsString}`;

    return new Observable<DnaOnboardingDashboard>(observer => {
      this.authenticatedGet(url).subscribe(result => {
        observer.next(new DnaOnboardingDashboard(result));
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }

  getFilterLabel(key: string): string {
    // This feels dirty but the key we receive from /filter-values is not the same as the key we need to send.
    // So we have to map the values we know...
    switch (key) {
      case 'countries':
        return 'country';
      case 'genders':
        return 'gender';
      case 'sources':
        return 'patient_source';
      case 'age_groups':
        return 'age_group';
      default:
        return key;
    }
  }

  phaseProgress(
    hospital_uid: string,
    hcp_uid: string,
    care_module_uid: string,
    phase_uid?: string
  ): Observable<any> {
    const paramBits = ['pathway_template_version=1'];

    if (hospital_uid) {
      paramBits.push(`hospital_uid=${hospital_uid}`);
    }

    if (hcp_uid) {
      paramBits.push(`hcp_uid=${hcp_uid}`);
    }

    if (care_module_uid) {
      paramBits.push(`care_module_uid=${care_module_uid}`);
    }

    if (phase_uid) {
      paramBits.push(`phase_id=${phase_uid}`);
    }

    const paramsString = paramBits.join('&');
    const url = `${environment.platformUrl}/analytics/hospitals/${hospital_uid}/${AnalyticsType.PATHWAYS_OVERVIEW}/${AnalyticsData.PHASE_PROGRESS}?${paramsString}`;

    return new Observable(observer => {
      this.authenticatedGet(url).subscribe(result => {
        if (result['sub_phases']) {
          observer.next(this.mapPhasesDna(result['sub_phases']));
        }
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }

  kpis(
    hospital_uid: string,
    hcp_uid: string,
    care_module_uid: string,
    phase_uid?: string
  ): Observable<any> {
    const paramBits = ['pathway_template_version=1'];

    if (hospital_uid) {
      paramBits.push(`hospital_uid=${hospital_uid}`);
    }

    if (hcp_uid) {
      paramBits.push(`hcp_uid=${hcp_uid}`);
    }

    if (care_module_uid) {
      paramBits.push(`care_module_uid=${care_module_uid}`);
    }

    if (phase_uid) {
      paramBits.push(`phase_id=${phase_uid}`);
    }

    const paramsString = paramBits.join('&');
    const url = `${environment.platformUrl}/analytics/hospitals/${hospital_uid}/${AnalyticsType.PATHWAYS_OVERVIEW}/${AnalyticsData.KPI}?${paramsString}`;

    return new Observable(observer => {
      this.authenticatedGet(url).subscribe(result => {
        const kpis = this.mapKpis(result['kpis']);
        this.kpiDetails(kpis).subscribe(kpis => {
          observer.next(kpis);
          observer.complete();
        })
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }

  kpiDetails(kpis: Kpi[]): Observable<Kpi[]> {
    return new Observable(observer => {
      const keys = kpis.map(item => item.key);
      const url = environment.cmsUrl + '/v3/content_types/kpi/entries';
      const locale = this.languageService.getCurrentLanguage().locale;

      this.cmsGet(url, locale, null, keys).subscribe(result => {
        result['entries'].forEach(element => {
          const kpi = kpis.filter(item => item.key == element.uid)[0];
          kpi.description = element.description;
          kpi.title = element.title;
          kpi.unit = element.unit;
        });;

        observer.next(kpis);
        observer.complete();
      });
    });
  }

  patientDetails(
    hospital_uid: string,
    hcp_uid: string,
    care_module_uid: string,
    phase_uid?: string,
    sort = 'last_name,desc',
    page = 0,
    size = 30
  ): Observable<PagingDto<PatientDna>> {
    const paramBits = [
      `page=${String(page)}`,
      `size=${String(size)}`,
      'pathway_template_version=1'
    ];

    if (sort) {
      paramBits.push(`sort=${sort}`);
    }

    if (hospital_uid) {
      paramBits.push(`hospital_uid=${hospital_uid}`);
    }

    if (hcp_uid) {
      paramBits.push(`hcp_uid=${hcp_uid}`);
    }

    if (care_module_uid) {
      paramBits.push(`care_module_uid=${care_module_uid}`);
    }

    if (phase_uid) {
      paramBits.push(`phase_id=${phase_uid}`);
    }

    const paramsString = paramBits.join('&');
    const url = `${environment.platformUrl}/analytics/hospitals/${hospital_uid}/${AnalyticsType.PATHWAYS_OVERVIEW}/${AnalyticsData.PATIENT_DETAILS}?${paramsString}`;

    return new Observable(observer => {
      this.authenticatedGet(url).subscribe(result => {
        observer.next({
          items: result.items?.map(item => new PatientDna(item)),
          pagination: result.pagination
        });
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }

  mapKpis(items): Kpi[] {
    const result = [];
    items.forEach(element => {
      result.push(this.mapKpi(element));
    });

    return result;
  }

  mapKpi(item): Kpi {
    return new Kpi(item);
  }

  mapPhasesDna(items) {
    const result = [];
    items.forEach(item => {
      result.push(this.mapPhaseDna(item));
    });
    result.sort((a, b) => (a.order > b.order) ? 1 : ((b.order > a.order) ? -1 : 0))
    return result;
  };

  mapPhaseDna(item) {
    const phase = new PhaseDna(item);
    phase.title = this.translateService.instant(phase.translationKey);
    return phase;
  }

  mapPatientDetail(item) {
    const patient = new PatientDna(item);
    return patient;
  }

  mapPatientDetails(items) {
    if (!items) {
      return [];
    }

    const result = [];
    items.forEach(item => {
      result.push(this.mapPatientDetail(item));
    });
    return result;
  }
}
