import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PatientDna } from '../../models/patient-dna';
import { PhaseDna } from '../../models/phase-dna';
import { AnalyticsService } from '../../services/analytics.service';
import { HcpService } from '../../services/hcp.service';
import { TranslateNumberPipe } from '../../pipes/translate-number.pipe';
import { LanguageService } from '../../services/language.service';
import { PaginationInterface } from '../../interfaces/pagination.interface';

@Component({
  selector: 'app-dna-patients-table',
  templateUrl: './dna-patients-table.component.html'
})
export class DnaPatientsTableComponent implements OnChanges {
  @Input() phase: PhaseDna;
  @Input() careModuleUid: string;

  public patients: PatientDna[] = [];
  public isLoading = false;
  public pagination = {
    current_page: 0,
    page_size: 10
  } as PaginationInterface;

  public sorting = null; /* 'last_name,asc' */

  constructor(
    public analyticsService: AnalyticsService,
    public hcpService: HcpService,
    public translateNumber: TranslateNumberPipe,
    public translateService: TranslateService,
    public languageService: LanguageService
  ) {
  }

  ngOnChanges(changes: SimpleChanges) {
    this.loadPatients();
  }

  loadPatients(showLoading: boolean = true) {
    if (!this.phase || !this.careModuleUid) {
      return;
    }

    this.isLoading = showLoading;

    if (showLoading || !this.patients) {
      this.patients = [];
    }

    const hcpUid = this.hcpService.getCurrentStoredHcpUid();
    const hospitalUid = this.hcpService.getCurrentStoredHospitalUid();
    this.analyticsService.patientDetails(hospitalUid, hcpUid, this.careModuleUid, this.phase?.uid, this.sorting, this.pagination.current_page, this.pagination.page_size)
      .subscribe({
      next: result => {
        this.patients = result.items;

        if (result.pagination) {
          this.pagination = result.pagination;
        }

        if (this.pagination?.total_pages && this.pagination?.current_page && this.pagination?.total_pages <= this.pagination?.current_page) {
          this.pagination.current_page = 0;
          this.loadPatients();
        }

        this.isLoading = false;
      },
      error: () => {
        this.isLoading = false;
      }
    });
  }

  onChangeSort(event: MouseEvent, sortKey: string, sortDir: string): void {
    event?.preventDefault();
    this.sorting = `${sortKey},${sortDir}`;
    this.pagination.current_page = 0;
    this.loadPatients(false);
  }

  onPaginationPageChanged(page: number) {
    if (page !== this.pagination.current_page) {
      this.pagination.current_page = page;
      this.loadPatients();
    }
  }

  onPaginationRangeChanged(range: number) {
    if (range !== this.pagination.page_size) {
      this.pagination.current_page = 0;
      this.pagination.page_size = range;
      this.loadPatients();
    }
  }
}
