<div class="layout-header-pusher header-back"></div>

<div class="layout-header header-back">
  <div class="header-content">
    <div class="container">
      <app-breadcrumb></app-breadcrumb>
    </div>

    <div class="container">
      <div class="row justify-content-between align-items-center">
        <div class="col col-auto">
          <h5>{{ 'pages.default.administration.tasks.tasks' | translate }}</h5>
        </div>
        <div class="col col-auto">
          <button class="btn btn-primary m-start-1"
                  [disabled]="(!selectedTasksUids || !selectedTasksUids?.length)"
                  id="admin-tasks-reassign"
                  (click)="openAssignModal($event)">{{ 'pages.default.administration.tasks.assign_button' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="body-content">

  <div class="container pb-4">
    <div class="row justify-content-between align-items-center">
      <div class="col col-auto">
        <div class="filters-group">

          <div class="form-group">
            <app-select
              id="dashboard-select-patient"
              class=""
              [selectClass]="'ng-select-sm'"
              [label]="('pages.admin.tasks.patient' | translate)"
              [clearable]="true"
              [searchable]="true"
              [hideSelected]="false"
              [multiple]="false"
              [items]="patients"
              [searchFn]="customSearchFn"
              [bindValue]="'value'"
              [asFilter]="true"
              [showUserDetails]="true"
              [showCheckboxes]="false"
              [showSortOptions]="true"
              [loading]="isLoadingPatients"
              [(ngModel)]="filters.patient_uid"
              (open)="getPatients()"
              (ngModelChange)="onFilterChange()"
              (search)="searchPatientListEvent.next($event)"
              (clear)="clearPatientFilter()"
              (updateSortingDirection)="updatePatientSortingDirection($event)"
            ></app-select>
          </div>

          <div class="divider"></div>

          <div class="form-group">
            <app-select
              id="admin-tasks-select-clinician"
              class=""
              [selectClass]="'ng-select-sm'"
              [label]="('pages.admin.tasks.hcp' | translate)"
              [clearable]="true"
              [searchable]="true"
              [hideSelected]="false"
              [multiple]="false"
              [items]="hcps"
              [searchFn]="customSearchFn"
              [bindValue]="'value'"
              [asFilter]="true"
              [showUserDetails]="true"
              [showCheckboxes]="false"
              [showSortOptions]="true"
              [loading]="isLoadingHcps"
              [(ngModel)]="filters.hcp_uid"
              (open)="getHCPs()"
              (ngModelChange)="onFilterChange()"
              (search)="searchHcpListEvent.next($event)"
              (clear)="clearHcpFilter()"
              (updateSortingDirection)="updateHcpSortingDirection($event)"
            ></app-select>
          </div>

          <div *ngIf="filtersAreSet" class="divider"></div>

          <button (click)="onActionResetFilters()" *ngIf="filtersAreSet" class="btn btn-link text-info py-0"
                  type="button" id="admin-patients-reset-filters">{{ 'action.reset' | translate }}
          </button>
        </div>
      </div>

      <div class="col col-auto">
        <!--
        <div class="btn-group text-dropdown text-dropdown-sm" dropdown>
          <button aria-controls="dropdown-basic" class="btn btn-primary dropdown-toggle" dropdownToggle
                  id="dropdown-btn-sort"
                  type="button">

            <span class="m-end-1">{{ 'general.sort_by' | translate }}:</span>

            <span *ngIf="sorting == 'created_at,asc'">{{ 'form.labels.date_added_sort_asc' | translate }}&#x200E;</span>
            <span *ngIf="sorting == 'created_at,desc'">{{ 'form.labels.date_added_sort_desc' | translate }}&#x200E;</span>

            <span [inlineSVG]="'./assets/svg/chevron-down-24.svg'" class="icon"></span>
          </button>
          <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu" aria-labelledby="dropdown-btn-sort">
            <li role="menuitem"><a class="dropdown-item" href="" (click)="onChangeSort($event, 'created_at', 'asc')">{{ 'form.labels.date_added_sort_asc' | translate }}&#x200E; </a></li>
            <li role="menuitem"><a class="dropdown-item" href="" (click)="onChangeSort($event, 'created_at', 'desc')">{{ 'form.labels.date_added_sort_desc' | translate }}&#x200E; </a></li>
          </ul>
        </div>
        -->
      </div>
    </div>
  </div>


  <div class="container">
    @if (isLoading) {
      <app-page-loader></app-page-loader>
    } @else {
      <div class="block-checkbox pl-2 pb-1">
        <div class="form-check">
          <input (change)="onToggleAllCheckbox()" class="form-check-input"
                 id="checkbox_all"
                 [checked]="selectedAll"
                 type="checkbox">
          <label class="form-check-label" for="checkbox_all">{{ 'general.select_all' | translate }}</label>
        </div>
      </div>

      <div class="e2e-task-list">
        @for (task of tasks; track task.uid) {
          <div id="task-{{$index}}" [ngClass]="{'block-selected': selectedTasksMap.get(task)}"
               class="single-item-block mb-2">

            <div class="block-checkbox">
              <div class="form-check">
                <input (change)="onToggleCheckbox(task)" class="form-check-input"
                       id="checkbox_item_A{{$index}}"
                       type="checkbox" [checked]="selectedTasksMap.get(task)">
                <label class="form-check-label" for="checkbox_item_A{{$index}}"></label>
              </div>
            </div>

            <div class="row g-0 d-flex align-items-center w-100">
              <div class="col-4">
                <div class="block-wrapper">
                  <a href="" class="label text-truncate e2e-task-title" (click)='openUserTask($event, task)'>
                    {{ task.title | translateHelp | translate }}
                  </a>
                  <p
                    class="value text-truncate e2e-caremodule-name">{{ task.care_module?.name | translateHelp | translate }}</p>
                </div>
              </div>

              <div class="col-3">
                <div class="block-wrapper pe-0">
                  <a href="" class="user-compact" id="task-go-to-detail"
                     (click)="goToPatientDetail($event, task?.patient)">
                    <app-avatar class="e2e-profile-picture"
                                [profilePicture]="task.patient?.profile_picture"></app-avatar>

                    <div class="user-compact-info">
                      <p class="title text-truncate e2e-full-name">{{ task.patient.getFullName() }}</p>
                      <p
                        class="text-truncate e2e-date-birth">{{ task.patient?.date_of_birth | date:dateFormat?.format }}</p>
                    </div>
                  </a>
                </div>
              </div>

              <div class="col-3">
                <div class="block-wrapper">
                  <div class="user-compact" *ngIf="task.candidate_users.length < 2">
                    <app-avatar [profilePicture]="task.assignee?.profile_picture"
                                class="e2e-assignee-profile-picture"></app-avatar>

                    <div class="user-compact-info">
                      <p *ngIf="task.assignee?.uid !== hcpUid"
                         class="title text-truncate e2e-assignee-full-name">{{ task.assignee?.getFullName() }}</p>
                      <p *ngIf="task.assignee?.uid === hcpUid"
                         class="title text-truncate text-info e2e-assignee-you">{{ 'pages.default.tasks.you' | translate }}</p>
                      <p class="text-truncate e2e-assignee-job-title">{{ task.assignee?.job_title }}</p>
                    </div>
                  </div>

                  <div class="candidate_users">
                    <ng-container *ngIf="task.candidate_users.length > 1">
                      <app-group-of-avatars [participants]="task.sortedCandidateUsers(hcpUid)"
                                            class="my-1"></app-group-of-avatars>
                    </ng-container>
                  </div>
                </div>
              </div>

              <div class="col-2">
                <div class="block-wrapper">
                  <p
                    class="extra-small fw-medium value text-truncate">{{ 'pages.default.administration.tasks.date' | translate }}</p>
                  <p class="e2e-start-date"
                     [ngClass]="{'status-badge badge-sm danger': task.passed(), 'fw-semibold small text-dark': !task.passed()}">
                    <span class="text-truncate">{{ task.created_at | timeZoneDate }}</span>
                  </p>
                </div>
              </div>
            </div>

            <div class="block-btn-wrapper actions">
              <div class="dropdown" dropdown placement="top right">
                <button id="button-task-more-actions" dropdownToggle type="button" class="block-action btn btn-tertiary"
                        aria-controls="dropdown-task-more-actions">
                  <span [inlineSVG]="'./assets/svg/more-24.svg'"></span>
                </button>
                <ul id="dropdown-task-more-actions" *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu"
                    aria-labelledby="button-task-more-actions">
                  <li role="menuitem">
                    <a class="dropdown-item" href=""
                       (click)="goToPatientDetail($event, task.patient)">{{ 'pages.default.administration.tasks.gotopatient' | translate }}</a>
                  </li>
                  <li role="menuitem">
                    <a class="dropdown-item" href=""
                       (click)="openAssignModal($event, task.uid)">{{ 'pages.default.administration.tasks.assign' | translate }}</a>
                  </li>
                </ul>
              </div>

              <a (click)='openUserTask($event, task)' class="block-action btn btn-tertiary"
                 [id]="'admin-open-task' + task.uid" href="">
                <span [inlineSVG]="'./assets/svg/arrow-right-24.svg'" class="rtl-mirrored-inline-svg"></span>
              </a>
            </div>
          </div>
        } @empty {
          <div class="empty-state my-5 my-lg-7">
            <span [inlineSVG]="'./assets/svg-color/no-notifications-graphic.svg'"></span>
            <h6 class="h7">{{ 'pages.default.tasks.no_tasks_yet' | translate }}</h6>
          </div>
        }
      </div>
    }
  </div>

  @if (!isLoading && pagination?.total_elements) {
    <div class="container align-items-center mt-auto">
      <div class="mt-4"></div>

      <app-pagination-footer
        [pagination]="pagination"
        (pageChange)="onPaginationPageChanged($event)"
        (rangeChange)="onPaginationRangeChanged($event)"
      ></app-pagination-footer>
    </div>
  }
</div>
