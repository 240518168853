<div class="layout-header-pusher header-title-only"></div>

<div class="layout-header header-title-only">
  <div class="header-content">
    <div class="container">
      <div class="row justify-content-between align-items-center">
        <div class="col col-auto">
          <h5>{{ 'pages.default.dashboard.patients' | translate }}</h5>
        </div>
        <div class="col col-auto">
          <button type="button" (click)="openAddPatientModal()" *ngIf="canCreatePatient()"
                  class="btn btn-primary">{{ 'pages.default.dashboard.add_patient' | translate }}
          </button>
        </div>

      </div>
    </div>
  </div>
</div>

<div class="body-content">
  <!--  -->
  <!-- Filters - Start -->
  <!--  -->
  <div class="container pb-4">
    <div class="row justify-content-between align-items-center">
      <div class="col col-auto">

        <div class="filters-group">

          <div class="form-group form-group-search">
            <label for="searchPatientLastName">{{ 'form.labels.patient_last_name' | translate }}</label>
            <input
              type="search"
              id="searchPatientLastName"
              class="form-control form-control-sm"
              [placeholder]="'form.labels.search' | translate"
              [(ngModel)]="filters.last_name"
              (ngModelChange)="patientLastNameSearch.next($event)"
              required
            />
          </div>

          <div class="form-group">

            <app-select
              id="dashboard-select-clinician"
              [label]="('pages.default.dashboard.hcp' | translate)"
              [selectClass]="'ng-select-sm'"
              [clearable]="true"
              [searchable]="true"
              [hideSelected]="false"
              [multiple]="false"
              [items]="hcps"
              [searchFn]="customSearchFn"
              [bindValue]="'value'"
              [loading]="isLoadingHCPs"
              [showSortOptions]="true"
              [asFilter]="true"
              [showUserDetails]="true"
              [showCheckboxes]="false"
              [(ngModel)]="filters.patient_mdt_hcp_uid"
              (ngModelChange)="onFilterChange()"
              (search)="hcpLastNameSearch.next($event)"
              (open)="getHCPs()"
              (clear)="clearHcpFilter()"
              (updateSortingDirection)="updateHcpSortingDirection($event)"
            ></app-select>
          </div>

          <div class="form-group">
            <app-select
              id="dashboard-select-pathway"
              [label]="('general.pathways' | translate)"
              [selectClass]="'ng-select-sm'"
              [clearable]="true"
              [searchable]="true"
              [hideSelected]="false"
              [multiple]="true"
              [items]="careModules"
              [bindValue]="'value'"
              [showSortOptions]="true"
              [asFilter]="true"
              [showCheckboxes]="true"
              [autoSortOnLabel]="true"
              [(ngModel)]="filters.care_module_uids"
              (ngModelChange)="onFilterChange()"
            ></app-select>
          </div>

          <button type="reset" class="btn btn-link" id="dashboard-action-reset-filters" *ngIf="filtersAreSet"
                  (click)="actionResetFilters()">{{ 'action.reset' | translate }}
          </button>
        </div>

      </div>
      <div class="col col-auto">
        <div class="filters-group">
          <div class="form-group">
            <app-select
              id="dropdown-btn-sort"
              [label]="'general.sort_by' | translate"
              [selectClass]="'ng-select-sm'"
              [clearable]="false"
              [searchable]="false"
              [hideSelected]="false"
              [multiple]="false"
              [items]="patientSortingOptions"
              [bindValue]="'value'"
              [asFilter]="false"
              [(ngModel)]="patientSorting"
              (ngModelChange)="onChangeSortDirection()"
            ></app-select>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--  -->
  <!-- Filters - End -->
  <!--  -->

  <div class="container">
    @if (isLoading) {
      <app-page-loader></app-page-loader>
    } @else {
      @for (patient of patients; track patient.uid) {
        <app-patient-tabbed-details [ngClass]="{'mb-2': !$last}" [patient]="patient"></app-patient-tabbed-details>
      } @empty {
        <div class="empty-state my-5 my-lg-7">
          <span [inlineSVG]="'./assets/svg-color/no-notifications-graphic.svg'"></span>
          <h6 class="h7">{{ 'pages.default.dashboard.no_patients_yet' | translate }}</h6>
        </div>
      }
    }
  </div>

  @if (pagination?.total_elements) {
    <div class="container align-items-center mt-auto">
      <div class="mt-4"></div>

      <app-pagination-footer
        [pagination]="pagination"
        [rangeOptions]="[10, 5]"
        (pageChange)="onPaginationPageChanged($event)"
        (rangeChange)="onPaginationRangeChanged($event)"
      ></app-pagination-footer>
    </div>
  }
</div>
