<div class="pagination-range-component d-flex align-items-center">
  <ng-select
    class="ng-select-sm"
    [clearable]="false"
    [searchable]="false"
    (change)="onRangeChange($event)"
    [(ngModel)]="currentRange">

    <ng-option *ngFor="let option of rangeOptions" [value]="option">
      {{ option | translateNumber }} / {{ 'components.pagination.page' | translate }}
    </ng-option>
  </ng-select>

  <div class="m-start-2" id="total-elements">
<!--    TODO: RTL -->
    <span class="font-weight-semibold">{{ currentElementsRange }}</span> {{ 'components.pagination.of' | translate }} <span class="font-weight-semibold">{{ totalElements }}</span>
  </div>
</div>
