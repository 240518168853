import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { HealthCareProfessional } from '../../models/health-care-professional';
import { HcpService } from '../../services/hcp.service';
import { UserTaskService } from '../../services/user-task.service';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-assign-task-modal',
  templateUrl: './assign-task-modal.component.html'
})
export class AssignTaskModalComponent implements OnInit {
  public form: FormGroup = new FormGroup({
    hcp: new FormControl('', Validators.required)
  });
  public isSaving = false;
  public isLoadingHcps = false;

  public validationVisible = false;
  public hcpList: Array<HealthCareProfessional> = [];
  public taskUids: string[];
  public searchHcpListEvent = new EventEmitter<{ term: string, items: any[] }>();

  @Output() assignmentSuccessEvent = new EventEmitter<{ tasks: string[], hcp: HealthCareProfessional }>();
  @Output() assignmentFailedEvent = new EventEmitter<{ failed: {task_id: string, reason_key: string}[], succeeded: string[], hcp: HealthCareProfessional }>();

  constructor(
    public bsModalRef: BsModalRef,
    public hcpService: HcpService,
    public userTaskService: UserTaskService,
    public toastrService: ToastrService,
    public translate: TranslateService
  ) { }

  ngOnInit(): void {
    this.searchHcpListEvent.pipe(
      debounceTime(400),
      distinctUntilChanged()
    ).subscribe(result => {
      this.searchHcpList(result);
    });
  }

  searchHcpList(event?) {
    let term = '';
    if (event && event['term'] && event['term'].length) {
      term = event['term'];
    } else {
      this.hcpList = [];
    }

    this.isLoadingHcps = true;

    this.hcpService.getPaged({hcpLastName: term}).subscribe({next: response => {
      this.hcpList = response.items;
    }, complete: () => this.isLoadingHcps = false});
  }

  handleClose() {
    return this.bsModalRef.hide();
  }

  handleSubmit() {
    if (!this.form.valid) {
      this.validationVisible = true;
      return;
    }

    this.isSaving = true;

    const hospitalUid = this.hcpService.getCurrentStoredHospitalUid();
    const hcpUid = this.form.value.hcp;

    this.userTaskService.assignUserTasks(hospitalUid, hcpUid, this.taskUids).subscribe(result => {
      if (result && result.hasOwnProperty('failed_assignments')) {
        this.failedHandler(result.failed_assignments, this.taskUids);
      } else {
        this.successHandler();
      }
    }, () => this.errorHandler());

  }


  successHandler() {
    const hcpUid = this.form.value.hcp;
    const hcp = this.hcpList.find((hcp: HealthCareProfessional) => hcp.uid === hcpUid);
    this.assignmentSuccessEvent.emit({ tasks: this.taskUids, hcp } );
    this.isSaving = false;
    this.toastrService.success(this.translate.instant('pages.default.administration.user_task_assign.success_notif'));
    this.bsModalRef.hide();
  }


  failedHandler(failed: {task_id: string, reason_key: string}[], allTasks: string[]) {
    const hcpUid = this.form.value.hcp;
    const hcp = this.hcpList.find((hcp: HealthCareProfessional) => hcp.uid === hcpUid);
    const key = (failed.length === 1) ? 'modals.assign_task_failed_single' : 'modals.assign_task_failed';

    this.toastrService.error(this.translate.instant(key, {value: failed.length}));

    const failedIds: Array<string> = failed.map((task) => task.task_id);
    const succeeded = allTasks.filter((task) => !failedIds.includes(task));

    this.assignmentFailedEvent.emit( { failed, succeeded, hcp } );
    this.bsModalRef.hide();
  }

  errorHandler() {
    this.isSaving = false;
    this.bsModalRef.hide();
  }

  customSearchFn(term: string, item: any) {
    return true; // always return, searching is done at the backend
  }
}
