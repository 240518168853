import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Patient } from '../../models/patient';
import { PatientService } from '../../services/patient.service';
import { PathwayService } from '../../services/pathway.service';
import { Pathway } from '../../models/pathway';
import { MedicalTeam } from '../../models/medical-team';
import { HealthCareProfessional } from '../../models/health-care-professional';
import { HcpService } from '../../services/hcp.service';
import { ConversationService } from '../../services/conversation.service';
import { Conversation } from '../../models/conversation';
import { ErrorService } from '../../services/error.service';
import { AuthenticationService } from '../../services/authentication.service';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-start-new-conversation-modal',
  templateUrl: './start-new-conversation-modal.component.html',
  styleUrls: ['./start-new-conversation-modal.component.scss']
})
export class StartNewConversationModalComponent implements OnInit {
  @Output() public onCreate: EventEmitter<Conversation> = new EventEmitter();

  public currentHcp: HealthCareProfessional;
  public checkIsRegardingPatient: boolean;
  public isPatientSelected: boolean;

  public formPatient: UntypedFormGroup;
  public formHcps: UntypedFormGroup;

  public patientsList: Array<Patient>;
  public generalSearchHcpsList: Array<HealthCareProfessional>;
  public patientsPathwaysList: Array<any>;
  public currentPathway: Pathway;
  public currentPatient: Patient;
  public selectedHcps: any;
  public losslessHcpSelection: HealthCareProfessional[];

  public losslessHcpSelectModel: any;

  public isCreating: boolean;
  public isLoadingPathway: boolean;
  public isloadingPatients = false;
  public isloadingHcps = false;
  public validationVisible: boolean;
  public subjectMaxLength = 48;

  public mdts: MedicalTeam[] = [];
  public otherHcps: HealthCareProfessional[] = [];

  public searchPatientListEvent = new EventEmitter<{ term: string, items: any[] }>();
  public searchHcpListEvent = new EventEmitter<{ term: string, items: any[] }>();

  private _closeAnyway = false;
  public get showModalInterceptor(): boolean {
    if (this._closeAnyway) {
      return false;
    }

    return this.formPatient?.dirty || this.formHcps?.dirty || this.losslessHcpSelection.length > 0;
  }

  constructor(
    public bsModalRef: BsModalRef,
    public formBuilder: UntypedFormBuilder,
    public hcpService: HcpService,
    public patientService: PatientService,
    public pathwayService: PathwayService,
    public conversationService: ConversationService,
    public errorService: ErrorService,
    public authenticationService: AuthenticationService
  ) { }

  ngOnInit(): void {
    this.checkIsRegardingPatient = true;
    this.getScope();
    this.formsSetup();
    this.setup();

    this.searchPatientListEvent.pipe(
      debounceTime(400),
      distinctUntilChanged()
    ).subscribe(result => {
      this.searchPatients(result);
    });

    this.searchHcpListEvent.pipe(
      debounceTime(400),
      distinctUntilChanged()
    ).subscribe(result => {
      this.searchHCPList(result);
    });
  }

  getScope() {
    this.currentHcp = this.hcpService.getCurrentStoredHcp();
  }

  onRegardingPatientCheckChange() {
    this.setup();
  }

  formsSetup() {
    this.formPatient = this.formBuilder.group({
      patient: ['', [Validators.required]],
      pathway_compact: ['', [Validators.required]],
      subject: ['', [Validators.required, Validators.maxLength(this.subjectMaxLength)]]
    });

    this.formHcps = this.formBuilder.group({
      subject: ['', [Validators.required, Validators.maxLength(this.subjectMaxLength)]]
    });
  }

  setup() {
    this.formPatient.reset();
    this.formHcps.reset();

    this.patientsList = [];
    this.patientsPathwaysList = [];
    this.selectedHcps = {};
    this.currentPathway = undefined;
    this.losslessHcpSelection = [];
    this.validationVisible = false;

    if (this.checkIsRegardingPatient) {
      // this.searchPatients();
    } else {
      // this.searchHCPList();
    }
  }

  searchPatients(event?) {
    let term = '';

    if (event && event['term'] && event['term'].length) {
      term = event['term'];
    } else {
      this.patientsList = [];
      this.patientsPathwaysList = [];
      this.currentPathway = undefined;
      this.formPatient.get('pathway_compact').setValue(undefined);
    }

    this.isloadingPatients = true;

    if (this.authenticationService.hasCcRole()) {
      const hospitalUid = this.hcpService.getCurrentStoredHospitalUid();
      this.patientService.getPatientsByHospital(
        hospitalUid,
        {
          last_name: term,
          status: ['ACTIVE', 'CONSENT_PENDING'],
          extensions: 'PATIENT_PATHWAYS'
        },
        'last_name,desc;first_name,desc',
        0,
        50
      ).subscribe(resp => {
        this.searchPatientsResultHandler(resp);
      });
    } else {
      this.patientService.getPatientsByHcp(
        this.currentHcp.uid,
        {
          last_name: term,
          status: ['ACTIVE', 'CONSENT_PENDING'],
          extensions: 'PATIENT_PATHWAYS'
        },
        'last_name,desc;first_name,desc',
        0,
        50
      ).subscribe(resp => {
        this.searchPatientsResultHandler(resp);
      });
    }
  }

  searchPatientsResultHandler(resp) {
    this.patientsList = resp.items;
    this.isloadingPatients = false;
  }

  patientSelected() {
    const patient: Patient = this.formPatient.get('patient').value;
    this.currentPatient = patient;
    this.isPatientSelected = true;

    this.formPatient.get('pathway_compact').setValue(undefined);
    this.currentPathway = undefined;

    if (patient && patient.pathways && patient.pathways.length) {
      this.patientsPathwaysList = patient.pathways;

      if (this.patientsPathwaysList.length === 1) {
        this.selectFirstCompactPathway();
      }
    } else {
      this.patientsPathwaysList = [];
    }
  }

  selectFirstCompactPathway() {
    this.formPatient.get('pathway_compact').setValue(this.patientsPathwaysList[0]);
    this.patientPathwaySelected();
  }

  patientPathwaySelected() {
    this.currentPathway = undefined;
    const hasCcRole = this.authenticationService.hasCcRole();

    const patient: Patient = this.formPatient.get('patient').value;
    const pathway: Pathway = this.formPatient.get('pathway_compact').value;

    if (pathway) {
      this.isLoadingPathway = true;

      const pathWayObservable = hasCcRole
        ? this.pathwayService.getPathwayByHospital(this.hcpService.getCurrentStoredHospitalUid(), patient.uid, pathway.uid)
        : this.pathwayService.getPathwayByHcp(this.hcpService.getCurrentStoredHcpUid(), patient.uid, pathway.uid);

      pathWayObservable.subscribe(resp => {
        this.getPatientPathwayResultHandler(resp);
      });
    } else {
      this.currentPathway = undefined;
    }
  }

  getPatientPathwayResultHandler(pathway: Pathway) {
    this.isLoadingPathway = false;
    this.currentPathway = pathway;
    this.mdts = this.currentPathway.patient_mdt?.getMdts(true, [this.currentPathway.clinical_lead.uid, this.currentPathway.case_manager.uid, this.currentHcp.uid]);
    this.otherHcps = this.currentPathway.patient_mdt?.getOtherHcps(true, [this.currentPathway.clinical_lead.uid, this.currentPathway.case_manager.uid, this.currentHcp.uid]);
  }

  selectEveryone() {
    this.isPatientSelected = true;

    this.selectedHcps[this.currentPathway?.clinical_lead?.uid] = true;
    this.selectedHcps[this.currentPathway?.case_manager?.uid] = true;

    if (this.currentPathway.patient_mdt.mdts) {
      this.currentPathway.patient_mdt.mdts.forEach(mdt => {
        this.selectAllFromMdt(mdt);
      });

      this.selectAllFromGroup();
    }
  }

  selectAllFromMdt(mdt: MedicalTeam) {
    if (mdt.hcps) {
      mdt.hcps.forEach(hcp => {
        this.selectedHcps[hcp.uid] = true;
      });
    }
  }

  selectAllFromGroup() {
    if (this.currentPathway.patient_mdt.otherHcps) {
      this.currentPathway.patient_mdt.otherHcps.forEach(hcp => {
        this.selectedHcps[hcp.uid] = true;
      });
    }
  }

  togglePatient() {
    this.isPatientSelected = !this.isPatientSelected;
  }

  togglePatientHcp(hcp: HealthCareProfessional) {
    if (hcp.uid !== this.currentHcp.uid) {
      if (this.selectedHcps[hcp.uid]) {
        this.selectedHcps[hcp.uid] = false;
      } else {
        this.selectedHcps[hcp.uid] = true;
      }
    }
  }

  searchHCPList(event?) {
    let term = '';
    if (event && event['term'] && event['term'].length) {
      term = event['term'];
    } else {
      this.generalSearchHcpsList = [];
    }

    this.isloadingHcps = true;
    this.hcpService.getPaged({ hcpLastName: term, status: 'ACTIVE' }, 'last_name,asc', 0, 50).subscribe({
      next: response => {
        this.generalSearchHcpsList = response.items.filter(hcp => (hcp.uid !== this.currentHcp.uid));
      }, complete: () => this.isloadingHcps = false
    });
  }

  onLosslessHcpSelect(hcp): void {
    if (hcp && !this.isHcpSelected(hcp)) {
      this.losslessHcpSelection.push(hcp);
      this.selectedHcps[hcp.uid] = true;
    }

    setTimeout(() => {
      this.losslessHcpSelectModel = null;
    });
  }

  removeLosslessHcp(hcp: any) {
    const index = this.losslessHcpSelection.indexOf(hcp);

    if (index >= 0) {
      this.losslessHcpSelection.splice(index, 1);
      this.selectedHcps[hcp.uid] = false;
    }
  }

  isHcpSelected(hcp: HealthCareProfessional) {
    return this.selectedHcps[hcp.uid];
  }

  isModalValid(): boolean {
    if (this.checkIsRegardingPatient && this.isPatientSelected) {
      return this.formPatient.valid;
    } else if (this.checkIsRegardingPatient && !this.isPatientSelected) {
      return (this.getSelectedHcpUids().length > 0 && this.formPatient.valid);
    } else {
      return this.getSelectedHcpUids().length > 0 && this.formHcps.valid;
    }
  }

  getSelectedHcpUids() {
    const selectedHcpUids = [];

    Object.keys(this.selectedHcps).forEach(key => {
      if (this.selectedHcps[key]) {
        selectedHcpUids.push(key);
      }
    });

    return selectedHcpUids;
  }

  handleSubmit() {
    if (!this.isModalValid()) {
      this.validationVisible = true;
    } else {
      this.handleCreateConversation();
    }
  }

  handleCreateConversation() {
    this.isCreating = true;

    const payload: any = {
      hcp_uids: this.getSelectedHcpUids()
    };

    if (this.checkIsRegardingPatient && this.isPatientSelected) {
      payload.subject = this.formPatient.get('subject').value;
      payload.conversation_type = 'PATIENT_CONVERSATION';
      payload.patient_pathway_id = this.currentPathway.uid;
      payload.patient_uid = this.currentPatient.uid;
      payload.all_hcps = false;
    } else if (this.checkIsRegardingPatient && !this.isPatientSelected) {
      payload.subject = this.formPatient.get('subject').value;
      payload.conversation_type = 'HCP_CONVERSATION';
    } else {
      payload.subject = this.formHcps.get('subject').value;
      payload.conversation_type = 'HCP_CONVERSATION';
    }

    this.conversationService.addConversation(payload).subscribe(
      con => this.createConversationResultHandler(con),
      err => this.createConversationErrorHandler(err)
    );
  }

  createConversationResultHandler(conversation: Conversation) {
    this.onCreate.emit(conversation);
    this.isCreating = false;

    this._closeAnyway = true;
    this.bsModalRef.hide();
  }

  createConversationErrorHandler(error: any) {
    this.errorService.showGeneralBackendErrorToast();
    this.isCreating = false;

    this._closeAnyway = true;
    this.bsModalRef.hide();
  }

  customSearchFn(term: string, item: any) {
    return true; // always return, searching is done at the backend
  }

  handleCancel() {
    return this.bsModalRef.hide();
  }
}
