import { Component, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { AssignTaskModalComponent } from '../../../../modals/assign-task-modal/assign-task-modal.component';
import { UserTaskAppointmentModalComponent } from '../../../../modals/user-task-appointment-modal/user-task-appointment-modal.component';
import { UserTaskStandardModalComponent } from '../../../../modals/user-task-standard-modal/user-task-standard-modal.component';
import { DateFormat } from '../../../../models/date-format';
import { HealthCareProfessional } from '../../../../models/health-care-professional';
import { Patient } from '../../../../models/patient';
import { UserTaskBasic } from '../../../../models/user-task-basic';
import { GeneralService } from '../../../../services/general.service';
import { HcpService } from '../../../../services/hcp.service';
import { LocaleService } from '../../../../services/locale.service';
import { PatientService } from '../../../../services/patient.service';
import { UserTaskService } from '../../../../services/user-task.service';
import { TranslateNumberPipe } from '../../../../pipes/translate-number.pipe';
import { LanguageService } from '../../../../services/language.service';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { SortingDirection } from '../../../../enums/sorting-direction';
import { PaginationInterface } from '../../../../interfaces/pagination.interface';

@Component({
  selector: 'app-admin-tasks',
  templateUrl: './admin-tasks.component.html'
})
export class AdminTasksComponent implements OnInit, OnDestroy {
  public selectedTasksMap = new Map<UserTaskBasic, boolean>();
  public selectedTasksUids: string[] = [];
  public selectedAll = false;
  public pagination = {
    current_page: 0,
    page_size: 20
  } as PaginationInterface;
  public isLoading = false;
  public isLoadingPatients = false;
  public isLoadingHcps = false;
  public filters = {
    hcp_uid: undefined,
    patient_uid: undefined,
    max_priority: UserTaskService.OVERVIEW_MAX_PRIORITY,
    min_priority: UserTaskService.OVERVIEW_MIN_PRIORITY
  };
  public filtersAreSet = false;
  public patientFilterSearchTerm: string;
  public patientFilterSortingDir: SortingDirection = SortingDirection.ASC;
  public hcpFilterSearchTerm: string;
  public hcpFilterSortingDir: SortingDirection = SortingDirection.ASC;
  public hcpUid: string;
  public hospitalUid: string;
  public sorting = 'created_at,asc';
  public tasks: UserTaskBasic[];
  public dateFormat: DateFormat;
  public hcps: Array<HealthCareProfessional>;
  public patients: Array<Patient>;

  public searchPatientListEvent = new EventEmitter<{ term: string, items: any[] }>();
  public searchHcpListEvent = new EventEmitter<{ term: string, items: any[] }>();

  constructor(
    public hcpService: HcpService,
    public userTaskService: UserTaskService,
    public localeService: LocaleService,
    public router: Router,
    public modalService: BsModalService,
    public generalService: GeneralService,
    public patientService: PatientService,
    public translateService: TranslateService,
    public translateNumber: TranslateNumberPipe,
    public languageService: LanguageService
  ) {
  }

  ngOnInit(): void {
    this.hospitalUid = this.hcpService.getCurrentStoredHospitalUid();
    this.hcpUid = this.hcpService.getCurrentStoredHcpUid();
    this.dateFormat = this.localeService.getLocalePreferences().dateFormat;
    this.getTasks();

    this.searchPatientListEvent.pipe(
      debounceTime(400),
      distinctUntilChanged()
    ).subscribe(result => {
      this.patientFilterSearchTerm = result.term;
      this.getPatients();
    });

    this.searchHcpListEvent.pipe(
      debounceTime(400),
      distinctUntilChanged()
    ).subscribe(result => {
      this.hcpFilterSearchTerm = result.term;
      this.getHCPs();
    });
  }

  ngOnDestroy() {
    this.searchPatientListEvent.complete();
    this.searchHcpListEvent.complete();
  }

  getTasks(showLoading = true): void {
    if (showLoading) {
      this.isLoading = true;
      this.tasks = [];
      this.selectedTasksMap.clear();
    }

    this.userTaskService.getHospitalUserTasks(
      this.hospitalUid, this.filters, this.pagination.current_page, this.pagination.page_size, this.sorting
    )
      .subscribe(result => {
        this.tasks = result?.items;
        this.selectedTasksMap.clear();

        for (const task of result?.items ?? []) {
          this.selectedTasksMap.set(task, false);
        }

        if (result.pagination) {
          this.pagination = result.pagination;
        }

        if (this.pagination?.total_pages && this.pagination?.current_page && this.pagination?.total_pages <= this.pagination?.current_page) {
          this.pagination.current_page = 0;
          this.getTasks();
        }

        this.isLoading = false;
      }, () => {
        this.isLoading = false;
      });
  }

  getHCPs() {
    let term = '';

    if (this.hcpFilterSearchTerm) {
      if (this.hcpFilterSearchTerm.length) {
        term = this.hcpFilterSearchTerm;
      } else {
        this.hcps = undefined;
      }
    }

    this.isLoadingHcps = true;

    this.hcpService.getPaged({ last_name: term }, `last_name,${this.hcpFilterSortingDir}`, 0, 50).subscribe({
      next: result => {
        this.hcps = result.items;
        this.isLoadingHcps = false;
      }, error: () => this.isLoadingHcps = false
    });
  }

  getPatients() {
    let term = '';

    if (this.patientFilterSearchTerm) {
      if (this.patientFilterSearchTerm.length) {
        term = this.patientFilterSearchTerm;
      } else {
        this.patients = undefined;
      }
    }

    this.isLoadingPatients = true;

    this.patientService.getPatientsByHospital(
      this.hospitalUid, { last_name: term }, `last_name,${this.patientFilterSortingDir};first_name,${this.patientFilterSortingDir}`, 0, 50
    ).subscribe({
      next: result => {
        this.patients = result.items;
        this.isLoadingPatients = false;
      },
      error: () => this.isLoadingPatients = false
    });
  }

  onToggleCheckbox(task: UserTaskBasic): void {
    const taskWasSelected = this.selectedTasksMap.get(task);

    // update selected-list
    if (taskWasSelected) {
      // was true, will toggle to false
      const index = this.selectedTasksUids.indexOf(task.uid);
      if (index > -1) {
        this.selectedTasksUids.splice(index, 1);
      }
    } else {
      this.selectedTasksUids.push(task.uid);
    }

    // toggle select
    this.selectedTasksMap.set(task, !taskWasSelected);
  }

  onToggleAllCheckbox(): void {
    this.selectedAll = !this.selectedAll;

    for (const task of this.tasks) {
      this.selectedTasksMap.set(task, this.selectedAll);

      if (this.selectedAll) {
        this.selectedTasksUids.push(task.uid);
      }
    }

    if (!this.selectedAll) {
      this.selectedTasksUids = [];
    }
  }

  goToPatientDetail(event, patient: Patient) {
    event.preventDefault();

    this.router.navigate(['/patient/', patient.uid], {
      queryParams: {
        'back-to-url': this.router.url
      }
    });
  }

  openUserTask(event, userTaskBasic: UserTaskBasic) {
    event?.preventDefault();

    switch (userTaskBasic?.type.toLowerCase()) {
      case 'appointment':
        this.showUserTaskAppointmentModal(userTaskBasic);
        break;
      case 'standard':
        this.showUserTaskStandardModal(userTaskBasic);
        break;
      case 'checklist':
        this.showUserTaskStandardModal(userTaskBasic);
        break;
      case 'questionnaire':
        this.showUserTaskStandardModal(userTaskBasic);
        break;
      default:
        console.error('User-task type not supported');
        break;
    }
  }

  openAssignModal(event, taskUid?) {
    event?.preventDefault();
    const taskUids = taskUid ? [taskUid] : this.selectedTasksUids;
    const initialState = {
      taskUids
    };
    const modal = this.modalService.show(AssignTaskModalComponent,
      GeneralService.BsModalOptions({
        class: 'modal-dialog-centered',
        initialState
      }));

    if (modal) {
      modal.content.assignmentSuccessEvent.subscribe((e) => {
        const tasks = e.tasks;
        const hcp: HealthCareProfessional = e.hcp;
        this.applyHcpToTasks(hcp, tasks);

        this.selectedTasksUids = [];
        this.selectedTasksMap = new Map<UserTaskBasic, boolean>();
        this.getTasks(false);
      });

      modal.content.assignmentFailedEvent.subscribe((e) => {
        const tasks = e.succeeded;
        const failed = e.failed;
        const hcp: HealthCareProfessional = e.hcp;

        this.applyHcpToTasks(hcp, tasks);

        this.selectedTasksUids = [];
        this.selectedTasksMap = new Map<UserTaskBasic, boolean>();

        failed.forEach(fail => {
          const task: UserTaskBasic = this.tasks.find((p) => fail.task_id === p.uid);
          this.selectedTasksMap.set(task, true);
          this.selectedTasksUids.push(task.uid);
        });
      });
    }
  }

  applyHcpToTasks(hcp: HealthCareProfessional, taskIds: string[]) {
    taskIds.forEach(uid => {
      const task = this.tasks.find(t => t.uid === uid);
      task.assignee = hcp;
    });
  }

  showUserTaskAppointmentModal(userTaskBasic: UserTaskBasic) {
    const initialState = {
      userTaskBasic,
      task_id: userTaskBasic.uid,
      asCc: true
    };

    this.modalService.show(UserTaskAppointmentModalComponent,
      GeneralService.BsModalOptions({
        class: 'modal-dialog-centered modal-xl',
        initialState
      })
    );
  }

  showUserTaskStandardModal(userTaskBasic: UserTaskBasic) {
    const initialState = {
      userTaskBasic,
      asCc: true
    };

    this.modalService.show(UserTaskStandardModalComponent,
      GeneralService.BsModalOptions({
        class: 'modal-xl modal-compact',
        initialState
      })
    );
  }

  onFilterChange() {
    this.filtersAreSet = true;
    this.pagination.current_page = 0;
    this.getTasks();
  }

  onActionResetFilters(): void {
    this.clearFilters();
    this.getTasks();
  }

  private clearFilters(): void {
    this.selectedTasksUids = [];

    this.filters = {
      hcp_uid: undefined,
      patient_uid: undefined,
      max_priority: UserTaskService.OVERVIEW_MAX_PRIORITY,
      min_priority: UserTaskService.OVERVIEW_MIN_PRIORITY
    };

    this.filtersAreSet = false;
  }

  onPaginationPageChanged(page: number) {
    if (page !== this.pagination.current_page) {
      this.pagination.current_page = page;
      this.getTasks();
    }
  }


  onPaginationRangeChanged(range: number) {
    if (range !== this.pagination.page_size) {
      this.pagination.current_page = 0; // also reset the currentPage
      this.pagination.page_size = range;
      this.getTasks();
    }
  }

  onChangeSort(event: MouseEvent, sortKey: string, sortDir: string): void {
    event.preventDefault();
    this.sorting = `${sortKey},${sortDir}`;
    this.getTasks();
  }

  customSearchFn(term: string, item: any) {
    return true; // always return, searching is done at the backend
  }

  tooltip(user) {
    if (user.uid === this.hcpUid) {
      return this.translateService.instant('pages.default.tasks.you');
    } else {
      return user.getFullName() + ' (' + user.job_title + ') ';
    }
  }

  clearPatientFilter() {
    this.patientFilterSearchTerm = '';
    this.getPatients();
  }

  updatePatientSortingDirection(dir: SortingDirection) {
    this.patientFilterSortingDir = dir;
    this.getPatients();
  }

  clearHcpFilter() {
    this.hcpFilterSearchTerm = '';
    this.getHCPs();
  }

  updateHcpSortingDirection(dir: SortingDirection) {
    this.hcpFilterSortingDir = dir;
    this.getHCPs();
  }
}
