<div class="group-of-avatars d-flex align-items-center" [ngClass]="{'small': small}">
  @for (participant of visibleParticipants; track participant.uid) {
    <div class="avatar-item" tabindex="0" [tooltip]="participant?.getFullName()" container="body">
      <app-avatar
        [profilePicture]="participant?.profile_picture"
      ></app-avatar>
    </div>
  }

  @if (hiddenParticipants?.length) {
    <button class="btn hidden-items d-flex align-items-center justify-content-center"
            [popover]="hiddenParticipantsList"
            placement="auto"
            triggers="focus"
            container="body"
            containerClass="group-of-avatars-popover"
    >
      <p class="mb-0">
        @if (hiddenParticipants?.length >= 100) {
          99+
        } @else {
          +{{ hiddenParticipants?.length }}
        }
      </p>
    </button>

    <ng-template #hiddenParticipantsList>
      <div class="hidden-participants-list">
        @for (participant of hiddenParticipants; track participant.uid) {
          <div class="wrapper item-details d-flex">
            <div class="d-block">
              <app-avatar [profilePicture]="participant?.profile_picture"></app-avatar>
            </div>

            <div class="user-info m-start-1 overflow-hidden">
              <p class="mb-0 text-truncate">{{ participant.getFullName() }}</p>
              <p class="mb-0 small" *ngIf="participant?.job_title">{{ participant.job_title }}</p>
            </div>
          </div>

          @if (!$last) {
            <div class="mb-2"></div>
          }
        }
      </div>
    </ng-template>
  }

</div>
