import { Component, OnDestroy, OnInit } from '@angular/core';
import { PageTabItem } from '../../../models/page-tab-item';
import { AuthenticationService } from '../../../services/authentication.service';
import { PathwayService } from '../../../services/pathway.service';
import { Pathway } from '../../../models/pathway';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { GeneralService } from '../../../services/general.service';
import { PatientService } from '../../../services/patient.service';
import { HealthCareProfessional } from '../../..//models/health-care-professional';
import { MedicalTeam } from '../../../models/medical-team';
import { EditRoleModalComponent } from '../../../modals/assign-role-modal/edit-role-modal.component';
import { EditPatientModalComponent } from '../../../modals/edit-patient-modal/edit-patient-modal.component';
import { Patient } from '../../../models/patient';
import { AssignMdtModalComponent } from '../../../modals/assign-mdt-modal/assign-mdt-modal.component';
import { MaterialService } from '../../../services/material.service';
import { Material } from '../../../models/material';
import { LanguageService } from '../../../services/language.service';
import { DataService } from '../../../services/data.service';
import { DateFormat } from '../../../models/date-format';
import { LocaleService } from '../../../services/locale.service';
import { BackButtonData } from '../../../models/back-button-data';
import { HcpService } from '../../../services/hcp.service';
import { AppointmentService } from '../../../services/appointment.service';
import { Appointment } from '../../../models/appointment';
import { UserTaskBasic } from '../../../models/user-task-basic';
import { GoalService } from '../../../services/goal.service';
import { PhaseInstance } from '../../../models/phase-instance';
import { MaterialPhase } from '../../../models/material-phase';
import { NotesService } from '../../../services/notes.service';
import { Note } from '../../../models/note';
import { DeleteNoteModalComponent } from '../../../modals/delete-note-modal/delete-note-modal.component';
import { QueryList } from '../../../models/query-list';
import { QueryListService } from '../../../services/query-list.service';
import { UserTaskService } from '../../../services/user-task.service';
import { ScopeService } from '../../../services/scope.service';
import { AddPathwayModalComponent } from '../../../modals/add-pathway-modal/add-pathway-modal.component';
import { Subscription, combineLatest } from 'rxjs';
import { StopPathwayModalComponent } from '../../../modals/stop-pathway-modal/stop-pathway-modal.component';
import { ModalService } from '../../../services/modal.service';


@Component({
  selector: 'app-patient-detail',
  templateUrl: './patient-detail.component.html',
  styleUrls: ['./patient-detail.component.scss']
})
export class PatientDetailComponent implements OnInit, OnDestroy {
  hasCcRole = false;
  currentHcpUid: string;
  selectedPathway: Pathway;
  pathwayLoading: boolean;
  pathwayAvailable: boolean = true;
  noPathwayAvailable: boolean;
  patient: Patient;
  pathwaysList: Pathway[] = [];
  patientUid: string;
  pageTabItems: PageTabItem[];
  showFullHeader: boolean;
  time_24_hours: boolean;
  timeZone: string;

  materialCompleted = 0;
  materialTotal: Number = 0;
  materialPercent = '0%';
  materials: Material[];
  materialsLoading: boolean;

  notesLoading: boolean;
  notes: Note[];
  totalNotes: number;

  essentialForms: QueryList[];
  essentialFormsLoading: boolean;
  totalEssentialForms: number;

  tasks: UserTaskBasic[];
  appointments: Appointment[];

  surgeryTasks: UserTaskBasic[];
  surgeryAppointments: Appointment[];

  public dateFormat: DateFormat;
  public backButtonData: BackButtonData;

  public onAppointmentCancelledSubscription: Subscription;
  public onAppointmentChangeSubscription: Subscription;

  constructor(
    public readonly authService: AuthenticationService,
    public readonly hcpService: HcpService,
    public readonly pathwayService: PathwayService,
    public readonly activatedRoute: ActivatedRoute,
    public readonly router: Router,
    public readonly modalService: ModalService,
    public readonly patientService: PatientService,
    public readonly materialService: MaterialService,
    public readonly languageService: LanguageService,
    public readonly dataService: DataService,
    public readonly localeService: LocaleService,
    public readonly appointmentService: AppointmentService,
    public generalService: GeneralService,
    public translateService: TranslateService,
    public goalService: GoalService,
    public notesService: NotesService,
    public queryListService: QueryListService,
    public userTaskService: UserTaskService,
    public scopeService: ScopeService
  ) { }

  ngOnInit(): void {
    this.materialsLoading = true;

    this.hasCcRole = this.authService.hasCcRole();
    this.currentHcpUid = this.hcpService.getCurrentStoredHcpUid();

    const preferences = this.localeService.getLocalePreferences();
    this.time_24_hours = preferences.locale.time_24_hours;
    this.dateFormat = preferences.dateFormat;
    this.timeZone = preferences.locale.time_zone;

    combineLatest([
      this.activatedRoute.params,
      this.activatedRoute.queryParams
    ]).subscribe((data: Params[]) => {
      this.handleParams(data);
    });

    this.onAppointmentCancelledSubscription = this.appointmentService.onAppointmentCancelled.subscribe(() => {
      this.refreshPathway();
    });

    this.onAppointmentChangeSubscription = this.appointmentService.onAppointmentChange.subscribe(() => {
      this.refreshPathway();
    });
  }

  ngOnDestroy() {
    this.onAppointmentCancelledSubscription?.unsubscribe();
    this.onAppointmentChangeSubscription?.unsubscribe();
  }

  handleParams(params: Params[]): void {
    const backToUrl = params[1]['back-to-url'];
    const pathwayUid = params[1]['pathwayUid'];

    this.patientUid = params[0]['patientUid'];

    if (backToUrl) {
      this.backButtonData = this.generalService.defineBackButton(backToUrl);
    }

    if (pathwayUid) {
      this.dataService.set(DataService.SelectedPathway, new Pathway(pathwayUid));
      this.getPatient(this.patientUid, pathwayUid);
    } else {
      this.getPatient(this.patientUid);
    }
  }

  getPatient(patient_uid: string, pathwayUid?: string): void {
    this.pathwayLoading = true;
    this.selectedPathway = undefined;

    const patientObservable = this.hasCcRole
      ? this.patientService.getDashboardPatientByHospital(this.hcpService.getCurrentStoredHospitalUid(), patient_uid)
      : this.patientService.getDashboardPatientByHcp(this.hcpService.getCurrentStoredHcpUid(), patient_uid);

    patientObservable.subscribe(result => {
      this.patient = result;
      this.pathwaysList = this.patient.pathways;
      this.dataService.set(DataService.BreadCrumbPatName, this.patient.getFullName());

      if (pathwayUid) {
        this.selectPathWay(pathwayUid);
      } else if (
        this.dataService.get(DataService.SelectedPathway) &&
        this.pathwaysList?.some(pw => pw.uid === this.dataService.get(DataService.SelectedPathway)?.uid)
      ) {
        this.selectPathWay(this.dataService.get(DataService.SelectedPathway).uid);
      } else if (this.pathwaysList?.length) {
        this.selectPathWay(this.pathwaysList[0].uid);
      } else {
        this.noPathwayAvailable = true;

        setTimeout(() => {
          this.pathwayLoading = false;
        }, 500);
      }
    }, () => {
    });
  }

  get pathwaysAvailable(): boolean {
    return this.pathwaysList?.length > 0;
  }

  definePageTabItems() {
    if (this.scopeService.doesCurrentPatientHasClicinalCareModule()) {
      this.pageTabItems = [
        new PageTabItem('pages.default.patient_detail.zone_timeline', 'zone_timeline'),
        new PageTabItem('pages.default.patient_detail.zone_materials', 'zone_materials'),
        new PageTabItem('pages.default.patient_detail.zone_checklists', 'zone_checklists'),
        new PageTabItem('pages.default.patient_detail.zone_essential_forms', 'zone_essential_forms'),
        new PageTabItem('pages.default.patient_detail.zone_goals', 'zone_goals'),
        new PageTabItem('pages.default.patient_detail.zone_notes', 'zone_notes'),
        new PageTabItem('pages.default.patient_detail.zone_mdt', 'zone_mdt')
      ];
    } else {
      this.pageTabItems = [
        new PageTabItem('pages.default.patient_detail.zone_materials', 'zone_materials')
      ];
    }

    if (this.hasReminders()) {
      this.pageTabItems.unshift(new PageTabItem('pages.default.patient_detail.zone_reminders', 'zone_reminders'));
    }
  }

  actionToggleHeader(event) {
    event.preventDefault();
    this.showFullHeader = !this.showFullHeader;
  }

  selectPathWay(pathwayUid: string, showLoading: boolean = true): void {
    window.scrollTo(0, 0);
    this.definePageTabItems();

    this.pathwayLoading = showLoading;

    const pathWayObservable = this.hasCcRole
      ? this.pathwayService.getDashboardPatientPathwayByHospital(this.hcpService.getCurrentStoredHospitalUid(), this.patientUid, pathwayUid)
      : this.pathwayService.getDashboardPatientPathwayByHcp(this.hcpService.getCurrentStoredHcpUid(), this.patientUid, pathwayUid);

    pathWayObservable.subscribe(result => {
      this.pathwayAvailable = true;
      this.pathwayLoading = false;
      this.dataService.set(DataService.SelectedPathway, result);
      this.selectedPathway = result;
      this.appointments = result.appointments;
      this.surgeryAppointments = this.filterAppointments(result.appointments, AppointmentService.TypeSurgery);

      const filteredPathway = this.pathwaysList.filter(item => (item.uid === pathwayUid));
      if (filteredPathway[0]) {
        this.selectedPathway.care_module = filteredPathway[0].care_module;
      }

      this.loadUserTasks();
      this.loadNotes(showLoading);
      this.definePageTabItems();

      setTimeout(() => {
        // required to redraw/recalculate the tabs bar, this fires an event to that component
        this.generalService.requestViewRedraw();
      }, 100);
    }, error => {
      this.pathwayLoading = false;
      // if (error.error.status === 404) {
      //   this.pathwayAvailable = false;
      //   setTimeout(()=>{
      //     this.selectPathWay(pathWay, false);
      //   }, 3000);
      // }
    });
  }

  refreshPathway() {
    this.selectPathWay(this.selectedPathway.uid, false);
  }

  updateSurgeryDates() {
    this.refreshPathway();
  }

  getLanguage() {
    return this.languageService.findLanguageCodeByEnum(this.patient.language);
  }

  // onOpenEditRoleModal(event: MouseEvent, hcp: HealthCareProfessional, mdt: MedicalTeam, pathway: Pathway, patientUid: string): void {
  //   event.preventDefault();
  //
  //   if (!this.hasCcRole) {
  //     return;
  //   }
  //
  //   const modalRef = this.modalService.show(EditRoleModalComponent,
  //     GeneralService.BsModalOptions({
  //       class: 'modal-dialog-centered modal-lg modal-compact',
  //       initialState: {
  //         hcp,
  //         mdt,
  //         pathway,
  //         patientUid
  //       }
  //     }));
  //
  //   modalRef.content.roleEditedEvent.subscribe(() => {
  //     this.refreshPathway();
  //   });
  // }

  isClinicalLead(hcp: HealthCareProfessional): boolean {
    return hcp?.uid === this.selectedPathway?.clinical_lead?.uid;
  }

  isCaseManager(hcp: HealthCareProfessional): boolean {
    return hcp?.uid === this.selectedPathway?.case_manager?.uid;
  }

  isUnassigned(hcp: HealthCareProfessional): boolean {
    return !this.isClinicalLead(hcp) && !this.isCaseManager(hcp);
  }

  getRoleLabel(hcp: HealthCareProfessional, pathway: Pathway): string {
    switch (hcp.uid) {
      case pathway.case_manager.uid:
        return 'pages.default.patient_detail.roles.case_manager';
      case pathway.clinical_lead.uid:
        return 'pages.default.patient_detail.roles.clinical_lead';
      default:
        return 'pages.default.patient_detail.roles.assign_role';
    }
  }

  onSelectPathway($event: MouseEvent, pathway: Pathway): void {
    $event.preventDefault();
    if (this.selectedPathway?.uid !== pathway.uid) {
      this.selectPathWay(pathway.uid);
    }
  }

  getPathwayListWithoutSelected(selectedPathway: Pathway): Pathway[] {
    if (selectedPathway && this.pathwaysList) {
      return this.pathwaysList.filter(pathway => pathway.uid !== selectedPathway.uid);
    } else {
      return [];
    }
  }

  editPatient() {
    const initialState = {
      patient: this.patient
    };

    const modalref = this.modalService.showWithInterceptor(
      EditPatientModalComponent,
      GeneralService.BsModalOptions({
        class: 'modal-dialog-centered modal-compact modal-lg',
        initialState
      })
    );

    modalref.content.onPatientUpdated.subscribe(patient => {
      const pathways = this.patient.pathways;
      this.patient = new Patient(patient);
      this.patient.pathways = pathways;
    });
  }

  editMdt(event) {
    event.preventDefault();
    this.openEditMdtModal();
  }

  addPathway(event) {
    event.preventDefault();
    this.openAddPathwayModal();
  }

  stopPathway(event) {
    event.preventDefault();
    this.openStopPathwayModal();
  }

  openEditMdtModal() {
    const initialState = {
      type: 'edit',
      patient: this.patient,
      pathway: this.selectedPathway
    };

    const modalref = this.modalService.show(AssignMdtModalComponent,
      GeneralService.BsModalOptions({
        class: 'modal-dialog-centered modal-xl',
        initialState
      })
    );

    modalref.content.assignmentSuccessEvent.subscribe(result => {
      this.selectedPathway.patient_mdt = result;
    });
  }

  openAddPathwayModal() {
    const initialState = {
      patientUid: this.patient.uid,
      pathwayList: this.pathwaysList
    };

    const modalref = this.modalService.show(AddPathwayModalComponent,
      GeneralService.BsModalOptions({
        class: 'modal-dialog-centered modal-m',
        initialState
      })
    );

    modalref.content.pathwayAdded.subscribe(result => {
      this.selectedPathway = result;
      this.pathwaysList.push(result);
      this.selectPathWay(result.uid);
    });
  }

  openStopPathwayModal() {
    const initialState = {
      patient: this.patient,
      selectedPathway: this.selectedPathway
    };

    const modalref = this.modalService.show(StopPathwayModalComponent,
      GeneralService.BsModalOptions({
        class: 'modal-dialog-centered modal-lg',
        initialState
      })
    );

    modalref.content.pathwayStopped.subscribe(() => {
      modalref.hide();
      this.refreshPathway();
    });
  }

  hasReminders(): boolean {
    if (!this.selectedPathway) {
      return false;
    }
    return Boolean(this.selectedPathway?.reminders?.length || this.tasks?.length);
  }

  loadUserTasks() {
    const hcpUid = this.hcpService.getCurrentStoredHcpUid();
    const filters = { patient_pathway_id: this.selectedPathway.uid, max_priority: 90 };

    this.userTaskService.getUserTasks(hcpUid, filters, 0, 100, ['created_at,asc']).subscribe(result => {
      const tasks = result.items;

      this.tasks = this.filterUserTasks(tasks, 'surgery_app_hcp_input', false);
      this.surgeryTasks = this.filterUserTasks(tasks, 'surgery_app_hcp_input');

      this.definePageTabItems();
    });
  }

  filterUserTasks(tasks: UserTaskBasic[], label: string, included: boolean = true) {
    return tasks.filter(task => task.labels.includes(label) === included);
  }

  filterAppointments(appointments: Appointment[], type: string) {
    return appointments.filter(appointment => appointment.type === type);
  }

  loadNotes(showLoading = true) {
    const hospitalUid = this.hcpService.getCurrentStoredHospitalUid();
    const hcpUid = this.hcpService.getCurrentStoredHcpUid();

    if (showLoading) {
      this.notes = [];
      this.notesLoading = true;
    }

    const notesObservable = this.hasCcRole
      ? this.notesService.getNotesByCc(hospitalUid, this.patientUid, this.selectedPathway.uid, {}, 'changed_at,desc', 0, 4)
      : this.notesService.getNotesByHcp(hcpUid, this.patientUid, this.selectedPathway.uid, {}, 'changed_at,desc', 0, 4);

    notesObservable.subscribe(result => {
      this.notesLoading = false;
      this.notes = result['notes'];
      this.totalNotes = result['pagination']['total_elements'];
    });
  }

  deleteNote(event, id: string) {
    event.preventDefault();

    const hospitalUid = this.hcpService.getCurrentStoredHospitalUid();
    const hcpUid = this.hcpService.getCurrentStoredHcpUid();
    const initialState = {
      patientUid: this.patientUid,
      hospitalUid,
      hcpUid,
      patientPathwayUid: this.selectedPathway.uid,
      noteUid: id,
      hasCcRole: this.hasCcRole
    };

    const modalref = this.modalService.show(DeleteNoteModalComponent,
      GeneralService.BsModalOptions({
        class: 'modal-dialog-centered',
        initialState
      })
    );

    if (modalref) {
      modalref.content?.noteDeleted.subscribe(() => {
        this.loadNotes(false);
      });
    }

  }
}
