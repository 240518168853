import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { DeleteNoteModalComponent } from '../../../modals/delete-note-modal/delete-note-modal.component';
import { HealthCareProfessional } from '../../../models/health-care-professional';
import { AuthenticationService } from '../../../services/authentication.service';
import { GeneralService } from '../../../services/general.service';
import { HcpService } from '../../../services/hcp.service';
import { NotesService } from '../../../services/notes.service';
import { TranslateNumberPipe } from '../../../pipes/translate-number.pipe';
import { LanguageService } from '../../../services/language.service';
import { PaginationInterface } from '../../../interfaces/pagination.interface';
import { Note } from '../../../models/note';

@Component({
  selector: 'app-patient-notes',
  templateUrl: './patient-notes.component.html',
  styleUrls: ['./patient-notes.component.scss']
})
export class PatientNotesComponent implements OnInit, OnDestroy {
  public currentHcpUid: string;

  hcpNameSearch = new Subject<string>();

  public patientUid: string;
  public patientPathwayUid: string;

  public notes: Note[];

  public hcps: HealthCareProfessional[];

  public hasCcRole: boolean;

  public filtersAreSet: boolean;
  public filters: any = {
    hcp_name: null
  };

  public sorting = 'changed_at,desc';
  public sortingOptions = [
    { value: 'changed_at,asc', translationKey: 'form.labels.date_edited_sort_asc' },
    { value: 'changed_at,desc', translationKey: 'form.labels.date_edited_sort_desc' }
  ];


  public isLoading: boolean;
  public isLoadingHCPs: boolean;
  public pagination = {
    current_page: 0,
    page_size: 10
  } as PaginationInterface;

  constructor(
    public activatedRoute: ActivatedRoute,
    public hcpService: HcpService,
    public notesService: NotesService,
    public authService: AuthenticationService,
    public generalService: GeneralService,
    public modalService: BsModalService,
    public router: Router,
    public translateService: TranslateService,
    public translateNumber: TranslateNumberPipe,
    public languageService: LanguageService
  ) {

    this.hcpNameSearch.pipe(
      debounceTime(400),
      distinctUntilChanged())
      .subscribe(value => {
        this.pagination.current_page = 0;
        this.getHCPs(value);
      });
  }

  ngOnInit(): void {
    this.currentHcpUid = this.hcpService.getCurrentStoredHcpUid();
    this.hasCcRole = this.authService.hasCcRole();
    this.clearFilters();

    this.activatedRoute.params.subscribe(params => {
      this.patientUid = params.patientUid;
      this.patientPathwayUid = params.patientPathwayUid;
      this.loadNotes();
    });
  }

  ngOnDestroy() {
    this.hcpNameSearch.complete();
  }

  loadNotes(showLoading = true): void {
    if (showLoading) {
      this.notes = [];
      this.isLoading = true;
    }

    const hospitalUid = this.hcpService.getCurrentStoredHospitalUid();
    const hcpUid = this.hcpService.getCurrentStoredHcpUid();

    const notesObservable = this.hasCcRole
      ? this.notesService.getNotesByCc(hospitalUid, this.patientUid, this.patientPathwayUid, this.filters, this.sorting, this.pagination.current_page, this.pagination.page_size)
      : this.notesService.getNotesByHcp(hcpUid, this.patientUid, this.patientPathwayUid, this.filters, this.sorting, this.pagination.current_page, this.pagination.page_size);

    notesObservable.subscribe(result => {
      this.isLoading = false;
      this.notes = result?.items;

      if (result?.pagination) {
        this.pagination = result.pagination;
      }

      if (this.pagination?.total_pages && this.pagination?.current_page && this.pagination?.total_pages <= this.pagination?.current_page) {
        this.pagination.current_page = 0;
        this.loadNotes();
      }
    });
  }

  deleteNote(event, id: string) {
    event.preventDefault();

    const hospitalUid = this.hcpService.getCurrentStoredHospitalUid();
    const hcpUid = this.hcpService.getCurrentStoredHcpUid();
    const initialState = {
      patientUid: this.patientUid,
      hospitalUid,
      hcpUid,
      patientPathwayUid: this.patientPathwayUid,
      noteUid: id,
      hasCcRole: this.hasCcRole
    };

    const modalref = this.modalService.show(DeleteNoteModalComponent,
      GeneralService.BsModalOptions({
        class: 'modal-dialog-centered',
        initialState
      })
    );

    if (modalref) {
      modalref.content?.noteDeleted.subscribe(() => {
        this.loadNotes(false);
      });
    }
  }

  clearFilters() {
    this.filters.hcp_name = null;
    this.filtersAreSet = false;
  }

  resetFilters() {
    this.pagination.current_page = 0;
    this.clearFilters();
    this.loadNotes();
  }

  getHCPs(event?) {
    let term = '';

    if (event && event.term && event.term.length) {
      term = event.term;
    } else {
      this.hcps = undefined;
    }

    this.isLoadingHCPs = true;

    this.hcpService.getPaged({ last_name: term }, 'last_name,asc', 0, 50).subscribe({
      next: result => {
        this.hcps = result?.items;
        this.isLoadingHCPs = false;
      }, error: () => this.isLoadingHCPs = false
    });
  }

  customSearchFn(term: string, item: any) {
    return true; // always return, searching is done at the backend
  }

  onFilterChange() {
    this.filtersAreSet = true;
    this.pagination.current_page = 0;
    this.loadNotes();
  }

  onPaginationPageChanged(page: number): void {
    if (page !== this.pagination.current_page) {
      this.pagination.current_page = page;
      this.loadNotes();
    }
  }

  onPaginationRangeChanged(pageSize: number): void {
    if (pageSize !== this.pagination.page_size) {
      this.pagination.current_page = 0; // also reset the currentPage
      this.pagination.page_size = pageSize;
      this.loadNotes();
    }
  }

  onChangeSort(): void {
    this.pagination.current_page = 0;
    this.loadNotes();
  }

  createNewNote(event: MouseEvent) {
    event.preventDefault();

    this.router.navigateByUrl(`patient/${this.patientUid}/${this.patientPathwayUid}/notes/new`);
  }
}
