import { Component, Input } from '@angular/core';
import { Participant } from '../../models/participant';

@Component({
  selector: 'app-group-of-avatars',
  templateUrl: './group-of-avatars.component.html'
})
export class GroupOfAvatarsComponent {
  @Input() participants: Array<Participant> = [];
  @Input() small = false;
  @Input() avatarsVisibleCount = 3;

  private avatarsOffset = 2;

  public get visibleParticipants(): Array<Participant> {
    if (this.participants.length <= this.avatarsVisibleCount + this.avatarsOffset) {
      return this.participants;
    }

    return this.participants.slice(0, this.avatarsVisibleCount);
  }

  public get hiddenParticipants(): Array<Participant> {
    if (this.participants.length <= this.avatarsVisibleCount + this.avatarsOffset) {
      return [];
    }

    return this.participants.slice(this.avatarsVisibleCount);
  }
}
