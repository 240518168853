import {Appointment} from '../models/appointment';

export class AppointmentSelectionEvent {
  public event: MouseEvent;
  public context: Appointment;

  constructor(event: MouseEvent, context: Appointment) {
    this.event  = event;
    this.context = context;
  }
}
