import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { CareModule } from '../../../../models/care-module';
import { TranslateNumberPipe } from '../../../../pipes/translate-number.pipe';
import { HcpService } from '../../../../services/hcp.service';
import { HospitalService } from '../../../../services/hospital.service';
import { LanguageService } from '../../../../services/language.service';
import { PaginationInterface } from '../../../../interfaces/pagination.interface';

@Component({
  selector: 'app-admin-care-modules',
  templateUrl: './admin-care-modules.component.html'
})
export class AdminCareModulesComponent implements OnInit {
  public isLoading = false;
  public careModules: CareModule[];
  public hospitalUid: string;
  public pagination = {
    current_page: 0,
    page_size: 10
  } as PaginationInterface;

  constructor(
    public hospitalService: HospitalService,
    public hcpService: HcpService,
    public translateNumber: TranslateNumberPipe,
    public translateService: TranslateService,
    public languageService: LanguageService,
    public modalService: BsModalService
  ) { }

  ngOnInit(): void {
    this.hospitalUid = this.hcpService.getCurrentStoredHospitalUid();
    this.getCareModules();
  }

  getCareModules(showLoading = true): void {
    if (showLoading) {
      this.isLoading = true;
    }

    this.hospitalService.getCareModulesPaginated(
      this.hospitalUid,
      this.pagination.current_page,
      this.pagination.page_size,
    ).subscribe(result => {
      this.careModules = result.items;

      if (result.pagination) {
        this.pagination = result.pagination;
      }

      if (this.pagination?.total_pages && this.pagination?.current_page && this.pagination?.total_pages <= this.pagination?.current_page) {
        this.pagination.current_page = 0;
        this.getCareModules();
      }

      this.isLoading = false;
    }, () => this.isLoading = false);
  }

  onPaginationPageChanged(page: number) {
    if (page !== this.pagination.current_page) {
      this.pagination.current_page = page;
      this.getCareModules();
    }
  }

  public onPaginationRangeChanged(range: number) {
    if (range !== this.pagination.page_size) {
      this.pagination.current_page = 0; // also reset the currentPage
      this.pagination.page_size = range;
      this.getCareModules();
    }
  }
}
