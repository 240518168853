<div class="notice notice-overlay">
  <div class="logo text-jnj mb-6 mb-xs-9 mb-lg-11 mb-xl-13" [inlineSVG]="'./assets/svg/logo-polyphonic.svg'"></div>

  <div class="position-relative">
    <h2>
      <span [inlineSVG]="'./assets/svg/warning-24.svg'" class="icon icon-lg m-end-2"></span>
      <span class="text-danger">{{ 'pages.general.generic_error.something_wrong' | translate }}</span>
    </h2>

    <h6 *ngIf="code">{{ 'pages.general.generic_error.error_with_code' | translate }} {{code}}</h6>
    <h6 *ngIf="!code">{{ 'pages.general.generic_error.error_text1' | translate }}</h6>

    <p>{{ 'pages.general.generic_error.error_text2' | translate }}</p>

    <p>
      <a href="" routerLink="/" class="text-icon-link hoverable text-secondary" id="generic-error-back-to-home">
        <span class="label">{{ 'pages.general.generic_error.back_to_home' | translate }}</span>
        <span class="icon-end">
          <span class="rtl-d-none" [inlineSVG]="'./assets/svg/arrow-right-24.svg'"></span>
          <span class="d-none rtl-d-inline" [inlineSVG]="'./assets/svg/arrow-left-24.svg'"></span>
        </span>
      </a>
    </p>
  </div>

  <div class="mt-auto">
    <app-flow-copyrights></app-flow-copyrights>
  </div>
</div>
