import { Pipe, PipeTransform } from '@angular/core';
import { LanguageService } from '../services/language.service';

@Pipe({ name: 'translateNumber', pure: false })
export class TranslateNumberPipe implements PipeTransform {
  constructor(
    private languageService: LanguageService
  ) {
  }

  numbersObject: { [x: string]: string } = {
    '1': '١',
    '2': '٢',
    '3': '٣',
    '4': '٤',
    '5': '٥',
    '6': '٦',
    '7': '٧',
    '8': '٨',
    '9': '٩',
    '0': '٠',
  };

  transform(n: number | string): string | number {
    if (n === null || n === undefined) {
      return '';
    }

    const currentLocale = this.languageService.getCurrentLanguageCode().locale;
    if (currentLocale !== 'ar') {
      return n;
    }

    n = n + ''; // to make it a string if it was a number
    let newString = '';
    for (let i = 0; i < n.length; i++) {
      if (this.numbersObject[n.charAt(i)]) {
        newString += this.numbersObject[n.charAt(i)];
      } else {
        newString += n.charAt(i);
      }
    }

    return newString;
  }
}
