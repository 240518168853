import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { HealthCareProfessional } from '../../models/health-care-professional';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { ErrorService } from '../../services/error.service';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from '../../services/language.service';
import { HcpService } from '../../services/hcp.service';
import { Router } from '@angular/router';
import { GeneralService } from '../../services/general.service';
import { HcpFunction } from '../../models/hcp-function';
import { LanguageCode } from '../../models/language-code';
import { EditOtherEmailModalComponent } from '../edit-other-email-modal/edit-other-email-modal.component';

@Component({
  selector: 'app-edit-hcp-modal',
  templateUrl: './edit-hcp-modal.component.html',
  styleUrls: ['./edit-hcp-modal.component.scss']
})
export class EditHcpModalComponent implements OnInit {
  @Input() hcp_uid: string;
  @Output() hcpEditedEvent = new EventEmitter<void>();

  hcp: HealthCareProfessional;
  languageOptions: LanguageCode[] = [];
  functionOptions: HcpFunction[] = [];

  form: UntypedFormGroup;
  validationVisible = false;
  isSaving = false;

  private _closeAnyway = false;
  public get showModalInterceptor(): boolean {
    if (this._closeAnyway) {
      return false;
    }

    return this.form?.dirty;
  }

  constructor(
    public bsModalRef: BsModalRef,
    public readonly hcpService: HcpService,
    public readonly languageService: LanguageService,
    public readonly formBuilder: UntypedFormBuilder,
    private readonly toastService: ToastrService,
    private readonly errorService: ErrorService,
    private readonly translate: TranslateService,
    private readonly router: Router,
    public modalService: BsModalService,
    public readonly generalService: GeneralService
  ) {
  }

  ngOnInit(): void {
    if (!this.hcp_uid) {
      this.onHandleClose();
      return;
    }

    this.getHcp(this.hcp_uid);
    this.hcpService.getFunctions(this.hcpService.getCurrentStoredHospitalUid()).subscribe(functions => {
      this.functionOptions = functions;
    });
    this.languageService.getSupportedAppLanguages().subscribe(languages => {
      this.languageOptions = languages;
    });
  }

  getHcp(id) {
    const hospital_uid = this.hcpService.getCurrentStoredHospitalUid();
    this.hcpService.getByHospital(id, hospital_uid).subscribe(result => {
      this.hcp = result;

      setTimeout(() => {
        this.formSetup();
      }, 250); // delay for visual purposes; so the loader doesn't flicker when the call finishes quickly
    });
  }

  formSetup(): void {
    if (this.form) {
      return;
    }

    this.form = this.formBuilder.group({
      profilePicture: [this.hcp?.profile_picture],
      gender: [this.hcp?.gender, [Validators.required]],
      first_name: [this.hcp?.first_name, [Validators.required]],
      last_name: [this.hcp?.last_name, [Validators.required]],
      language: [this.hcp?.language, [Validators.required]],
      job_title: [this.hcp?.job_title, [Validators.required]],
      function: [this.hcp?.function.key, [Validators.required]],
      email: [{
        value: this.hcp?.email,
        disabled: true
      }],
      phoneNumber: [{
        value: this.hcp?.phone_number?.number,
        disabled: true
      }],
      phoneNumberCountryCode: [{
        value: this.generalService.getDialCodeForCountry(this.hcp?.phone_number?.code)?.label,
        disabled: true
      }],
      location: [this.hcp?.location?.name],
      useLocationUrl: [!!this.hcp?.location?.url],
      locationUrl: [this.hcp?.location?.url]
    });
  }

  onHandleSubmit(): void {
    if (this.form.pristine) {
      this.bsModalRef.hide();
      return;
    }

    if (!this.form.valid) {
      this.validationVisible = true;
      return;
    }

    this.hcp.gender = this.form.get('gender').value;
    this.hcp.first_name = this.form.get('first_name').value;
    this.hcp.last_name = this.form.get('last_name').value;
    this.hcp.language = this.form.get('language').value;
    this.hcp.location.name = this.form.get('location').value;
    this.hcp.location.url = this.form.get('useLocationUrl').value ? this.form.get('locationUrl').value : null;
    this.hcp.job_title = this.form.get('job_title').value;
    this.hcp.function = this.form.get('function').value;

    this.isSaving = true;
    this.hcpService.updateForCcRole(this.hcp).subscribe(() => {
      this.isSaving = false;
      this.hcpEditedEvent.emit();
      this.showSuccessToast();

      this._closeAnyway = true;
      this.onHandleClose();
    }, (error) => {
      this.onRequestError(error);
    });
  }

  onHandleClose(): void {
    return this.bsModalRef.hide();
  }

  private showSuccessToast(): void {
    this.toastService.success(this.translate.instant('modals.edit_hcp.edit_hcp_success_notification'));
  }

  onRequestError(error) {
    const errorArray = error?.error?.errors;
    this.isSaving = false;

    if (errorArray) {
      this.validationVisible = true;

      errorArray.forEach(err => {
        this.form.get(err.field).setErrors({
          backend_errors: true,
          message: err.key
        });
      });
    }
  }

  showEditEmailModal() {
    const initialState = {
      hcp: this.hcp
    };

    const modalRef = this.modalService.show(EditOtherEmailModalComponent,
      GeneralService.BsModalOptions({
        class: 'modal-dialog-centered',
        initialState
      })
    );

    modalRef.content.emailChanged.subscribe(() => modalRef.hide());
  }
}
