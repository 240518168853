import { Directive, ElementRef, HostListener, OnInit, OnDestroy } from '@angular/core';
import { FormGroupDirective } from '@angular/forms';

@Directive({
  selector: 'form[scrollToValidationFeedback]'
})
export class FormValidationScrollDirective implements OnInit, OnDestroy {
  private observer: MutationObserver;

  constructor(
    private el: ElementRef,
    private formGroupDirective: FormGroupDirective
  ) {
    this.observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.type === 'attributes' &&
          mutation.attributeName === 'class' &&
          this.el.nativeElement.classList.contains('validation-visible')
        ) {
          this.scrollToError();
        }
      });
    });
  }

  @HostListener('submit', ['$event'])
  onSubmit() {
    if (this.formGroupDirective.form.invalid && this.el.nativeElement.classList.contains('validation-visible')) {
      this.scrollToError();
    }
  }

  ngOnInit() {
    this.observer.observe(this.el.nativeElement, {
      attributes: true,
      attributeFilter: ['class']
    });
  }

  ngOnDestroy() {
    this.observer.disconnect();
  }

  private scrollToError() {
    const form = this.el.nativeElement;
    const invalidElements: NodeListOf<HTMLElement> = form.querySelectorAll('.ng-invalid');

    if (invalidElements.length > 0) {
      const firstInvalidElement = invalidElements[0];
      firstInvalidElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }
}

// Usage in component template:
// Make sure you are using the (ngSubmit) event on the form to trigger this directive

// If the submit-btn is in the form it's sufficient to use btn type="submit" and no (click)
// <form [formGroup]="form" (ngSubmit)="submitForm()" scrollToValidationFeedback>
//   <!-- form content -->
//
//   <button type="submit">Submit</button>
// </form>

// If the submit-btn is outside the form, make sure you add the form-property with the id of the form and no (click)
// <form [formGroup]="form" id="form-id" (ngSubmit)="submitForm()" scrollToValidationFeedback>
//   <!-- form content -->
// </form>
//
// <button type="submit" form="form-id">Submit</button>
