<div class="body-content-calendar-month-view">

  <div class="container" *ngIf="isLoading">
    <app-page-loader />
  </div>

  <div class="calendar-month-view" *ngIf="!isLoading">
    <div class="container">

      <div class="calendar-month-view-wrapper">
        <div class="month-legend">
          <div class="item" *ngFor="let day of weekdays">
            <p>{{ day }}</p>
          </div>
        </div>

        <div class="month-days" #monthDaysContainer>

          <div class="item disabled" [style.height.%]="blockHeight" *ngFor="let i of [].constructor(fillUpDaysAtStart)"></div>

          <div class="item" [style.height.%]="blockHeight" *ngFor="let day of days" [ngClass]="{'current': isToday(day.key)}">
            <p class="num">{{ day.label | translateNumber }} </p>
            <div *ngFor="let appointment of getVisibleItems(day.appointments).visible">
              <a href=""
                (click)="selectAppointment($event, appointment)"
                class="event"
                [id]="'calendar-month-appointment-' + appointment.uid"
                [ngClass]="{
                  'cancelled' : appointment.hasCancelledState(),
                  'pending': appointment.hasNoneState(),
                  'full-day': appointment?.date
                }"
              >
                <span class="m-end-1" *ngIf="appointment?.start_date && !appointment?.date">{{ appointment.start_date | time }}</span>
                <span class="text-truncate fw-bold" *ngIf="appointment?.start_date && !appointment?.date">{{ appointment.title }}</span>
                <span class="text-truncate" *ngIf="appointment?.date && !appointment?.start_date">{{ appointment.patient_invitee.getFullName() }}</span>
              </a>
            </div>
            <a href="" (click)="onMoreClicked($event, day)" class="x-more-link" *ngIf="getVisibleItems(day.appointments)?.invisible?.length">
              <span>+{{getVisibleItems(day.appointments)?.invisible?.length}} {{ 'pages.default.calendar.more' | translate }}</span>
            </a>
          </div>

          <div class="item disabled" [style.height.%]="blockHeight" *ngFor="let i of [].constructor(fillUpDaysAtEnd)"></div>
        </div>
      </div>
    </div>
  </div>
</div>
