<div class="container" *ngIf="isLoading">
  <app-page-loader/>
</div>

<div class="container position-relative overflow-hidden" *ngIf="!isLoading">
  <mwl-calendar-week-view
    *ngIf="renderMwlView"
    class="style-no-interaction"
    [viewDate]="viewDate"
    [events]="events"
    [weekStartsOn]="weekStartsOn"
    (eventClicked)="calendarEventClickHandler($event)"
  />
</div>

