<div class="help-center-modal">
  <div class="modal-header">
    <h6 class="h7">{{ 'modals.help_center.modal_title' | translate }}</h6>

    <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close" (click)="bsModalRef.hide()"></button>
  </div>

  <div class="modal-body">
    <div [ngSwitch]="currentView">
      <div *ngSwitchCase="helpCenterModalViews.OVERVIEW">
        <div class="overview">
          @for(helpcenterSection of helpcenterSections; track helpcenterSection.title) {
            <!-- Faqs -->
            <div *ngIf="helpcenterSection.isFaqs">
              <app-help-center-faq-list
                [helpcenterSection]="helpcenterSection"
                [compact]="true"
                (goToOverview)="goToSectionOverview($event)"
                (goToFaq)="goToFaq($event)"
              />
            </div>
            <!-- /Faqs -->

            <!-- Contact -->
            <div *ngIf="helpcenterSection.isContact">
              <app-help-center-contact
                [helpcenterSection]="helpcenterSection"
                [compact]="true"
              />
            </div>
            <!-- /Contact -->
            <hr class="my-5" *ngIf="!$last"/>
          }
        </div>
      </div>

      <div *ngSwitchCase="helpCenterModalViews.FAQ_OVERVIEW">
        <div class="faq-overview">
          <button class="btn btn-tertiary mb-2" (click)="goToOverview()">
            <span class="rtl-mirrored-inline-svg m-end-1" [inlineSVG]="'./assets/svg/arrow-left-24.svg'"></span>
            <span>{{ 'modals.help_center.return_to_overview' | translate }}</span>
          </button>

          <app-faq-block *ngFor="let category of faqs"
            [category]="category"
            (goToFaq)="goToFaq($event)"
          />
        </div>
      </div>

      <div *ngSwitchCase="helpCenterModalViews.FAQ_DETAIL">
        <div class="faq-detail">
          <button class="btn btn-tertiary mb-2" (click)="goToOverview()">
            <span class="rtl-mirrored-inline-svg m-end-1" [inlineSVG]="'./assets/svg/arrow-left-24.svg'"></span>
            <span>{{ 'modals.help_center.return_to_overview' | translate }}</span>
          </button>

          <app-faq-content
            [faq]="faqSelected"
            [category]="categorySelected"
            [noPaddings]="true"
            (goToFaq)="goToFaq($event)"
          />
        </div>
      </div>
    </div>
  </div>
</div>
