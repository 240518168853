<div class="pagination-component d-flex align-items-center">
  <button class="btn"
          id="pagination-action-previous"
          (click)="actionPrev()"
          [ngClass]="{ 'disabled': isFirstPage }">
    <span class="icon rtl-mirrored-inline-svg" [inlineSVG]="'./assets/svg/chevron-left-24.svg'"></span>
  </button>

  <div class="input-wrapper d-flex align-items-center">
    <div class="form-group">

      <input #pageInput="ngModel"
             placeholder=""
             class="form-control"
             id="inputPage"
             type="number"
             [min]="1"
             [max]="totalPages"
             [(ngModel)]="inputValue"
             (ngModelChange)="handleInputChange($event)"
             (keyup)="numberOnly($event)"
             (focus)="onFocus()"
             (blur)="onBlur(pageInput)"
             [disabled]="totalPages <= 1"
      >
    </div>

    <div class="m-start-1" id="total-pages">
      <!--    TODO: RTL -->
      {{ 'components.pagination.of' | translate }} {{ totalPages }}
    </div>
  </div>

  <button class="btn"
          id="pagination-action-next"
          (click)="actionNext()"
          [ngClass]="{ 'disabled': isLastPage }">
    <span class="icon rtl-mirrored-inline-svg" [inlineSVG]="'./assets/svg/chevron-right-24.svg'"></span>
  </button>
</div>
