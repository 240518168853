import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin, Observable } from 'rxjs';
import { Crumb } from '../../models/crumb';
import { Pathway } from '../../models/pathway';
import { Patient } from '../../models/patient';
import { QueryList } from '../../models/query-list';
import { AuthenticationService } from '../../services/authentication.service';
import { HcpService } from '../../services/hcp.service';
import { PathwayService } from '../../services/pathway.service';
import { PatientService } from '../../services/patient.service';
import { TranslateHelp } from '../../pipes/translate-help.pipe';
import { ActivatedRoute, Router } from '@angular/router';
import { Material } from '../../models/material';
import { LanguageService } from '../../services/language.service';
import { MaterialService } from '../../services/material.service';
import { Note } from '../../models/note';
import { NotesService } from '../../services/notes.service';
import { QueryListService } from '../../services/query-list.service';
import { GoalInstance } from '../../models/goal-instance';
import { GoalService } from '../../services/goal.service';
import { HospitalService } from '../../services/hospital.service';
import { CareModule } from '../../models/care-module';
import { DataService } from '../../services/data.service';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html'
})
export class BreadcrumbComponent {
  @Input() public crumbs: Array<Crumb> = [];
  currentCrumbs: Array<Crumb>;

  constructor(
    public hcpService: HcpService,
    public patientService: PatientService,
    public authService: AuthenticationService,
    public pathwayService: PathwayService,
    public translate: TranslateService,
    public router: Router,
    public route: ActivatedRoute,
    public languageService: LanguageService,
    public materialService: MaterialService,
    public notesService: NotesService,
    public goalService: GoalService,
    public queryListService: QueryListService,
    public hospitalService: HospitalService,
    private dataService: DataService
  ) {
    this.route.params.subscribe(queryParams => {
      this.defineCrumbs(route.snapshot, queryParams);
    });
  }

  defineCrumbs(snapshot, params) {
    const page: string = snapshot?.data?.breadcrumb;

    const patientUid = params['patientUid'];
    const pathwayUid = params['patientPathwayUid'];
    const materialUid = params['educationalMaterialUid'];
    const noteUid = params['noteUid'];
    const goalId = params['goalId'];
    const careModuleUid = params['careModuleUid'];

    this.crumbs = [];

    switch (page) {
      case 'patientDetail':
        this.patientDetailCrumb(patientUid).subscribe((patientDetailCrumb: Crumb) => {
          this.crumbs = [this.patientsCrumb(), patientDetailCrumb];
        });
        break;

      case 'timeline':
        this.patientPathwayCrumb(patientUid, pathwayUid).subscribe((patientPathwayCrumb: Crumb) => {
          this.crumbs = [this.patientsCrumb(), patientPathwayCrumb, this.timelineCrumb()];
        });
        break;

      case 'notes':
        this.patientPathwayCrumb(patientUid, pathwayUid).subscribe((patientPathwayCrumb: Crumb) => {
          this.crumbs = [this.patientsCrumb(), patientPathwayCrumb, this.notesCrumb(pathwayUid, pathwayUid)]
        });
        break;

      case 'notesDetail':
        forkJoin([
          this.patientPathwayCrumb(patientUid, pathwayUid),
          this.noteDetailCrumb(patientUid, pathwayUid, noteUid)
        ]).subscribe((data: Crumb[]) => {
          const patientPathwayCrumb = data[0];
          const noteDetailCrumb = data[1];

          this.crumbs = [this.patientsCrumb(), patientPathwayCrumb, this.notesCrumb(patientUid, pathwayUid), noteDetailCrumb]
        });
        break;

      case 'notesNew':
        this.patientPathwayCrumb(patientUid, pathwayUid).subscribe((patientPathwayCrumb: Crumb) => {
          this.crumbs = [this.patientsCrumb(), patientPathwayCrumb, this.notesCrumb(patientUid, pathwayUid), this.noteNewCrumb()]
        });
        break;

      case 'goalDetail':
        forkJoin([
          this.patientPathwayCrumb(patientUid, pathwayUid),
          this.goalCrumb(patientUid, pathwayUid, goalId)
        ]).subscribe((data: Crumb[]) => {
          const patientPathwayCrumb = data[0];
          const goalCrumb = data[1];

          this.crumbs = [this.patientsCrumb(), patientPathwayCrumb, goalCrumb];
        });
        break;

      case 'learningMaterials':
        this.patientPathwayCrumb(patientUid, pathwayUid).subscribe((patientPathwayCrumb: Crumb) => {
          this.crumbs = [this.patientsCrumb(), patientPathwayCrumb, this.materialsCrumb(patientUid, pathwayUid)]
        });
        break;

      case 'learningMaterialsDetail':
        forkJoin([
          this.patientPathwayCrumb(patientUid, pathwayUid),
          this.materialDetailCrumb(materialUid)
        ]).subscribe((data: Crumb[]) => {
          const patientPathwayCrumb = data[0];
          const materialDetailCrumb = data[1];

          this.crumbs = [this.patientsCrumb(), patientPathwayCrumb, this.materialsCrumb(patientUid, pathwayUid), materialDetailCrumb]
        });
        break;

      case 'queryLists':
        forkJoin([
          this.patientPathwayCrumb(patientUid, pathwayUid),
          this.queryListsCrumb(patientUid, pathwayUid)
        ]).subscribe((data: Crumb[]) => {
          const patientPathwayCrumb = data[0];
          const queryListsCrumb = data[1];
          this.crumbs = [this.patientsCrumb(), patientPathwayCrumb, queryListsCrumb];
        });
        break;

      case 'queryListDetail':
        forkJoin([
          this.patientPathwayCrumb(patientUid, pathwayUid),
          this.queryListsCrumb(patientUid, pathwayUid),
          this.queryListDetailCrumb(patientUid, pathwayUid, params['formUid'])
        ]).subscribe((data: Crumb[]) => {
          const patientPathwayCrumb = data[0];
          const queryListsCrumb = data[1];
          const queryListDetailCrumb = data[2];

          this.crumbs = [this.patientsCrumb(), patientPathwayCrumb, queryListsCrumb, queryListDetailCrumb];
        });
        break;

      case 'careModules':
        this.crumbs = [this.adminCrumb(), this.careModulesCrumb()];
        break;

      case 'careModulesDetails':
        this.careModuleDetailsCrumb(careModuleUid).subscribe((careModuleDetailsCrumb: Crumb) => {
          this.crumbs = [this.adminCrumb(), this.careModulesCrumb(), careModuleDetailsCrumb];
        });
        break;

      case 'adminHcps':
        this.crumbs = [this.adminCrumb(), this.adminHcpsCrumb()];
        break;

      case 'adminPatients':
        this.crumbs = [this.adminCrumb(), this.adminPatientsCrumb()];
        break;

      case 'adminTasks':
        this.crumbs = [this.adminCrumb(), this.adminTasksCrumb()];
        break;

      case 'adminRequests':
        this.crumbs = [this.adminCrumb(), this.adminRequestsCrumb()];
        break;

      case 'adminCodes':
        this.crumbs = [this.adminCrumb(), this.adminCodesCrumb()];
        break;

      case 'adminMdts':
        this.crumbs = [this.adminCrumb(), this.adminMdtsCrumb()];
        break;

      case 'analyticsRegistration':
        this.crumbs = [this.analyticsCrumb(), this.analyticsRegistrationCrumb()];
        break;
    }
  }

  analyticsCrumb(): Crumb {
    return new Crumb(this.translate.instant('pages.default.analytics.overview.analytics'), '/analytics');
  }

  analyticsRegistrationCrumb(): Crumb {
    return new Crumb(this.translate.instant('pages.default.analytics.onboarding.title'), '/analytics/registration');
  }

  patientsCrumb(): Crumb {
    const queryParams = this.dataService.get(DataService.BreadCrumbPatientDashboardParams);
    return new Crumb(this.translate.instant('pages.default.dashboard.patients'), '/dashboard', queryParams);
  }

  adminCrumb(): Crumb {
    return new Crumb(this.translate.instant('pages.default.administration.overview.administration'), '/administration');
  }

  careModulesCrumb(): Crumb {
    return new Crumb(this.translate.instant('pages.default.administration.care_modules.care_modules'), '/administration/care-modules');
  }

  adminHcpsCrumb(): Crumb {
    return new Crumb(this.translate.instant('pages.default.administration.hcps.hcps'), '/administration/staff/hcps');
  }

  adminPatientsCrumb(): Crumb {
    return new Crumb(this.translate.instant('pages.default.administration.patients.patients'), '/administration/staff/patients');
  }

  adminTasksCrumb(): Crumb {
    return new Crumb(this.translate.instant('pages.default.administration.tasks.tasks'), '/administration/tasks');
  }

  adminRequestsCrumb(): Crumb {
    return new Crumb(this.translate.instant('pages.default.administration.onboarding_requests.onboarding_requests'), '/administration/patient/onboarding-requests');
  }

  adminCodesCrumb(): Crumb {
    return new Crumb(this.translate.instant('pages.default.administration.onboarding_codes.onboarding_codes'), '/administration/patient/onboarding-codes');
  }

  adminMdtsCrumb(): Crumb {
    return new Crumb(this.translate.instant('pages.default.administration.mdts.mdts'), '/administration/staff/mdts');
  }

  careModuleDetailsCrumb(careModuleUid: string): Observable<Crumb> {
    return new Observable(observer => {
      const careModuleTitle = this.dataService.get(DataService.BreadCrumbCareModuleTitle);

      if (careModuleTitle) {
        observer.next(new Crumb(this.translate.instant(careModuleTitle), `/administration/care-modules/${careModuleUid}`));
        observer.complete();
      } else {
        this.getCareModule(careModuleUid).subscribe(careModule => {
          this.dataService.set(DataService.BreadCrumbCareModuleTitle, careModule.translationKey);
          observer.next(new Crumb(this.translate.instant(careModule.translationKey), `/administration/care-modules/${careModuleUid}`));
          observer.complete();
        });
      }
    });
  }

  patientDetailCrumb(patientUid: string): Observable<Crumb> {
    return new Observable(observer => {
      const fullName = this.dataService.get(DataService.BreadCrumbPatName);

      if (fullName) {
        observer.next(new Crumb(fullName, ''));
        observer.complete();
      }

      if (!fullName) {
        this.getPatient(patientUid).subscribe(patient => {
          this.dataService.set(DataService.BreadCrumbPatName, patient.getFullName());

          observer.next(new Crumb(patient.getFullName(), ''));
          observer.complete();
        });
      }
    })
  }

  patientPathwayCrumb(patientUid: string, pathwayUid: string): Observable<Crumb> {
    return new Observable(observer => {
      let fullName = this.dataService.get(DataService.BreadCrumbPatName);
      let pathway: Pathway = this.dataService.get(DataService.SelectedPathway);
      const observables = [];

      if (!fullName) {
        observables.push(this.getPatient(patientUid));
      }

      if (!pathway) {
        observables.push(this.getPathway(patientUid, pathwayUid))
      }

      if (observables.length === 0) {
        const pathwayName = this.getTranslatedPathwayName(pathway);

        observer.next(new Crumb(`${fullName}(${pathwayName})`, `/patient/${patientUid}`));
        observer.complete();
      }

      if (observables.length) {
        forkJoin(observables).subscribe(result => {
          result.forEach((response: Patient | Pathway) => {
            if (response instanceof Patient) {
              fullName = response.getFullName();
              this.dataService.set(DataService.BreadCrumbPatName, fullName);
            }

            if (response instanceof Pathway) {
              pathway = response;
              this.dataService.set(DataService.SelectedPathway, pathway);
            }
          });

          observer.next(new Crumb(`${fullName}(${this.getTranslatedPathwayName(pathway)})`, `/patient/${patientUid}`));
          observer.complete();
        });
      }
    });
  }

  getTranslatedPathwayName(pathway: Pathway): string {
    const pathwayKey = new TranslateHelp().transform(pathway.care_module.name);
    return this.translate.instant(pathwayKey);
  }

  onSuccessHandlePatientPathwayCrumb(fullName: string, pathway: string, patientUid: string) {
    const crumb = new Crumb(`${fullName}(${pathway})`, `/patient/${patientUid}`);
    this.crumbs.push(crumb);
  }

  timelineCrumb(): Crumb {
    return new Crumb(this.translate.instant('pages.default.timeline.timeline'), '');
  }

  materialsCrumb(patientUid: string, pathwayUid: string): Crumb {
    return new Crumb(this.translate.instant('pages.default.patient_materials.learning_materials'), `/patient/${patientUid}/${pathwayUid}/learning-materials`);
  }

  materialDetailCrumb(materialUid: string): Observable<Crumb> {
    return new Observable(observer => {
      const materialTitle = this.dataService.get(DataService.BreadCrumbMaterialTitle);

      if (materialTitle) {
        observer.next(new Crumb(materialTitle, ''));
        observer.complete();
      } else {
        this.getMaterial(materialUid).subscribe(material => {
          this.dataService.set(DataService.BreadCrumbMaterialTitle, material.title);

          observer.next(new Crumb(material.title, ''));
          observer.complete();
        });
      }
    });
  }

  notesCrumb(patientUid: string, pathwayUid: string): Crumb {
    return new Crumb(this.translate.instant('pages.default.notes.notes'), `/patient/${patientUid}/${pathwayUid}/notes`);
  }

  noteDetailCrumb(patientUid: string, pathwayUid: string, noteUid: string): Observable<Crumb> {
    return new Observable(observer => {
      const noteTitle = this.dataService.get(DataService.BreadCrumbNoteTitle);

      if (noteTitle) {
        observer.next(new Crumb(noteTitle, ''));
        observer.complete();
      } else {
        this.getNote(patientUid, pathwayUid, noteUid).subscribe(note => {
          this.dataService.set(DataService.BreadCrumbNoteTitle, note.title);

          observer.next(new Crumb(note.title, ''));
          observer.complete();
        });
      }
    });
  }

  noteNewCrumb(): Crumb {
    return new Crumb(this.translate.instant('pages.default.notes.new'), '');
  }

  goalCrumb(patientUid: string, pathwayUid: string, goalId: string): Observable<Crumb> {
    return new Observable(observer => {
      const goalTitle = this.dataService.get(DataService.BreadCrumbGoalTitle);

      if (goalTitle) {
        observer.next(new Crumb(this.translate.instant(goalTitle), ''));
        observer.complete();
      } else {
        this.getGoal(patientUid, pathwayUid, goalId).subscribe(goal => {
          this.dataService.set(DataService.BreadCrumbGoalTitle, goal.translationTitleKey);

          observer.next(new Crumb(this.translate.instant(goal.translationTitleKey), ''));
          observer.complete();
        });
      }
    });
  }

  queryListsCrumb(patientUid: string, pathwayUid: string): Observable<Crumb> {
    return new Observable(observer => {
      this.route.queryParams.subscribe(result => {
        const filter = result['filter'];
        const key = (filter === 'QUESTIONNAIRE') ? 'pages.default.dashboard.essential-forms' : 'pages.default.dashboard.checklists';

        observer.next(new Crumb(this.translate.instant(key), `/patient/${patientUid}/${pathwayUid}/query-lists`, { filter: filter }));
        observer.complete();
      });
    });
  }

  queryListDetailCrumb(patientUid: string, pathwayUid: string, formUid: string): Observable<Crumb> {
    return new Observable(observer => {
      const queryListTitle = this.dataService.get(DataService.BreadCrumbQueryListTitle);

      if (queryListTitle) {
        const key = new TranslateHelp().transform(queryListTitle);
        observer.next(new Crumb(this.translate.instant(key), ''));
        observer.complete();
      } else {
        this.getQueryList(patientUid, pathwayUid, formUid).subscribe(queryList => {
          this.dataService.set(DataService.BreadCrumbQueryListTitle, queryList.title);

          const key = new TranslateHelp().transform(queryList.title);
          observer.next(new Crumb(this.translate.instant(key), ''));
          observer.complete();
        });
      }
    });
  }

  ///  PF Requests ////
  private getPatient(patientUid: string): Observable<Patient> {
    return new Observable(observer => {
      const hasCcRole = this.authService.hasCcRole();
      const patientObservable = hasCcRole
        ? this.patientService.getDashboardPatientByHospital(this.hcpService.getCurrentStoredHospitalUid(), patientUid)
        : this.patientService.getDashboardPatientByHcp(this.hcpService.getCurrentStoredHcpUid(), patientUid);

      patientObservable.subscribe(result => {
        observer.next(result);
        observer.complete();
      });
    });
  }

  private getPathway(patientUid: string, pathwayUid: string): Observable<Pathway> {
    return new Observable(observer => {
      const hasCcRole = this.authService.hasCcRole();
      const pathWayObservable = hasCcRole
        ? this.pathwayService.getPathwayByHospital(this.hcpService.getCurrentStoredHospitalUid(), patientUid, pathwayUid)
        : this.pathwayService.getPathwayByHcp(this.hcpService.getCurrentStoredHcpUid(), patientUid, pathwayUid);

      pathWayObservable.subscribe(result => {
        observer.next(result);
        observer.complete();
      });
    });
  }

  private getMaterial(materialUid: string): Observable<Material> {
    return new Observable(observer => {
      const language = this.languageService.getCurrentLanguageCode().locale;

      this.materialService.getMaterial(materialUid, language).subscribe(result => {
        observer.next(result);
        observer.complete();
      });
    });
  }

  private getNote(patientUid: string, pathwayUid: string, noteUid: string): Observable<Note> {
    return new Observable(observer => {
      const hasCcRole = this.authService.hasCcRole();
      const hospitalUid = this.hcpService.getCurrentStoredHospitalUid();
      const hcpUid = this.hcpService.getCurrentStoredHcpUid();

      const notesObservable = hasCcRole
        ? this.notesService.getNoteByCc(hospitalUid, patientUid, pathwayUid, noteUid)
        : this.notesService.getNoteByHcp(hcpUid, patientUid, pathwayUid, noteUid);


      notesObservable.subscribe(result => {
        observer.next(result);
        observer.complete();
      });
    });
  }

  private getGoal(patientUid: string, pathwayUid: string, goalId: string): Observable<GoalInstance> {
    return new Observable(observer => {
      const hasCcRole = this.authService.hasCcRole();
      const hospitalUid = this.hcpService.getCurrentStoredHospitalUid();
      const hcpUid = this.hcpService.getCurrentStoredHcpUid();

      const goalObservable = hasCcRole
        ? this.goalService.getDashboardGoalByHospital(hospitalUid, patientUid, pathwayUid, goalId)
        : this.goalService.getDashboardGoalByHcp(hcpUid, patientUid, pathwayUid, goalId);

      goalObservable.subscribe(result => {
        observer.next(result.goal);
        observer.complete();
      });
    });
  }

  private getQueryList(patientUid: string, pathwayUid: string, formUid: string): Observable<QueryList> {
    const hcpUid = this.hcpService.getCurrentStoredHcpUid();
    return new Observable(observer => {
      this.queryListService.getQueryList(hcpUid, patientUid, pathwayUid, formUid).subscribe(result => {
        observer.next(result);
        observer.complete();
      });
    });
  }

  private getCareModule(careModuleUid): Observable<CareModule> {
    const hospitalUid = this.hcpService.getCurrentStoredHospitalUid();

    return new Observable(observer => {
      this.hospitalService.getCareModule(hospitalUid, careModuleUid).subscribe(result => {
        observer.next(result);
        observer.complete();
      });
    });
  }
}
