import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Appointment } from "../../models/appointment";
import { DateFormat } from "../../models/date-format";
import { UserService } from "../../services/user.service";
import { GeneralService } from "../../services/general.service";
import { BsModalRef } from "ngx-bootstrap/modal";
import { AppointmentService } from '../../services/appointment.service';
import { AuthenticationService } from '../../services/authentication.service';
import { HcpService } from '../../services/hcp.service';
import { LocaleService } from '../../services/locale.service';
import { AttentionCenterService } from '../../services/attention-center.service';
import { cloneDeep } from 'lodash';
import { AppointmentFormModalComponent } from '../appointment-form-modal/appointment-form-modal.component';
import { LanguageService } from '../../services/language.service';
import { ModalService } from '../../services/modal.service';

@Component({
  selector: 'app-appointment-modal',
  templateUrl: './appointment-modal.component.html',
  styleUrls: ['./appointment-modal.component.scss']
})
export class AppointmentModalComponent implements OnInit {
  public appointment: Appointment;
  public dateFormat: DateFormat;
  public time_24_hours: boolean;
  public timeZone: string;
  public showLoaderFor: string;

  constructor(
    public bsModalRef: BsModalRef,
    public modalService: ModalService,
    public generalService: GeneralService,
    public userService: UserService,
    public appointmentService: AppointmentService,
    public authenticationService: AuthenticationService,
    public hcpService: HcpService,
    public localeService: LocaleService,
    public attentionCenterService: AttentionCenterService,
    public languageService: LanguageService
  ) {
  }

  ngOnInit(): void {
    this.timeZone = this.localeService.getLocalePreferences().locale.time_zone;

    this.appointmentService.getAppointment(this.appointment.patient_invitee.uid, this.appointment.uid).subscribe((appointment: Appointment) => {
      if (this.appointment.status_of_user !== appointment.status_of_user) {
        this.appointmentService.onAppointmentStatusChanged.emit(appointment);
        this.attentionCenterService.refresh();
      }
    });
  }

  handleYes() {
    this.changeAppointment(AppointmentService.StatusAppointmentAccepted);
  }

  handleNo() {
    this.handleDecline();
  }

  handleDecline() {
    this.bsModalRef.hide();

    setTimeout(() => {
      this.appointmentService.proposeDeclineAppointment(this.appointment);
    });
  }

  handleCancel() {
    this.showLoaderFor = 'CANCELLED';

    this.appointmentService.cancelAppointment(this.appointment).subscribe(() => { });
    this.bsModalRef.hide();
  }

  handleEdit() {
    this.bsModalRef.hide();
    setTimeout(() => this.openEditFormModal(), 300);
  }

  openEditFormModal() {
    const initialState = {
      type: 'EDIT',
      appointment: cloneDeep(this.appointment, true)
    };

    this.modalService.showWithInterceptor(AppointmentFormModalComponent,
      GeneralService.BsModalOptions({
        class: 'modal-dialog-centered modal-xl',
        initialState
      })
    );
  }

  canSeeStatus(): boolean {
    // return false.  Temp fix for demo.  Nobody can see the status.
    return false;


    // if(this.authenticationService.hasCcRole()) {
    //   return true;
    // }

    // const currentStoredHcpUid = this.hcpService.getCurrentStoredHcpUid();
    // if(currentStoredHcpUid === this.appointment.organiser.uid) {
    //   return true;
    // }

    // return false;
  }

  canRemove(): boolean {
    return false; // Temp disable
    // if(this.authenticationService.hasCcRole() && this.appointment?.hasCancelledState()) {
    //   return true;
    // }

    // const currentStoredHcpUid = this.hcpService.getCurrentStoredHcpUid();
    // if(currentStoredHcpUid === this.appointment.organiser.uid && this.appointment?.hasCancelledState()) {
    //   return true;
    // }

    // return false;
  }

  canDecline(): boolean {
    return false; // Temp disable
    // if(this.appointment?.hasCancelledState()) {
    //   return false;
    // }

    // if(this.appointment?.hasAcceptedState()  ) {
    //   return true;
    // }

    // return false;
  }

  canCancel(): boolean {
    if (this.appointment?.hasCancelledState()) {
      return false;
    }

    if (this.appointment?.cancel_by_user_disabled) {
      return false;
    }

    if (this.authenticationService.hasCcRole()) {
      return true;
    }

    // The hcp is organizer of the appointment
    const currentStoredHcpUid = this.hcpService.getCurrentStoredHcpUid();
    if (currentStoredHcpUid === this.appointment.organiser.uid) {
      return true;
    }

    return false;
  }


  canEdit(): boolean {
    if (this.appointment?.hasCancelledState()) {
      return false;
    }

    if (this.authenticationService.hasCcRole()) {
      return true;
    }

    const currentStoredHcpUid = this.hcpService.getCurrentStoredHcpUid();

    if (currentStoredHcpUid === this.appointment.organiser.uid) {
      return true;
    }
  }


  handleRemove() {
    if (this.showLoaderFor) {
      return;
    }

    this.showLoaderFor = AppointmentService.StatusAppointmentRemoved;

    this.appointmentService.deleteAppointment(this.appointment).subscribe(result => {
      this.showLoaderFor = undefined;
      this.bsModalRef.hide();
      this.appointment.status_of_user = AppointmentService.StatusAppointmentRemoved;
    })
  }

  changeAppointment(status) {

    if (this.showLoaderFor) {
      return;
    }

    this.showLoaderFor = status;

    this.appointmentService.changeAppointment(this.appointment, status).subscribe(result => {
      this.showLoaderFor = undefined;
      this.bsModalRef.hide();
      this.appointment.status_of_user = status;
    })
  }
}
