import { Component, OnInit } from '@angular/core';
import { CareModule } from '../../../../models/care-module';
import { DateFormat } from '../../../../models/date-format';
import { LocaleService } from '../../../../services/locale.service';
import { HospitalService } from '../../../../services/hospital.service';
import { ConfirmModalComponent } from '../../../../modals/confirm-modal/confirm-modal.component';
import { GeneralService } from '../../../../services/general.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { TimedCode } from '../../../../models/timed-code';
import { TimedCodeService } from '../../../../services/timed-code.service';
import {
  GenerateOnboardingCodeModalComponent
} from '../../../../modals/generate-onboarding-code-modal/generate-onboarding-code-modal.component';
import { HcpService } from '../../../../services/hcp.service';
import { TranslateNumberPipe } from '../../../../pipes/translate-number.pipe';
import { LanguageService } from '../../../../services/language.service';
import { PaginationInterface } from '../../../../interfaces/pagination.interface';

@Component({
  selector: 'app-admin-onboarding-codes',
  templateUrl: './admin-onboarding-codes.component.html',
  styleUrls: ['./admin-onboarding-codes.component.scss']
})
export class AdminOnboardingCodesComponent implements OnInit {
  public hospital_uid: string;
  public hcp_uid: string;
  public dateFormat: DateFormat;

  public isLoading: boolean;

  public filtersAreSet: boolean;
  public filters: any;
  public showAllCodes: boolean;

  public timedCodes: Array<TimedCode>;
  public careModules: Array<CareModule>;
  public pagination = {
    current_page: 0,
    page_size: 10
  } as PaginationInterface;

  constructor(
    public generalService: GeneralService,
    public hospitalService: HospitalService,
    public localeService: LocaleService,
    public modalService: BsModalService,
    public hcpService: HcpService,
    public timedCodeService: TimedCodeService,
    public toastrService: ToastrService,
    public translateService: TranslateService,
    public translateNumber: TranslateNumberPipe,
    public languageService: LanguageService
  ) {
  }

  ngOnInit(): void {
    this.dateFormat = this.localeService.getLocalePreferences().dateFormat;

    this.clearFilters();
    this.getScope();
    this.getTimedCodes();
  }

  clearFilters() {
    this.filtersAreSet = false;

    this.filters = {
      care_module_uid: [],
      status: ['ACTIVE']
    };

    this.showAllCodes = false;
  }

  actionResetFilters() {
    this.clearFilters();
    this.getTimedCodes();
  }

  getScope() {
    const hcp = this.hcpService.getCurrentStoredHcp();
    this.hcp_uid = hcp.uid;
    this.hospital_uid = hcp.hospital_uid;

    this.hospitalService.getCareModules(this.hospital_uid).subscribe(careModules => {
      this.careModules = careModules;
    });
  }

  getTimedCodes(showLoading = true): void {
    if (showLoading) {
      this.timedCodes = undefined;
      this.isLoading = true;
    }

    this.timedCodeService.getTimedCodes(this.hospital_uid, this.filters, this.pagination.current_page, this.pagination.page_size, ['end_at,desc'])
      .subscribe(result => {
          this.isLoading = false;
          this.timedCodes = result?.items;

          if (result?.pagination) {
            this.pagination = result.pagination;
          }

          if (this.pagination?.total_pages && this.pagination?.current_page && this.pagination?.total_pages <= this.pagination?.current_page) {
            this.pagination.current_page = 0;
            this.getTimedCodes();
          }
        },
        () => this.isLoading = false
      );
  }

  onPaginationPageChanged(page: number) {
    if (page !== this.pagination.current_page) {
      this.pagination.current_page = page;
      this.getTimedCodes();
    }
  }

  onPaginationRangeChanged(range: number) {
    if (range !== this.pagination.page_size) {
      this.pagination.current_page = 0; // also reset the currentPage
      this.pagination.page_size = range;
      this.getTimedCodes();
    }
  }

  onShowAllCodesCheck() {
    if (this.showAllCodes) {
      delete this.filters.status;
    } else {
      this.filters.status = ['ACTIVE'];
    }

    this.filtersAreSet = true;
    this.pagination.current_page = 0;
    this.getTimedCodes();
  }

  onCareModuleFilterChange() {
    this.filtersAreSet = true;
    this.pagination.current_page = 0;
    this.getTimedCodes();
  }

  actionGenerate(event) {
    event.preventDefault();

    const modalRef = this.modalService.show(GenerateOnboardingCodeModalComponent,
      GeneralService.BsModalOptions({
        class: 'modal-dialog-centered modal-lg',
        initialState: {
          dateFormat: this.dateFormat,
          hospital_uid: this.hospital_uid
        }
      }));

    modalRef.content.onCodeCreated.subscribe(() => {
      this.getTimedCodes(false);
    });
  }

  actionInactivate(event, timed_code_uid: string) {
    event.preventDefault();

    const modalRef = this.modalService.show(ConfirmModalComponent,
      GeneralService.BsModalOptions({
        class: 'modal-dialog-centered',
        initialState: {
          title: 'pages.default.administration.onboarding_codes.inactivate_modal_title',
          description: 'pages.default.administration.onboarding_codes.inactivate_modal_description',
          yes: 'pages.default.administration.onboarding_codes.inactivate_modal_yes'
        }
      })
    );

    modalRef.content.onChoice.subscribe(() => {
      modalRef.content.showYesLoading = true;
      this.timedCodeService.inactivateCode(this.hospital_uid, timed_code_uid).subscribe(
        () => this.onInactivateSuccess(modalRef),
        () => this.onInactivateError(modalRef));
    });
  }

  onInactivateSuccess(modalRef: BsModalRef) {
    this.getTimedCodes(false);
    modalRef.content.showYesLoading = false;
    modalRef.hide();
  }

  onInactivateError(modalRef: BsModalRef) {
    modalRef.content.showYesLoading = false;
    modalRef.hide();
    this.toastrService.error(this.translateService.instant('form.feedback.something_went_wrong'));
  }

  getCodeNotation(timedCode: TimedCode): string {
    return timedCode.hospital_code + '' + timedCode.code;
  }

  actionCopyCode(event, timedCode: TimedCode) {
    event.preventDefault();

    const value = this.getCodeNotation(timedCode);
    this.generalService.copyToClipboard(value);

    this.toastrService.info(this.translateService.instant('pages.default.administration.onboarding_codes.code_copied_to_clipboard'));
  }

  isActive(timedCode: TimedCode): boolean {
    return timedCode.status === 'ACTIVE';
  }

  isInactivated(timedCode: TimedCode): boolean {
    return timedCode.status === 'INACTIVE' && timedCode.reason === 'INACTIVATED';
  }

  isExpired(timedCode: TimedCode): boolean {
    return timedCode.status === 'INACTIVE' && timedCode.reason === 'EXPIRED';
  }
}
