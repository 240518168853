<div class="modal-header">
  <h6 class="h7">{{ 'modals.appointment.new_invites' | translate }}</h6>
  <button type="button" class="btn-close" id="confirm-modal-close" data-dismiss="modal" aria-label="Close"
          (click)="bsModalRef.hide()"></button>
</div>

<div class="modal-body">
  <div class="pending-invitations">
    @for (appointment of appointments; track appointment.uid) {
      <button
        class="single-item-block item-clickable mb-1"
        [id]="'pending-appointment-'+ appointment.uid"
        (click)="appointmentSelectHandler(appointment)"
      >

        <div class="block-icon">
          <div class="date" *ngIf="appointment.start_date || appointment.date">
            <p class="number font-weight-bold text-center text-uppercase mb-0">
              {{ (appointment.start_date || appointment.date) | timeZoneDate:'d' }}
            </p>
            <p class="label font-weight-bold text-uppercase">
              {{ (appointment.start_date || appointment.date) | weekday:true }}
            </p>
          </div>
        </div>

        <div class="meta m-start-3 my-auto" *ngIf="appointment.start_date && !appointment.date">
          <p class="title large font-weight-semibold mb-0">{{ appointment?.title }}</p>
          <p class="description small mb-0">
            {{ appointment.start_date | weekday }},
            {{ appointment.start_date | timeZoneDate }} {{ 'modals.appointment.from' | translate }}
            <span>
              {{ appointment.start_date | time }} - {{ appointment.end_date | time }}
            </span>
          </p>
        </div>

        <div class="meta m-start-3 my-auto" *ngIf="appointment.date && !appointment.start_date">
          <p class="title large font-weight-semibold mb-0">{{ appointment?.patient_invitee.getFullName() }}</p>
          <p class="description small mb-0">
            {{ appointment.date | weekday }},
            {{ appointment.date | timeZoneDate }}
            <span class="text-info font-weight-bold m-start-1">
              {{ 'pages.default.calendar.full_day' | translate }}
            </span>
          </p>
        </div>

        <div class="btn btn-tertiary btn-sm btn-icon m-start-auto m-end-1">
          <span class="rtl-mirrored-inline-svg" [inlineSVG]="'./assets/svg/arrow-right-24.svg'"></span>
        </div>

      </button>
    }
  </div>
</div>
