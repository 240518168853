import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import { HttpClient } from '@angular/common/http';
import {AuthenticationService} from './authentication.service';
import {Observable} from 'rxjs';
import { environment } from '../../environments/environment';
import {TimedCode} from '../models/timed-code';
import { HospitalCode } from '../models/hospital-code';
import { TranslateService } from '@ngx-translate/core';
import { PagingDto } from '../interfaces/paging-dto.interface';

@Injectable({
  providedIn: 'root'
})
export class TimedCodeService extends ApiService {
  private readonly platformUrl: string;

  constructor(
    http: HttpClient,
    public authenticationService: AuthenticationService,
    public translateService: TranslateService
  ) {
    super(http, authenticationService);
    this.platformUrl = environment.platformUrl;
  }

  getHospitalCodes(hospital_uid: string, page: number = 0, size: number = 25): Observable<any> {
    return new Observable(observer => {
      const paramBits = [
        `page=${String(page)}`,
        `size=${String(size)}`
      ];
      const paramsString = paramBits.join('&');

      const url = `${this.platformUrl}/hospitals/${hospital_uid}/self-registration/hospital-codes?${paramsString}`;

      this.authenticatedGet(url).subscribe(result => {
        const hospitalCodes: HospitalCode[] = this.mapHospitalCodes(result)

        if(hospitalCodes.length > 0) {
          observer.next(hospitalCodes);
        } else {
          observer.error();
        }

        observer.complete();
      }, error => {
        observer.error(error);
      });
    });
  }

  getTimedCodes(
    hospital_uid: string,
    filters: any = { hospital_code: [], code: [], care_module_uid: [], status: [] },
    page = 0,
    size = 30,
    sort: string[] = []
  )
    : Observable<PagingDto<TimedCode>>
  {
    return new Observable(observer => {
      const paramBits = [
        `page=${page}`,
        `size=${size}`
      ];

      if (filters.hasOwnProperty('hospital_code')) {
        filters.hospital_code.forEach(hc => {
          paramBits.push(`hospital_code=${hc}`);
        });
      }

      if (filters.hasOwnProperty('code')) {
        filters.code.forEach(c => {
          paramBits.push(`code=${c}`);
        });
      }

      if (filters.hasOwnProperty('care_module_uid')) {
        filters.care_module_uid.forEach(cmi => {
          paramBits.push(`care_module.uid=${cmi}`);
        });
      }

      if (filters.hasOwnProperty('status')) {
        filters.status.forEach(s => {
          paramBits.push(`status=${s}`);
        });
      }

      if (sort.length) {
        sort.forEach(s => {
          paramBits.push(`sort=${s}`);
        });
      }

      const paramsString = paramBits.join('&');
      const url = `${this.platformUrl}/hospitals/${hospital_uid}/self-registration/timed-codes?${paramsString}`;

      this.authenticatedGet(url).subscribe(result => {
        observer.next({
          items: result.items?.map(item => new TimedCode(item)),
          pagination: result.pagination
        });
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }

  createTimedCode(hospital_uid: string, hospital_code_uid: string, start_at: string): Observable<TimedCode> {
    return new Observable(observer => {
      const requestBody = {
        hospital_code_uid,
        start_at
      };

      const url = `${this.platformUrl}/hospitals/${hospital_uid}/self-registration/timed-codes`;

      this.authenticatedPost(url, requestBody).subscribe(result => {
        observer.next(new TimedCode(result));
        observer.complete();
      },
        error => {
          observer.error(error);
        });

    });
  }

  inactivateCode(hospital_uid: string, timed_code_uid: string): Observable<TimedCode> {
    return new Observable(observer => {
      const requestBody = {
        status: 'INACTIVE',
        reason: 'INACTIVATED'
      };
      const url = `${this.platformUrl}/hospitals/${hospital_uid}/self-registration/timed-codes/${timed_code_uid}`;

      this.authenticatedPut(url, requestBody).subscribe(result => {
        observer.next(new TimedCode(result));
        observer.complete();
      },
        error => {
          observer.error(error);
          observer.complete();
        });
    });
  }

  mapHospitalCodes(result: any): Array<HospitalCode> {
    const codes = new Array();
    for (const item of result['items']) {
      const code = new HospitalCode(item);
      codes.push(code);
    }
    return codes;
  }
}
