import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PaginationInterface } from '../../interfaces/pagination.interface';

@Component({
  selector: 'app-pagination-footer',
  templateUrl: './pagination-footer.component.html'
})
export class PaginationFooterComponent {
  @Input() pagination: PaginationInterface;
  @Input() rangeOptions: number[] = [5, 10, 20];
  @Output() pageChange = new EventEmitter<number>();
  @Output() rangeChange = new EventEmitter<number>();

  onPaginationPageChanged(e: number) {
    this.pageChange.emit(e);
  }

  onPaginationRangeChanged(e: number) {
    this.rangeChange.emit(e);
  }
}
