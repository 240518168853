<div class="layout-header-pusher header-back"></div>

<div class="layout-header header-back">
  <div class="header-content">
    <div class="container">
      <app-breadcrumb></app-breadcrumb>
    </div>

    <div class="container">
      <h5 *ngIf="filter === 'QUESTIONNAIRE'">{{ 'pages.default.dashboard.essential-forms' | translate }}</h5>
      <h5 *ngIf="filter === 'CHECKLIST'">{{ 'pages.default.dashboard.checklists' | translate }}</h5>
    </div>
  </div>
</div>

<div class="body-content">
  <div class="container">

    @if (isLoading) {
      <app-page-loader></app-page-loader>
    } @else {
      <div class="row g-2">
        @for (form of queryLists; track form.uid) {
          <div class="col-4">
            <div class="card h-100" [ngClass]="{'card-muted': grayedOut(form)}">

              <div class="card-header">
                <a href=""
                   [routerLink]="['/patient/' + patientUid + '/' + patientPathwayUid + '/' + '/query-lists/' + form.uid]"
                   [queryParams]="{'filter': filter}">{{ form.title | translateHelp | translate }}</a>
              </div>

              <div class="card-body">
                <div class="user-compact" *ngIf="form.candidate_users.length < 2">
                  <app-avatar [profilePicture]="form.assignee?.profile_picture"></app-avatar>

                  <div class="user-compact-info">
                    <p class="title text-truncate">{{ form.assignee?.getFullName() }}</p>
                    <p *ngIf="form.assignee_type === 'PATIENT'">
                      @if (form.assignee_type === 'PATIENT') {
                        {{ 'pages.default.query_lists.patient' | translate }}
                      } @else if (form.isAssignedToHcp() && form.assignee?.uid === hcpUid) {
                        {{ 'pages.default.query_lists.you' | translate }}
                      } @else if (form.isAssignedToHcp() && form.assignee?.uid != hcpUid) {
                        {{ form.assignee?.job_title }}
                      }
                    </p>
                  </div>
                </div>

                @if (form.candidate_users.length > 1) {
                  <app-group-of-avatars [participants]="form.sortedCandidateUsers(hcpUid)" [small]="true"
                                        class="my-1"></app-group-of-avatars>
                }

                <p class="text-muted small mb-0 mt-2" *ngIf="form.status === QueryListStatus.COMPLETED">
                  {{ 'pages.default.query_lists.submitted_on' | translate }} {{ form.completed_at | timeZoneDate }}
                </p>
                <p class="text-muted small mb-0 mt-2" *ngIf="form.status === QueryListStatus.TIMED_OUT">
                  {{ 'pages.default.query_lists.due_at' | translate : { value: form.due_days_ago?.toString() } }}
                </p>

              </div>
              <div class="card-footer">

                @switch (form.status) {
                  @case (QueryListStatus.NEW) {

                    <div class="status-badge badge-sm success">
                      <span class="icon rtl-mirrored-inline-svg" [inlineSVG]="'./assets/svg/dot-24.svg'"></span>
                      {{ 'pages.default.query_lists.status.new' | translate }}
                    </div>
                  }
                  @case (QueryListStatus.SAVED) {
                    <div class="status-badge badge-sm in-progress">
                      {{ 'pages.default.query_lists.status.saved' | translate }}
                    </div>
                  }
                  @case (QueryListStatus.COMPLETED) {
                    <div class="status-badge badge-sm">
                      {{ 'pages.default.query_lists.status.completed' | translate }}
                    </div>
                  }
                  @case (QueryListStatus.TIMED_OUT) {
                    <div class="status-badge badge-sm">
                      {{ 'pages.default.query_lists.status.timed_out' | translate }}
                    </div>
                  }
                }

                <a href="" class="btn btn-tertiary btn-icon m-start-auto"
                   [routerLink]="['/patient/' + patientUid + '/' + patientPathwayUid + '/' + '/query-lists/' + form.uid]"
                   [queryParams]="{'filter': filter}">
                  <span class="rtl-mirrored-inline-svg" [inlineSVG]="'./assets/svg/arrow-right-32.svg'"></span>
                </a>
              </div>
            </div>
          </div>
        } @empty {
          <div class="col">
            <div class="empty-state my-5 my-lg-7 my-xl-9">
              <span [inlineSVG]="'./assets/svg-color/no-notifications-graphic.svg'"></span>
              <h6 class="h7"
                  *ngIf="filter === 'QUESTIONNAIRE'">{{ 'pages.default.essential_forms.no_essential_forms_yet' | translate }}</h6>
              <h6 class="h7"
                  *ngIf="filter === 'CHECKLIST'">{{ 'pages.default.checklists.no_checklists_yet' | translate }}</h6>
            </div>
          </div>
        }
      </div>
    }
  </div>

  @if (!isLoading && pagination?.total_elements) {
    <div class="container align-items-center mt-auto">
      <div class="mt-4"></div>

      <app-pagination-footer
        [pagination]="pagination"
        (pageChange)="onPaginationPageChanged($event)"
        (rangeChange)="onPaginationRangeChanged($event)"
      ></app-pagination-footer>
    </div>
  }
</div>
