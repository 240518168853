import { Component, OnInit } from '@angular/core';
import { CareModule } from '../../../models/care-module';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, take } from 'rxjs/operators';
import { DateFormat } from '../../../models/date-format';
import { LocaleService } from '../../../services/locale.service';
import { HospitalService } from '../../../services/hospital.service';
import { HealthCareProfessional } from '../../../models/health-care-professional';
import { GeneralService } from '../../../services/general.service';
import { PatientService } from '../../../services/patient.service';
import { AuthenticationService } from '../../../services/authentication.service';
import { HcpService } from '../../../services/hcp.service';
import { Patient } from '../../../models/patient';
import { DataService } from '../../../services/data.service';
import { TranslateNumberPipe } from '../../../pipes/translate-number.pipe';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from '../../../services/language.service';
import { AddPatientWizardModalComponent } from '../../../modals/add-patient-wizard-modal/add-patient-wizard-modal.component';
import { ModalService } from '../../../services/modal.service';
import { SortingDirection } from '../../../enums/sorting-direction';
import { ActivatedRoute, Router } from '@angular/router';
import { PaginationInterface } from '../../../interfaces/pagination.interface';

interface Filters {
  last_name?: string;
  care_module_uids?: string[];
  patient_mdt_hcp_uid?: string;
}

@Component({
  selector: 'app-dashboard-page',
  templateUrl: './dashboard-page.component.html',
  styleUrls: ['./dashboard-page.component.scss']
})
export class DashboardPageComponent implements OnInit {
  public isLoading: boolean;
  public isLoadingHCPs: boolean;

  public careModules: Array<CareModule>;
  public hcps: Array<HealthCareProfessional>;
  public patients: Array<Patient>;
  public pagination = {
    current_page: 0,
    page_size: 10
  } as PaginationInterface;

  public dateFormat: DateFormat;

  public filters: Filters = {};
  public patientSorting = 'last_name,asc;first_name,asc';
  public patientSortingOptions = [
    { value: 'care_module_id,asc', translationKey: 'pages.default.dashboard.pathway' },
    { value: 'last_name,asc;first_name,asc', translationKey: 'pages.default.dashboard.name_az' },
    { value: 'last_name,desc;first_name,desc', translationKey: 'pages.default.dashboard.name_za' },
    { value: 'created_at,asc', translationKey: 'pages.default.dashboard.date_added_asc' },
    { value: 'created_at,desc', translationKey: 'pages.default.dashboard.date_added_desc' },
  ];
  public hcpSortingDir: SortingDirection = SortingDirection.ASC;
  public hcpSearchTerm: string;
  public patientLastNameSearch = new Subject<string>();
  public hcpLastNameSearch = new Subject<{ term: string, items: any[] }>();

  public get filtersAreSet(): boolean {
    for (const key in this.filters) {
      if (this.filters[key] && this.filters[key].length) {
        return true;
      }
    }

    return false;
  }

  constructor(
    public localeService: LocaleService,
    public hospitalService: HospitalService,
    public hcpService: HcpService,
    public patientService: PatientService,
    public modalService: ModalService,
    public authenticationService: AuthenticationService,
    public dataService: DataService,
    public translateNumber: TranslateNumberPipe,
    public translateService: TranslateService,
    public languageService: LanguageService,
    public router: Router,
    public activatedRoute: ActivatedRoute
  ) {
  }

  ngOnInit() {
    this.dateFormat = this.localeService.getLocalePreferences().dateFormat;
    this.dataService.clear('selectedPathway');

    this.activatedRoute.queryParams.pipe(take(1)).subscribe(queryParams => {
      this.filters = { ...queryParams };
    });

    this.getCareModules();
    this.getPatients();

    this.patientLastNameSearch.pipe(
      debounceTime(400),
      distinctUntilChanged())
      .subscribe(value => {
        this.pagination.current_page = 0;
        this.filters.last_name = value;
        this.onFilterChange();
      });

    this.hcpLastNameSearch.pipe(
      debounceTime(400),
      distinctUntilChanged())
      .subscribe(value => {
        this.hcpSearchTerm = value.term;
        this.getHCPs();
      });
  }

  getPatients(showLoading = true): void {
    if (showLoading) {
      this.isLoading = true;
      this.patients = undefined;
      this.pagination.total_pages = undefined;
      this.pagination.total_elements = undefined;
    }

    const getPats = this.authenticationService.hasCcRole()
      ? this.patientService.getDashboardPatientsByHospital(
        this.hcpService.getCurrentStoredHospitalUid(), this.filters, this.patientSorting, this.pagination.current_page, this.pagination.page_size)
      : this.patientService.getDashboardPatientsByHcp(
        this.hcpService.getCurrentStoredHcpUid(), this.filters, this.patientSorting, this.pagination.current_page, this.pagination.page_size);

    getPats.subscribe(result => {
      this.isLoading = false;
      this.patients = result.items;

      if (result.pagination) {
        this.pagination = result.pagination;
      }

      if (this.pagination?.total_pages && this.pagination?.current_page && this.pagination?.total_pages <= this.pagination?.current_page) {
        this.pagination.current_page = 0;
        this.getPatients();
      }
    }, () => {
      this.isLoading = false;
    });
  }

  getHCPs() {
    let term = '';

    if (this.hcpSearchTerm) {
      if (this.hcpSearchTerm.length) {
        term = this.hcpSearchTerm;
      } else {
        this.hcps = undefined;
      }
    }

    this.isLoadingHCPs = true;

    this.hcpService.getPaged({ last_name: term }, `last_name,${this.hcpSortingDir}`, 0, 50).subscribe({
      next: result => {
        this.hcps = result.items;
        this.isLoadingHCPs = false;
      }, error: () => this.isLoadingHCPs = false
    });
  }

  getCareModules() {
    const hospitalUid = this.hcpService.getCurrentStoredHospitalUid();
    this.hospitalService.getCareModules(hospitalUid).subscribe(careModules => {
      this.careModules = careModules;
    });
  }

  canCreatePatient() {
    return this.authenticationService.hasCcRole();
  }

  openAddPatientModal() {
    const modalRef = this.modalService.showWithInterceptor(
      AddPatientWizardModalComponent,
      GeneralService.BsModalOptions({
        class: 'modal-dialog-centered modal-xl',
      }),
      GeneralService.BsModalOptions({
        class: 'modal-dialog-centered',
        initialState: {
          title: this.translateService.instant('modals.confirm_add_patient_wizard.title'),
          description: this.translateService.instant('modals.confirm_add_patient_wizard.description'),
          yes: this.translateService.instant('modals.confirm_add_patient_wizard.confirm'),
          no: this.translateService.instant('modals.confirm_add_patient_wizard.cancel')
        }
      })
    );

    modalRef.content.onPatientCreated.subscribe(() => {
      this.getPatients(false);
    });
  }

  customSearchFn() {
    return true; // always return, searching is done at the backend
  }

  actionResetFilters() {
    this.onFilterChange(true);
  }

  onFilterChange(reset: boolean = false) {
    if (reset) {
      this.patientSorting = 'last_name,asc;first_name,asc';

      this.filters = {
        last_name: null,
        care_module_uids: null,
        patient_mdt_hcp_uid: null
      };
    }

    this.pagination.current_page = 0;
    this.getPatients();

    this.updateQueryParams();
  }

  updateQueryParams() {
    const queryParams = {
      last_name: this.filters.last_name?.length ? this.filters.last_name : null,
      care_module_uids: this.filters.care_module_uids?.length ? this.filters.care_module_uids : null,
      patient_mdt_hcp_uid: this.filters.patient_mdt_hcp_uid?.length ? this.filters.patient_mdt_hcp_uid : null
    };

    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams,
      queryParamsHandling: 'merge',
      replaceUrl: true
    });
  }

  onChangeSortDirection() {
    this.getPatients();
  }

  onPaginationPageChanged(page: number) {
    if (page !== this.pagination.current_page) {
      this.pagination.current_page = page;
      this.getPatients();
    }
  }

  onPaginationRangeChanged(range: number) {
    if (range !== this.pagination.page_size) {
      this.pagination.current_page = 0;
      this.pagination.page_size = range;
      this.getPatients();
    }
  }

  updateHcpSortingDirection(dir: SortingDirection) {
    this.hcpSortingDir = dir;
    this.getHCPs();
  }

  clearHcpFilter() {
    this.hcpSearchTerm = '';
    this.getHCPs();
  }
}
