import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from '../../../services/authentication.service';
import { DataService } from '../../../services/data.service';
import { GeneralService } from '../../../services/general.service';
import { HcpService } from '../../../services/hcp.service';
import { PathwayService } from '../../../services/pathway.service';
import { LanguageService } from '../../../services/language.service';
import { MaterialService } from '../../../services/material.service';
import { MaterialPhase } from '../../../models/material-phase';

@Component({
  selector: 'app-patient-materials',
  templateUrl: './patient-materials.component.html',
  styleUrls: ['./patient-materials.component.scss']
})
export class PatientMaterialsComponent implements OnInit {
  public patientUid: string;
  public patientPathwayUid: string;
  public patientPathwayKey: string;

  public isLoading: boolean;
  public flatMaterialPhases: MaterialPhase[] = [];
  public activePhaseID: string;

  constructor(
    public translate: TranslateService,
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public dataService: DataService,
    public materialService: MaterialService,
    public hcpService: HcpService,
    public translateService: TranslateService,
    public languageService: LanguageService,
    public authService: AuthenticationService,
    public pathwayService: PathwayService,
    public generalService: GeneralService
  ) {
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      this.patientUid = params.patientUid;
      this.patientPathwayUid = params.patientPathwayUid;
      this.patientPathwayKey = params.patientPathwayKey;

      this.loadContents();
    });
  }

  loadContents() {
    this.isLoading = true;

    this.materialService.getTranslatedMaterials(this.patientUid, this.patientPathwayUid).subscribe(materialPhases => {
      this.mapToFlatList(materialPhases);
      this.isLoading = false;
    });
  }

  mapToFlatList(materialPhases: MaterialPhase[]) {
    this.flatMaterialPhases = [];

    materialPhases.forEach(materialPhase => {
      this.flatMaterialPhases.push(materialPhase);

      if (materialPhase.sub_phases) {
        materialPhase.sub_phases.forEach(subPhase => this.flatMaterialPhases.push(subPhase));
      }
    });

    if (this.flatMaterialPhases?.length) {
      setTimeout(() => {
        this.updateActiveNav();
      });
    }
  }

  @HostListener('window:scroll')
  onScroll() {
    this.updateActiveNav();
  }

  updateActiveNav() {
    if (!this.flatMaterialPhases?.length) {
      return;
    }

    const bodyContent = document.getElementsByClassName('body-content')[0] as HTMLElement | null;
    const scroll = window.scrollY || document.body.scrollTop || document.documentElement.scrollTop;
    const scrollEnd = document.body.offsetHeight - window.innerHeight;
    const margin = 150;
    let phase: MaterialPhase;

    this.flatMaterialPhases.forEach((item) => {
      const htmlID = `phase_group_${item.phase_id}`;
      const zone = document.getElementById(htmlID);

      if (zone && bodyContent) {
        if (zone.offsetTop - scroll < bodyContent.offsetTop + margin) {
          phase = item;
        }
      }
    });

    if (scroll > 0 && scroll === scrollEnd) {
      phase = this.flatMaterialPhases[this.flatMaterialPhases.length - 1];
    }

    if (phase) {
      this.setActiveNav(phase.phase_id);

    } else {
      this.setActiveNav(this.flatMaterialPhases[0].phase_id);
    }
  }

  setActiveNav(phaseID: string) {
    if (phaseID !== this.activePhaseID) {
      this.activePhaseID = phaseID;
    }
  }

  navClick(event, phase: MaterialPhase) {
    if (event) {
      event.preventDefault();
    }

    const bodyContent: HTMLElement | null = document.getElementsByClassName('body-content')[0] as HTMLElement;
    const bodyContentStyle = getComputedStyle(bodyContent);
    const margin = parseInt(bodyContentStyle.paddingTop) || 40;

    const htmlID = `phase_group_${phase.phase_id}`;

    const targetElement = document.getElementById(htmlID);
    if (targetElement) {
      // Get the top position of the target element relative to the document
      const targetPosition = targetElement.getBoundingClientRect().top + window.pageYOffset;

      // Calculate the final scroll position, adjusting for any offsets
      const scrollPosition = targetPosition - (bodyContent.offsetTop + margin);

      window.scrollTo({
        top: scrollPosition,
        behavior: 'smooth'
      });
    }
  }

  openMaterial(event, material?) {
    event.preventDefault();
    
    this.dataService.set(DataService.KeyLearningMaterialSelected, material);
    this.router.navigate(['/patient', this.patientUid, this.patientPathwayUid, 'learning-materials', 'article', material.reference.key]);
  }
}
