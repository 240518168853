import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from './authentication.service';
import { environment } from '../../environments/environment';
import { CareModule } from '../models/care-module';
import { TranslateService } from '@ngx-translate/core';
import { HealthCareProfessional } from '../models/health-care-professional';
import {Hospital} from '../models/hospital';
import { PagingDto } from '../interfaces/paging-dto.interface';

@Injectable({
  providedIn: 'root'
})
export class HospitalService extends ApiService {

  constructor(
    http: HttpClient,
    authenticationService: AuthenticationService,
    public translateService: TranslateService
  ) {
    super(http, authenticationService);
  }

  private static puttableHcp(hcp: HealthCareProfessional): object {
    if (hcp == null) {
      return;
    }
    return {
      first_name: hcp.first_name,
      last_name: hcp.last_name,
      phone_number: hcp.phone_number,
      gender: hcp.gender,
      profile_picture: hcp.profile_picture,
      language: hcp.language,
      location: hcp.location,
      status: hcp.status,
      function: hcp.function,
      job_title: hcp.job_title
    };
  }

  get(hospitalUid): Observable<Hospital> {
    return new Observable<Hospital>(observer => {
      const url = `${environment.platformUrl}/hospitals/${hospitalUid}`;

      this.authenticatedGet(url).subscribe(result => {
        observer.next(new Hospital(result));
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }

  getCareModules(hospital_uid: string): Observable<Array<CareModule>> {
    return new Observable(observer => {
      this.getCareModulesPaginated(hospital_uid, 0, 999, 'name,desc', true).subscribe(result => {
        observer.next(result.items);
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }

  getCareModulesPaginated(
    hospital_uid: string,
    page = 0,
    size = 10,
    sort = 'name,desc',
    cache = false
  ): Observable<PagingDto<CareModule>> {
    const paramBits = [
      `page=${String(page)}`,
      `size=${String(size)}`
    ];

    if (sort) {
      paramBits.push(`sort=${sort}`);
    }

    const paramsString = paramBits.join('&');
    const url = `${environment.platformUrl}/hospitals/${hospital_uid}/care-modules?${paramsString}`;


    return new Observable(observer => {

      this.authenticatedGet(url, 'v2', cache).subscribe(result => {
        observer.next({
          items: result.items?.map(item => new CareModule(item)),
          pagination: result.pagination
        });
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }


  getCareModule(hospitalUid: string, careModuleUid: string): Observable<CareModule> {
    const urlParts = [];
    const urlParams = [];
    urlParts.push(`${environment.platformUrl}/hospitals/${hospitalUid}/care-modules/${careModuleUid}`);
    const url = urlParts.join('');

    return new Observable(observer => {
      this.authenticatedGet(url, 'v2').subscribe(result => {
        const careModule = new CareModule(result);
        observer.next(careModule);
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });

  }

  changeCareModuleDetails(hospitalUid: string, careModuleUid: string, params): Observable<CareModule> {
    return new Observable(observer => {
      const url = `${environment.platformUrl}/hospitals/${hospitalUid}/care-modules/${careModuleUid}`;

      this.authenticatedPatch(url, params).subscribe(result => {
        observer.next(new CareModule(result));
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }

  createHcp(
    hospital_uid: string,
    params
  ): Observable<HealthCareProfessional> {
    const url = `${environment.platformUrl}/hospitals/${hospital_uid}/hcps`;
    return new Observable(observer => {
      this.authenticatedPost(url, params).subscribe(result => {
        observer.next(new HealthCareProfessional(result));
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }
}
