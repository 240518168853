import { Component, OnDestroy, OnInit } from '@angular/core';
import { BulkApprovalService } from '../../../../services/bulk-approval.service';
import { HcpService } from '../../../../services/hcp.service';
import { Observable, Subject } from 'rxjs';
import { BulkApproval } from '../../../../models/bulk-approval';
import { ConfirmModalComponent } from '../../../../modals/confirm-modal/confirm-modal.component';
import { GeneralService } from '../../../../services/general.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from '../../../../services/user.service';
import { DateFormat } from '../../../../models/date-format';
import { LocaleService } from '../../../../services/locale.service';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { HospitalService } from '../../../../services/hospital.service';
import { CareModule } from '../../../../models/care-module';
import { BulkApprovalModalComponent } from '../../../../modals/bulk-approval-modal/bulk-approval-modal.component';
import { TranslateNumberPipe } from '../../../../pipes/translate-number.pipe';
import { LanguageService } from '../../../../services/language.service';
import { PhoneNumberInterface } from '../../../../interfaces/phone-number.interface';
import { AttentionCenterService } from '../../../../services/attention-center.service';
import { BulkApprovalState } from '../../../../enums/bulk-approval-state';
import { PaginationInterface } from '../../../../interfaces/pagination.interface';


@Component({
  selector: 'app-admin-onboarding-requests',
  templateUrl: './admin-onboarding-requests.component.html',
  styleUrls: ['./admin-onboarding-requests.component.scss']
})
export class AdminOnboardingRequestsComponent implements OnInit, OnDestroy {
  public showPathwaysFilter: boolean;
  public filtersAreSet: boolean;
  public isLoading: boolean;
  public careModules: Array<CareModule>;
  public requests: Array<BulkApproval>;
  public selected: Array<string>;
  public dateFormat: DateFormat;
  public filters: any;
  public showApprovedRequests: boolean;
  public patientLastNameSearch = new Subject<string>();

  public sorting = 'submission_date,desc';
  public sortingLabel = this.translateService.instant('pages.default.administration.onboarding_requests.date_requested');
  public sortingOptions = [
    {
      value: 'submission_date,desc',
      translationKey:
        `${this.sortingLabel}, (${this.translateService.instant('general.sort_dir_desc')})`
    },
    {
      value: 'submission_date,asc',
      translationKey:
        `${this.sortingLabel}, (${this.translateService.instant('general.sort_dir_asc')})`,
    }
  ];

  public pagination = {
    current_page: 0,
    page_size: 20
  } as PaginationInterface;

  constructor(
    public bulkApprovalService: BulkApprovalService,
    public hcpService: HcpService,
    public modalService: BsModalService,
    public translateService: TranslateService,
    public generalService: GeneralService,
    public userService: UserService,
    public hospitalService: HospitalService,
    public localeService: LocaleService,
    public toastrService: ToastrService,
    public translateNumber: TranslateNumberPipe,
    public languageService: LanguageService,
    public attentionCenterService: AttentionCenterService
  ) {
    this.patientLastNameSearch.pipe(
      debounceTime(400),
      distinctUntilChanged())
      .subscribe(value => {
        this.filters.last_name = value;
        this.pagination.current_page = 0;
        this.filtersAreSet = true;
        this.getRequests();
      });
  }

  ngOnInit(): void {
    this.dateFormat = this.localeService.getLocalePreferences().dateFormat;
    this.clearFilters();

    this.getCareModules().subscribe(() => this.getRequests());
  }

  ngOnDestroy() {
    this.patientLastNameSearch.complete();
  }

  clearFilters() {
    this.selected = [];
    this.showApprovedRequests = false;
    this.filtersAreSet = false;

    let selectedCareModule = [];
    if (this.filters && this.filters.care_module_uid) {
      selectedCareModule = this.filters.care_module_uid;
    }

    this.filters = {
      last_name: '',
      approval_status: ['PENDING_APPROVAL'],
      care_module_uid: selectedCareModule,
    };
  }

  actionResetFilters() {
    this.clearFilters();
    this.getRequests();
  }

  getCareModules(): Observable<void> {
    return new Observable(observer => {
      this.hospitalService.getCareModules(this.hcpService.getCurrentStoredHospitalUid()).subscribe(careModules => {
        this.careModules = careModules;
        this.filters.care_module_uid = this.careModules[0].uid;

        observer.next();
        observer.complete();
      }, () => {
        observer.next();
        observer.complete();
      });
    });
  }

  getRequests(showLoading = true): void {
    if (showLoading) {
      this.requests = undefined;
      this.isLoading = true;
    }

    this.bulkApprovalService.getBulkApprovals(
      this.filters, this.sorting, this.pagination.current_page, this.pagination.page_size)
      .subscribe(result => {
        this.isLoading = false;
        this.requests = result.items;

        if (result.pagination) {
          this.pagination = result.pagination;
        }

        if (this.pagination?.total_pages && this.pagination?.current_page && this.pagination?.total_pages <= this.pagination?.current_page) {
          this.pagination.current_page = 0;
          this.getRequests();
        }
      });
  }

  onPaginationPageChanged(page: number) {
    if (page !== this.pagination.current_page) {
      this.pagination.current_page = page;
      this.getRequests();
    }
  }

  onPaginationRangeChanged(range: number) {
    if (range !== this.pagination.page_size) {
      this.pagination.current_page = 0; // also reset the currentPage
      this.pagination.page_size = range;
      this.getRequests();
    }
  }

  onShowApprovedCheck() {
    if (this.showApprovedRequests) {
      this.filters.approval_status = ['PENDING_APPROVAL', 'APPROVED'];
    } else {
      this.filters.approval_status = ['PENDING_APPROVAL'];
    }

    this.filtersAreSet = true;
    this.pagination.current_page = 0;
    this.getRequests();
  }

  onChangeSort(): void {
    this.pagination.current_page = 0;
    this.getRequests();
  }

  onCareModuleFilterChange() {
    this.filtersAreSet = true;
    this.pagination.current_page = 0;
    this.getRequests();
  }

  checked(bulkApproval: BulkApproval) {
    const index = this.selected.indexOf(bulkApproval.uid);
    if (index > -1) {
      this.selected.splice(index, 1);
    } else {
      this.selected.push(bulkApproval.uid);
    }
  }

  isChecked(bulkApproval: BulkApproval) {
    return (this.selected.indexOf(bulkApproval.uid) > -1);
  }

  togglePathwaysFilter(shown: boolean = undefined) {
    if (shown !== undefined) {
      this.showPathwaysFilter = shown;
    } else {
      this.showPathwaysFilter = !this.showPathwaysFilter;
    }
  }

  clickOutsideHeaderFilters() {
    this.togglePathwaysFilter(false);
  }

  bulkActionApprove(event) {
    event.preventDefault();
    if (this.selected.length > 0) {
      this.showBulkApproveModal(this.selected);
    }
  }

  bulkActionDecline(event) {
    event.preventDefault();
    if (this.selected.length > 0) {
      this.showBulkDeclineModal(this.selected);
    }
  }

  showApproveModal(event, onboardingRequest: BulkApproval) {
    event.preventDefault();
    this.showBulkApproveModal([onboardingRequest.uid]);
  }

  showDeclineModal(event, onboardingRequest: BulkApproval) {
    event.preventDefault();
    this.showBulkDeclineModal([onboardingRequest.uid]);
  }

  showBulkApproveModal(onboardingRequestUids: string[]) {
    const modalRef = this.modalService.show(BulkApprovalModalComponent,
      GeneralService.BsModalOptions({
        class: 'modal-dialog-centered modal-lg',
        initialState: {
          patientUids: onboardingRequestUids,
          careModuleUid: this.filters.care_module_uid
        }
      })
    );

    if (modalRef) {
      modalRef.content.requestUpdated.subscribe(() => {
        this.getRequests(false);
        this.selected = [];
        this.attentionCenterService.refresh();
      });
    }
  }

  showBulkDeclineModal(onboardingRequestUids: string[]) {
    const modalRef = this.modalService.show(ConfirmModalComponent,
      GeneralService.BsModalOptions({
        class: 'modal-dialog-centered',
        initialState: {
          title: 'pages.default.administration.onboarding_requests.decline_modal_title',
          description: 'pages.default.administration.onboarding_requests.decline_modal_description',
          yes: 'action.i_am_sure'
        }
      })
    );

    if (modalRef) {
      modalRef.content.onChoice.subscribe(() => {
        modalRef.content.showYesLoading = true;
        this.bulkApprovalService.updateBulkApprovals({
          patient_uids: onboardingRequestUids,
          approval: BulkApprovalState.REJECTED
        }).subscribe(
          () => this.onBulkUpdateSuccess(modalRef, false),
          () => this.onBulkUpdateError(modalRef));
      });
    }
  }

  onBulkUpdateSuccess(modalRef: BsModalRef, accepted: boolean) {
    const msg: string = (accepted)
      ? this.translateService.instant('pages.default.administration.onboarding_requests.approve_success_notif')
      : this.translateService.instant('pages.default.administration.onboarding_requests.approve_error_notif');

    this.toastrService.success(msg);

    this.getRequests(false);
    modalRef.content.showYesLoading = false;
    modalRef.hide();
    this.selected = [];

    this.attentionCenterService.refresh();
  }

  onBulkUpdateError(modalRef: BsModalRef) {
    this.toastrService.error(this.translateService.instant('error.general'));

    modalRef.content.showYesLoading = false;
    modalRef.hide();
  }

  getPhonePrefix(phone: PhoneNumberInterface): string {
    return this.generalService.getDialCodeForCountry(phone.code).label;
  }
}
