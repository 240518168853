import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MedicalTeam } from '../../models/medical-team';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { HealthCareProfessional } from '../../models/health-care-professional';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { MdtService } from '../../services/mdt.service';
import { ToastrService } from 'ngx-toastr';
import { ErrorService } from '../../services/error.service';
import { TranslateService } from '@ngx-translate/core';
import { concat, Observable } from 'rxjs';
import { HcpService } from '../../services/hcp.service';

@Component({
  selector: 'app-edit-mdt-modal',
  templateUrl: './edit-mdt-modal.component.html',
  styleUrls: ['./edit-mdt-modal.component.scss']
})
export class EditMdtModalComponent implements OnInit {
  @Input() mdt: MedicalTeam;
  @Output() mdtEditedEvent = new EventEmitter<void>();

  hcpSearchResults: HealthCareProfessional[] = [];
  originalSelectedHcpMap = new Map<string, boolean>(); // hcp <uuid, isSelected>
  selectedHcpMap = new Map<string, boolean>(); // hcp <uuid, isSelected>
  losslessHcpSelectModel: HealthCareProfessional;
  losslessHcpSelection: HealthCareProfessional[] = [];

  form: UntypedFormGroup;
  validationVisible = false;
  isSaving = false;
  hasChangedHcps = false;
  hcp_uid_error: string = null;

  searchHcpListEvent = new EventEmitter<{ term: string, items: any[] }>();
  isLoadingHcps = false;

  private _closeAnyway = false;
  public get showModalInterceptor(): boolean {
    if (this._closeAnyway) {
      return false;
    }

    return this.form?.dirty || this.hcpsAreValid();
  }

  constructor(
    public readonly bsModalRef: BsModalRef,
    private readonly hcpService: HcpService,
    private readonly mdtService: MdtService,
    private readonly formBuilder: UntypedFormBuilder,
    private readonly toastService: ToastrService,
    private readonly errorService: ErrorService,
    private readonly translate: TranslateService
  ) { }

  ngOnInit(): void {
    if (!this.mdt) {
      this.onHandleClose();
      return;
    }

    this.formSetup();

    this.searchHcpListEvent.pipe(
      debounceTime(400),
      distinctUntilChanged()
    ).subscribe(result => {
      this.searchHcpList(result?.term);
    });

    this.mdt.hcps.forEach(hcp => {
      this.selectedHcpMap.set(hcp.uid, true);
      this.originalSelectedHcpMap.set(hcp.uid, true);
      this.losslessHcpSelection.push(hcp);
    });
  }

  private formSetup(): void {
    if (this.form) {
      return;
    }

    this.form = this.formBuilder.group({
      name: [this.mdt.name, [Validators.required]]
    });
  }

  searchHcpList(searchTerm?): void {
    if (!searchTerm) {
      this.hcpSearchResults = [];
    }

    this.isLoadingHcps = true;

    this.hcpService.getPaged({ last_name: searchTerm || '', status: 'ACTIVE' }, 'last_name,asc', 0, 50).subscribe(
      {
        next: response => {
          this.hcpSearchResults = response.items;
        }, complete: () => this.isLoadingHcps = false
      }
    );
  }

  onHandleClose(): void {
    return this.bsModalRef.hide();
  }

  onAddLosslessHcp(hcp: HealthCareProfessional): void {
    if (hcp && !this.isHcpSelected(hcp)) {
      this.losslessHcpSelection.push(hcp);
      this.selectedHcpMap.set(hcp.uid, true);
      this.hasChangedHcps = true;
    }

    setTimeout(() => {
      this.losslessHcpSelectModel = null;
    });
  }

  onRemoveLosslessHcp($event: MouseEvent, hcp: HealthCareProfessional): void {
    $event.preventDefault();

    const index = this.losslessHcpSelection.indexOf(hcp);

    if (index >= 0) {
      this.losslessHcpSelection.splice(index, 1);
      this.selectedHcpMap.set(hcp.uid, false);
      this.hasChangedHcps = true;
    }
  }

  isHcpSelected(hcp: HealthCareProfessional): boolean {
    return this.selectedHcpMap.get(hcp.uid);
  }

  hcpsAreValid(): boolean {
    return this.losslessHcpSelection?.length > 0;
  }

  onHandleSubmit(): void {
    if (this.isSaving) {
      return;
    }

    if (!this.form.valid || !this.hcpsAreValid()) {
      this.validationVisible = true;
      return;
    }

    if (!this.form.dirty && !this.hasChangedHcps) {
      this.onHandleClose();
      return;
    }

    const updateObservables: Observable<any>[] = [];
    this.isSaving = true;
    this.validationVisible = false;

    if (this.form.get('name').dirty) {
      updateObservables.push(this.updateMdtName(this.form.get('name').value));
    }

    if (this.hasChangedHcps) {

      updateObservables.push(...this.saveHcpChanges());
    }

    if (updateObservables.length === 0) {
      this.isSaving = false;
      this.onHandleClose();
    }

    concat(...updateObservables).subscribe(() => {
      setTimeout(() => this.onSubmitSuccess(), 1000);
    }, error => {
      this.isSaving = false;

      if (error?.error?.errors?.length) {
        this.onRequestError(error);
      } else {
        this._closeAnyway = true;
        this.onHandleClose();
      }
    });
  }

  onSubmitSuccess() {
    this.isSaving = false;
    this.mdtEditedEvent.emit();
    this.showSuccessToast();

    this._closeAnyway = true;
    this.onHandleClose();
  }

  onRequestError(error) {
    const errorArray = error?.error?.errors;
    this.isSaving = false;

    if (errorArray) {
      this.validationVisible = true;

      errorArray.forEach(err => {
        if (err.field === 'hcp_uid' || err.path_variable === 'hcp_uid') {
          this.hcp_uid_error = err.key;
        } else {
          if (this.form.get(err.field)) {
            this.form.get(err.field).setErrors({
              backend_errors: true,
              message: err.key
            });
          }
        }
      });
    }
  }

  private updateMdtName(name: string): Observable<MedicalTeam> {
    const mdt: MedicalTeam = Object.assign({}, this.mdt);
    mdt.name = name;
    return this.mdtService.update(mdt);
  }

  private saveHcpChanges(): Observable<void>[] {
    const removedHcpUids: string[] = [];
    const addedHcpUids: string[] = [];

    this.originalSelectedHcpMap.forEach((value, key) => {
      if (value && !this.selectedHcpMap.get(key)) {
        removedHcpUids.push(key);
      }
    });
    this.selectedHcpMap.forEach((value, key) => {
      if (value && !this.originalSelectedHcpMap.get(key)) {
        addedHcpUids.push(key);
      }
    });

    const actions: Observable<any>[] = [];
    removedHcpUids.forEach(hcp => {
      actions.push(this.mdtService.deleteHcp(this.mdt, hcp));
    });
    addedHcpUids.forEach(hcp => {
      actions.push(this.mdtService.addHcp(this.mdt, hcp));
    });

    return actions;
  }

  private showSuccessToast(): void {
    this.toastService.success(this.translate.instant('modals.mdt.edit_mdt_hcps_success_notification'));
  }

  customSearchFn(term: string, item: any) {
    return true; // always return, searching is done at the backend
  }
}
