import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { ApiService } from './api.service';
import { AuthenticationService } from './authentication.service';
import { GeneralService } from './general.service';
import { LanguageService } from './language.service';
import { QueryList } from './../models/query-list';
import { PagingDto } from '../interfaces/paging-dto.interface';

@Injectable({
  providedIn: 'root'
})
export class QueryListService extends ApiService {
  private readonly platformUrl: string;

  constructor(
    public http: HttpClient,
    public authenticationService: AuthenticationService,
    public languageService: LanguageService,
    public translateService: TranslateService,
    protected generalService: GeneralService,
  ) {
    super(http, authenticationService);
    this.platformUrl = environment.platformUrl;
  }

  getQueryLists(
    hcpUid: string,
    patientUid: string,
    patientPathwayUid: string,
    filters: any = {},
    sort = 'status,desc',
    page = 0,
    size = 30
  )
    : Observable<PagingDto<QueryList>> {
    const paramBits = [
      `page=${String(page)}`,
      `size=${String(size)}`
    ];

    if (sort) {
      paramBits.push(`sort=${sort}`);
    }

    if (filters.hasOwnProperty('query_list_type')) {
      paramBits.push(`query_list_type=${filters.query_list_type}`);
    }

    const paramsString = paramBits.join('&');

    return new Observable(observer => {
      const url = `${this.platformUrl}/hcps/${hcpUid}/patients/${patientUid}/patient-pathways/${patientPathwayUid}/query-lists?${paramsString}`;

      this.authenticatedGet(url).subscribe(result => {
        observer.next({
          items: result.items?.map(item => new QueryList(item)),
          pagination: result.pagination,
        });
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }

  getQueryList(hcpUid: string, patientUid: string, patientPathwayUid: string, queryListUid: string): Observable<QueryList> {
    return new Observable(observer => {
      const url = `${this.platformUrl}/hcps/${hcpUid}/patients/${patientUid}/patient-pathways/${patientPathwayUid}/query-lists/${queryListUid}`;
      this.authenticatedGet(url, 'v2').subscribe(result => {
        observer.next(new QueryList(result));
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }
}
