import { EventEmitter, Injectable, Output } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { ApiService } from './api.service';
import { AuthenticationService } from './authentication.service';
import { DialCode } from '../models/dial-code';
import { DateFormatMap } from '../models/date-format-map';
import { TranslateService } from '@ngx-translate/core';
import { BackButtonData } from '../models/back-button-data';
import { PageTabItem } from '../models/page-tab-item';
import moment from 'moment-timezone';
import { LanguageService } from './language.service';

@Injectable({
  providedIn: 'root'
})
export class GeneralService extends ApiService {
  @Output() onViewRedrawRequest: EventEmitter<any> = new EventEmitter();
  @Output() onPageTabScrollRequest: EventEmitter<{
    pageTabItem: PageTabItem,
    animated: boolean
  }> = new EventEmitter();

  constructor(
    http: HttpClient,
    authenticationService: AuthenticationService,
    public translateService: TranslateService,
    public languageService: LanguageService
  ) {
    super(http, authenticationService);
  }

  public dialCodes: DialCode[] = [];
  public dateFormatMap: DateFormatMap;

  public supportedFileTypes: {
    extensions: Array<string>,
    fileInputAccept: string
  };

  public supportedAudioTypes: {
    extensions: Array<string>,
    fileInputAccept: string
  };

  public static BsModalOptions(input: any = {}) {

    if (!input.animated) {
      input.animated = false;
    }

    if (!input.backdrop) {
      input.backdrop = 'static';
    }

    return input;
  }

  appInitializer() {
    return new Promise((resolve, reject) => {
      forkJoin([
        this.getDialCodes(),
        this.getDateFormats(),
        this.getSupportedFileTypes(),
        this.getSupportedAudioTypes(),
      ]).subscribe(() => {
        resolve(true);
      }, () => {
        reject(true);
      });
    });
  }

  getDialCodes(): Observable<any> {
    this.dialCodes = [];

    return new Observable(observer => {
      const url = `${environment.platformUrl}/general/dial-codes`;

      this.basicAuthGet(url, null, true).subscribe(result => {
        this.dialCodes = this.mapDialCodes(result);
        observer.next(this.dialCodes);
        observer.complete();
      }, () => {
        observer.next(this.dialCodes);
        observer.complete();
      });
    });
  }

  getDateFormats(): Observable<DateFormatMap> {
    this.dateFormatMap = new DateFormatMap({});

    return new Observable(observer => {
      const url = `${environment.platformUrl}/general/date-formats`;

      this.basicAuthGet(url, null, true).subscribe(result => {
        this.dateFormatMap = this.mapDateFormatMap(result);
        observer.next(this.dateFormatMap);
        observer.complete();
      }, () => {
        observer.next(this.dateFormatMap);
        observer.complete();
      });
    });
  }

  getWeekDays(): Observable<Array<any>> {
    return new Observable(observer => {
      const weekdays = [
        { value: 'MONDAY', translationKey: 'dates.weekdays.monday', index: 1 },
        { value: 'TUESDAY', translationKey: 'dates.weekdays.tuesday', index: 2 },
        { value: 'WEDNESDAY', translationKey: 'dates.weekdays.wednesday', index: 3 },
        { value: 'THURSDAY', translationKey: 'dates.weekdays.thursday', index: 4 },
        { value: 'FRIDAY', translationKey: 'dates.weekdays.friday', index: 5 },
        { value: 'SATURDAY', translationKey: 'dates.weekdays.saturday', index: 6 },
        { value: 'SUNDAY', translationKey: 'dates.weekdays.sunday', index: 0 }
      ];

      observer.next(weekdays);
      observer.complete();
    });
  }

  getNumberFormats(): Observable<Array<any>> {
    return new Observable(observer => {
      const numberFormats = [
        { translationKey: 'general.locale_values.number_formats.us', value: 'DECIMAL_POINT' },
        { translationKey: 'general.locale_values.number_formats.eu', value: 'DECIMAL_COMMA' }
      ];

      observer.next(numberFormats);
      observer.complete();
    });
  }

  getTimeFormats(): Observable<Array<any>> {
    return new Observable(observer => {
      const timeFormats = [
        { translationKey: 'general.locale_values.time_formats.twelve', value: false },
        { translationKey: 'general.locale_values.time_formats.twenty_four', value: true }
      ];

      observer.next(timeFormats);
      observer.complete();
    });
  }

  getTimeZones(): Observable<Array<any>> {
    return new Observable(observer => {
      const timeZones = [];

      moment.tz.names().forEach(element => {
        timeZones.push({ label: element, value: element });
      });

      observer.next(timeZones);
      observer.complete();
    });
  }

  getMeasurementUnits(): Observable<Array<any>> {
    return new Observable(observer => {
      const measurementUnits = [
        { label: 'Metric', value: 'METRIC' },
        { label: 'Imperial', value: 'IMPERIAL' }
      ];

      observer.next(measurementUnits);
      observer.complete();
    });
  }

  getSupportedFileTypes(): Observable<null> {
    return new Observable(observer => {
      const url = `${environment.platformUrl}/general/supported-file-types`;

      this.basicAuthGet(url, null, true).subscribe(result => {
        this.supportedFileTypes = this.formatSupportedTypesOutput(result['extensions']);
        observer.next();
        observer.complete();
      }, () => {
        observer.next();
        observer.complete();
      });
    });
  }

  getSupportedAudioTypes(): Observable<null> {
    return new Observable(observer => {
      const url = `${environment.platformUrl}/general/supported-audio-file-types`;

      this.basicAuthGet(url, null, true).subscribe(result => {
        this.supportedAudioTypes = this.formatSupportedTypesOutput(result['extensions']);
        observer.next();
        observer.complete();
      }, () => {
        observer.next();
        observer.complete();
      });
    });
  }

  formatSupportedTypesOutput(extensions: Array<string>): {
    extensions: Array<string>,
    fileInputAccept: string
  } {
    const output = {
      extensions: [],
      fileInputAccept: ''
    };

    if (extensions && extensions.length) {
      output.extensions = extensions;
      output.fileInputAccept = output.extensions.map((element) => {
        return '.' + element;
      }).join(', ');
    }

    return output;
  }

  public get allSupportedTypes(): {
    extensions: Array<string>,
    fileInputAccept: string
  } {
    let extensions: Array<string> = [];

    if (this.supportedFileTypes) {
      extensions = extensions.concat(this.supportedFileTypes.extensions);
    }

    if (this.supportedAudioTypes) {
      extensions = extensions.concat(this.supportedAudioTypes.extensions);
    }

    return this.formatSupportedTypesOutput(extensions);
  }

  statusClass(status: string): string {
    status = status.toLocaleUpperCase();

    if (status === 'PENDING') {
      return 'muted';
    } else if (status === 'REMOVED' || status === 'DECLINED') {
      return 'danger';
    } else if (status === 'ACCEPTED') {
      return 'success';
    } else {
      return 'default';
    }
  }

  mapDialCodes(result: any): Array<DialCode> {
    const dialCodes = [];

    Object.keys(result).forEach(key => {
      dialCodes.push(new DialCode(key, result[key]));
    });

    return dialCodes;
  }

  getDialCodeForCountry(country: string): DialCode {
    return this.dialCodes.find((dialCode) => dialCode.country === country);
  }

  mapDateFormatMap(item): DateFormatMap {
    return new DateFormatMap(item);
  }

  mapPhoneNumberCodeToDialCode(code: string): DialCode {
    for (const dialCode of this.dialCodes) {
      if (dialCode.country === code) {
        return dialCode;
      }
    }
    return null;
  }

  copyToClipboard(value: string) {
    navigator?.clipboard?.writeText(value);
  }

  public openCookieSettings() {
    const btns = document.getElementsByClassName('optanon-show-settings');

    if (btns && btns.length) {
      const btn: HTMLElement = btns[0] as HTMLElement;
      btn.click();
    }
  }

  public goToCookiePolicy() {
    const locale = this.languageService.getCurrentLanguageCode().locale;
    window.open('/cookie-policy?locale=' + locale, '_blank');
  }

  defineBackButton(url: string): BackButtonData {
    let titleKey = 'action.back';

    if (url.startsWith('/tasks')) {
      titleKey = 'action.back_to_tasks';
    }

    if (url.startsWith('/calendar')) {
      titleKey = 'action.back_to_calendar';
    }

    if (url.startsWith('/administration/tasks')) {
      titleKey = 'action.back_to_tasks_admin';
    }

    return new BackButtonData(url, titleKey);
  }

  requestViewRedraw() {
    this.onViewRedrawRequest.emit();
  }

  sortByKey(items, key: string) {
    items.sort((a, b) => {
      const typeA = a[key];
      const typeB = b[key];
      if (typeA < typeB) {
        return -1;
      }
      if (typeA > typeB) {
        return 1;
      }
      return 0;
    });

    return items;
  }

  scrollToPageTabItem(pageTabItem: PageTabItem, animated: boolean = true) {
    setTimeout(() => {
      this.onPageTabScrollRequest.emit({
        pageTabItem,
        animated
      });
    });
  }

  dateToString(date: Date): string {
    if (date) {
      let d: string = String(date.getDate());
      let m: string = String(date.getMonth() + 1);
      const y: string = String(date.getFullYear());

      if (d.length === 1) {
        d = '0' + d;
      }

      if (m.length === 1) {
        m = '0' + m;
      }

      return `${y}-${m}-${d}`;
    } else {
      return null;
    }
  }
}
