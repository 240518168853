<div class="modal-header">
  <h6 class="h7">{{ 'modals.edit_personal_details.title' | translate }}</h6>
  <button type="button" class="btn-close" id="edit-personal-modal-back" data-dismiss="modal" aria-label="Close" (click)="bsModalRef.hide()"></button>
</div>

<div class="modal-body">
  <form
    id="personal-details-form"
    [formGroup]="form"
    [ngClass]="{'validation-visible': validationVisible}"
    (ngSubmit)="handleConfirm()"
    scrollToValidationFeedback
  >

    <!-- Profile picture -->
    <label class="small">{{ 'form.labels.profile_picture' | translate }}</label>
    <div class="control-profile-picture mb-3">
      <app-avatar-input formControlName="profile_picture" />
    </div>

    <!-- Gender -->
    <div class="form-group mb-n2">
      <label class="required">{{ 'form.labels.gender' | translate }}</label>

      <div class="form-checks-inline">
        <div class="form-check">
          <input type="radio" class="form-check-input" id="radioSexMale" formControlName="gender" value="MALE">
          <label class="form-check-label" for="radioSexMale">{{ 'form.labels.male' | translate }}</label>
        </div>

        <div class="form-check">
          <input type="radio" class="form-check-input" id="radioSexFemale" formControlName="gender" value="FEMALE">
          <label class="form-check-label" for="radioSexFemale">{{ 'form.labels.female' | translate }}</label>
        </div>

        <div class="form-check">
          <input type="radio" class="form-check-input" id="radioSexOther" formControlName="gender" value="UNKNOWN">
          <label class="form-check-label" for="radioSexOther">{{ 'form.labels.prefer_not_say' | translate }}</label>
        </div>
      </div>

      <app-feedback-field [field]="form.get('gender')" [extraClass]="'general-feedback'"></app-feedback-field>
    </div>

    <!-- First name -->
    <div class="form-group">
      <label for="inputFirstName" class="required">{{ 'form.labels.first_name' | translate }}</label>
      <input type="text" id="inputFirstName" class="form-control w-100" [placeholder]="('form.labels.first_name' | translate)" formControlName="first_name">
      <app-feedback-field [field]="form.get('first_name')"></app-feedback-field>
    </div>

    <!-- Last name -->
    <div class="form-group">
      <label for="inputLastName" class="required">{{ 'form.labels.last_name' | translate }}</label>
      <input type="text" id="inputLastName" class="form-control w-100" [placeholder]="'.form.labels.last_name' | translate" formControlName="last_name">
      <app-feedback-field [field]="form.get('last_name')"></app-feedback-field>
    </div>

    <!-- Language -->
    <div class="form-group required">
      <app-select
        [placeholder]="('form.labels.language' | translate)"
        [clearable]="false"
        [searchable]="true"
        [hideSelected]="false"
        [multiple]="false"
        [items]="languageOptions"
        [bindValue]="'value'"

        [autoSortOnLabel]="true"
        [selectedItemsOnTop]="false"

        formControlName="language"

        [asFilter]="false"
        [showCheckboxes]="false"
      ></app-select>
      <app-feedback-field [field]="form.get('language')"></app-feedback-field>
    </div>

    <hr class="my-3" />

    <!-- Function -->
    <div class="form-group required" *ngIf="authenticationService.hasCcRole()">
      <app-select
        [placeholder]="('form.labels.function' | translate)"
        [clearable]="false"
        [searchable]="true"
        [hideSelected]="false"
        [multiple]="false"
        [items]="functionOptions"
        [bindValue]="'value'"

        formControlName="function"

        [asFilter]="false"
        [showCheckboxes]="false"
      ></app-select>
    </div>

    <!-- Job Title -->
    <div class="form-group">
      <label for="inputJobTitle" class="required">{{ 'form.labels.job_title' | translate }}</label>
      <input type="text" id="inputJobTitle" class="form-control w-100" [placeholder]="'.form.labels.job_title' | translate" formControlName="job_title">
    </div>

    <!-- Location -->
    <div class="form-group">
      <label for="inputLocation">{{ 'form.labels.location' | translate }}</label>
      <input type="text" id="inputLocation" class="form-control w-100" [placeholder]="'.form.labels.location' | translate" formControlName="location">
    </div>

    <!-- Location URL -->
    <div class="form-group">
      <label for="inputLocation_url">{{ 'form.labels.location_url' | translate }}</label>
      <input type="text" id="inputLocation_url" class="form-control w-100" [placeholder]="'.form.labels.location_url' | translate" formControlName="location_url">
    </div>
  </form>
</div>

<div class="modal-footer">
  <div class="m-start-auto">
    <button class="btn btn-secondary" id="edit-personal-modal-cancel" (click)="handleCancel()">{{ 'action.cancel' | translate }}</button>

    <button class="btn btn-primary m-start-2" id="edit-personal-modal-confirm" [ngClass]="{'loader': isSaving}" type="submit" form="personal-details-form">
      <span class="loader" *ngIf="isSaving" [inlineSVG]="'./assets/svg/loader-dark-sm.svg'"></span>
      <span>{{ 'action.confirm' | translate }}</span>
    </button>
  </div>
</div>
