<div class="layout-header-pusher header-back"></div>

<div class="layout-header header-back">
  <div class="header-content">
    <div class="container">
      <app-breadcrumb></app-breadcrumb>
    </div>

    <div class="container">
      <div class="row justify-content-between align-items-center">
        <div class="col col-auto">
          <h5>{{ 'pages.default.administration.onboarding_codes.onboarding_codes' | translate }}</h5>
        </div>
        <div class="col col-auto">
          <a href="" class="btn btn-primary" id="admin-codes-generate"
             (click)="actionGenerate($event)">{{ 'pages.default.administration.onboarding_codes.generate_new_code' | translate }}</a>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="body-content">
  <div class="container pb-4">
    <div class="row justify-content-between align-items-center">
      <div class="col col-auto">

        <div class="filters-group">
          <div class="form-group">
            <app-select
              id="admin-onboarding-codes-select-care-module"
              class=""
              [selectClass]="'ng-select-sm'"
              [placeholder]="('pages.default.administration.patients.pathway' | translate)"
              [clearable]="true"
              [searchable]="true"
              [hideSelected]="false"
              [multiple]="true"
              [items]="careModules"
              [asFilter]="true"
              [showCheckboxes]="true"
              [bindValue]="'value'"
              [showSortOptions]="true"
              [autoSortOnLabel]="true"
              [(ngModel)]="filters.care_module_uid"
              (ngModelChange)="onCareModuleFilterChange()"
            ></app-select>
          </div>

          <div class="divider"></div>

          <div class="form-group">
            <div class="form-check">
              <input type="checkbox" class="form-check-input" id="checkbox_all_codes" [(ngModel)]="showAllCodes"
                     [ngModelOptions]="{standalone: true}"
                     (change)="onShowAllCodesCheck()">
              <label class="form-check-label"
                     for="checkbox_all_codes">{{ 'pages.default.administration.onboarding_codes.show_all_codes' | translate }}</label>
            </div>
          </div>

          <div class="divider" *ngIf="filtersAreSet"></div>

          <button type="button" class="btn btn-link text-info py-0" id="admin-codes-reset-filters" *ngIf="filtersAreSet"
                  (click)="actionResetFilters()">{{ 'action.reset' | translate }}
          </button>
        </div>

      </div>
    </div>
  </div>

  <div class="container">
    @if (isLoading) {
      <app-page-loader></app-page-loader>
    } @else {
        @for (timedCode of timedCodes; track timedCode.uid) {
          <div id="admin-oboarding-codes-timecode-{{$index}}" class="single-item-block block-compact mb-2"
               [ngClass]="{ 'block-danger': isInactivated(timedCode), 'block-muted': isExpired(timedCode) }">
            <div class="row d-flex align-items-center w-100">
              <div class="col-4">
                <div class="block-wrapper py-2">
                  <p
                    class="label text-truncate e2e-care-module">{{ timedCode?.care_module?.translationKey | translate }}</p>
                  <p class="value text-truncate e2e-valid-until" *ngIf="isActive(timedCode)">
                    @if (timedCode.end_at) {
                      {{ 'pages.default.administration.onboarding_codes.valid_until' | translate }} {{ timedCode.end_at | timeZoneDate:dateFormat.format }}
                    } @else {
                      {{ 'pages.default.administration.onboarding_codes.no_expiry' | translate }}
                    }

                  </p>
                  <p *ngIf="isInactivated(timedCode)"
                     class="text-truncate text-danger e2e-inactivated-on">{{ 'pages.default.administration.onboarding_codes.inactivated_on' | translate }} {{ timedCode.end_at | timeZoneDate:dateFormat.format }}</p>
                  <p *ngIf="isExpired(timedCode)"
                     class="text-truncate e2e-expired-on">{{ 'pages.default.administration.onboarding_codes.expired_on' | translate }} {{ timedCode.end_at | timeZoneDate:dateFormat.format }}</p>
                </div>
              </div>

              <div class="col-4">
                <div class="block-wrapper">
                  <p class="text-truncate e2e-timedcode">{{ getCodeNotation(timedCode) }}</p>
                </div>
              </div>

              <div class="col-4">
                <div class="block-wrapper">
                  <p
                    class="text-truncate e2e-valid-uses">{{ timedCode.statistics.valid_uses }} {{ 'pages.default.administration.onboarding_codes.times_used' | translate }}</p>
                  <p *ngIf="!isActive(timedCode)" class="text-truncate e2e-invalid-uses"
                     [ngClass]="{'text-danger': isInactivated(timedCode)}"
                  >{{ timedCode.statistics.invalid_uses }} {{ 'pages.default.administration.onboarding_codes.expired_attempts' | translate }}</p>
                </div>
              </div>

            </div>

            <div class="block-btn-wrapper m-end-1" style="min-width: 200px;">
              <!-- <a href="" (click)="onShowEditHcpModal($event, hcp)" class="block-action btn btn-lg btn-tertiary btn-icon" [id]="'admin-hcps-edit-hcp-' + hcp.uid">
                <span [inlineSVG]="'./assets/svg/edit-outline-24.svg'"></span>
              </a> -->

              <a *ngIf="isActive(timedCode)" href="" class="block-action btn btn-lg btn-tertiary btn-icon"
                 [id]="'admin-codes-inactivate-code-' + timedCode.uid"
                 (click)="actionInactivate($event, timedCode.uid)">
                <span [inlineSVG]="'./assets/svg/close-24.svg'"></span>
                <span
                  class="m-start-1">{{ 'pages.default.administration.onboarding_codes.inactivate' | translate }}</span>
              </a>
              <a *ngIf="isActive(timedCode)" href="" class="block-action btn btn-lg btn-tertiary btn-icon m-start-1"
                 [id]="'admin-codes-copy-code-' + timedCode.uid" (click)="actionCopyCode($event, timedCode)">
                <span [inlineSVG]="'./assets/svg/copy-24.svg'"></span>
              </a>
            </div>
          </div>
        } @empty {
          <div class="empty-state my-5 my-lg-7 my-xl-9">
            <span [inlineSVG]="'./assets/svg-color/no-notifications-graphic.svg'"></span>
            <h6 class="h7">{{ 'pages.default.administration.onboarding_codes.no_onboarding_codes' | translate }}</h6>
          </div>
        }
    }
  </div>

  @if (!isLoading && pagination?.total_elements) {
    <div class="container align-items-center mt-auto">
      <div class="mt-4"></div>

      <app-pagination-footer
        [pagination]="pagination"
        (pageChange)="onPaginationPageChanged($event)"
        (rangeChange)="onPaginationRangeChanged($event)"
      ></app-pagination-footer>
    </div>
  }
</div>
