import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-pagination-range',
  templateUrl: './pagination-range.component.html'
})
export class PaginationRangeComponent {
  @Input() rangeOptions: number[] = [11, 50, 100];
  @Input() currentRange = this.rangeOptions[0];
  @Input() totalElements: number;
  @Input() currentPage: number;
  @Output() rangeChange = new EventEmitter<number>();

  onRangeChange(event: any) {
    this.rangeChange.emit(this.currentRange);
  }

  get currentElementsRange(): string {
    if (!this.totalElements) {
      return '';
    }

    const startIndex = this.currentPage * this.currentRange + 1;
    const endIndex = Math.min(
      (this.currentPage + 1) * this.currentRange,
      this.totalElements
    );

    return `${startIndex}-${endIndex}`;
  }
}
