import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { MdtService } from '../../../../services/mdt.service';
import { MedicalTeam } from '../../../../models/medical-team';
import { HcpFunction } from '../../../../models/hcp-function';
import { HcpService } from '../../../../services/hcp.service';
import { Router } from '@angular/router';
import { GeneralService } from '../../../../services/general.service';
import { AddMdtModalComponent } from '../../../../modals/add-mdt-modal/add-mdt-modal.component';
import { EditMdtModalComponent } from '../../../../modals/edit-mdt-modal/edit-mdt-modal.component';
import { LanguageService } from '../../../../services/language.service';
import { TranslateService } from '@ngx-translate/core';
import { TranslateNumberPipe } from '../../../../pipes/translate-number.pipe';
import { ModalService } from '../../../../services/modal.service';
import { PaginationInterface } from '../../../../interfaces/pagination.interface';

@Component({
  selector: 'app-admin-mdts',
  templateUrl: './admin-mdts.component.html',
  styleUrls: ['./admin-mdts.component.scss']
})
export class AdminMdtsComponent implements OnInit, OnDestroy {
  public mdts: MedicalTeam[] = [];
  public functions: HcpFunction[] = [];

  public filtersAreSet: boolean;
  public filters: any = {
    name: null,
    functionKeys: null,
    hcpLastName: null
  };
  public functionFilter: HcpFunction[];
  public sortDirectionAsc = true;

  public isLoading: boolean;

  public pagination = {
    current_page: 0,
    page_size: 10
  } as PaginationInterface;

  public sorting = 'name,asc';
  public sortingOptions = [
    {
      value: 'name,desc',
      translationKey:
        `${this.translateService.instant('form.labels.name')}, (${this.translateService.instant('general.sort_dir_desc')})`
    },
    {
      value: 'name,asc',
      translationKey:
        `${this.translateService.instant('form.labels.name')}, (${this.translateService.instant('general.sort_dir_asc')})`
    }
  ];

  public searchMdtNameEvent = new Subject<string>();
  public searchHcpLastNameEvent = new Subject<string>();

  constructor(
    public router: Router,
    public modalService: ModalService,
    public mdtService: MdtService,
    public hcpService: HcpService,
    public translateNumber: TranslateNumberPipe,
    public translateService: TranslateService,
    public languageService: LanguageService
  ) {
    this.searchMdtNameEvent.pipe(
      debounceTime(400),
      distinctUntilChanged())
      .subscribe(value => {
        this.filters.name = value;
        this.pagination.current_page = 0;
        this.filtersAreSet = true;
        this.getMdts();
      });

    this.searchHcpLastNameEvent.pipe(
      debounceTime(400),
      distinctUntilChanged())
      .subscribe(value => {
        this.filters.hcpLastName = value;
        this.pagination.current_page = 0;
        this.filtersAreSet = true;
        this.getMdts();
      });
  }

  ngOnInit(): void {
    this.getAllFunctions();
    this.clearFiltersAndGetMdts();
  }

  ngOnDestroy() {
    this.searchHcpLastNameEvent.complete();
    this.searchHcpLastNameEvent.complete();
  }

  private getAllFunctions(): void {
    this.hcpService.getFunctions(this.hcpService.getCurrentStoredHospitalUid()).subscribe((result: HcpFunction[]) => {
      this.functions = result;
    });
  }

  private clearFiltersAndGetMdts(): void {
    this.filters = {
      name: null,
      functionKeys: null,
      hcpLastName: null
    };
    this.functionFilter = null;
    this.sortDirectionAsc = true;
    this.filtersAreSet = false;
    this.getMdts();
  }

  private getMdts(showLoading = true): void {
    if (showLoading) {
      this.mdts = [];
      this.isLoading = true;
    }

    this.mdtService.getPaged(this.filters, this.sorting, this.pagination.current_page, this.pagination.page_size).subscribe(result => {
      this.isLoading = false;
      this.mdts = result.items;

      if (result.pagination) {
        this.pagination = result.pagination;
      }

      if (this.pagination?.total_pages && this.pagination?.current_page && this.pagination?.total_pages <= this.pagination?.current_page) {
        this.pagination.current_page = 0;
        this.getMdts();
      }
    }, () => {
      this.isLoading = false;
    });
  }

  onPaginationPageChanged(page: number): void {
    if (page !== this.pagination.current_page) {
      this.pagination.current_page = page;
      this.getMdts();
    }
  }

  onPaginationRangeChanged(pageSize: number): void {
    if (pageSize !== this.pagination.page_size) {
      this.pagination.current_page = 0; // also reset the currentPage
      this.pagination.page_size = pageSize;
      this.getMdts();
    }
  }

  onChangeSort() {
    this.pagination.current_page = 0;
    this.getMdts();
  }

  onFunctionFilterChange(hcpFunctions: HcpFunction[]): void {
    this.filtersAreSet = true;
    this.pagination.current_page = 0;

    if (hcpFunctions && hcpFunctions.length) {
      this.filters.functionKeys = [];
      hcpFunctions.forEach(hcpFunc => {
        this.filters.functionKeys.push(hcpFunc.key);
      });
    } else {
      this.filters.functionKeys = null;
    }

    this.getMdts();
  }

  onResetFilters(): void {
    this.clearFiltersAndGetMdts();
  }

  onShowAddMdtModal(event): void {
    event.preventDefault();

    const modalRef = this.modalService.showWithInterceptor(AddMdtModalComponent,
      GeneralService.BsModalOptions({
        class: 'modal-dialog-centered modal-lg',
      }));

    modalRef.content.mdtCreatedEvent.subscribe(() => {
      this.getMdts(false);
    });
  }

  onShowEditMdtModal(event, mdt: MedicalTeam): void {
    const modalRef = this.modalService.showWithInterceptor(EditMdtModalComponent,
      GeneralService.BsModalOptions({
        class: 'modal-dialog-centered modal-lg',
        initialState: {
          mdt: new MedicalTeam(structuredClone(mdt)),
        }
      }));

    modalRef.content.mdtEditedEvent.subscribe(() => {
      this.getMdts(false);
    });
  }
}
