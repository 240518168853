import { Component, EventEmitter, Input, Output, OnInit, OnDestroy } from '@angular/core';
import { NgModel } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html'
})
export class PaginationComponent implements OnInit, OnDestroy {
  @Input() totalPages: number;
  @Input()
  set activePage(activePage: number) {
    this._activePage = this.validatePageNumber(activePage);
    this.inputValue = this.displayPage.toString();
  }

  get activePage(): number {
    return this._activePage;
  }
  @Output() pageChange: EventEmitter<number> = new EventEmitter();

  private _activePage = 0; // Stores 0-based page
  private inputSubject = new Subject<string>();
  private destroy = new Subject<void>();
  private isDebouncing = false;

  public inputValue = '';

  private get displayPage(): number {
    // Get display (1-based) page from internal (0-based) page
    return this._activePage + 1;
  }

  public get isFirstPage(): boolean {
    return !this.activePage || this.activePage <= 0;
  }

  public get isLastPage(): boolean {
    return this.activePage >= this.totalPages - 1;
  }

  constructor() {
    this.inputSubject
      .pipe(
        takeUntil(this.destroy),
        debounceTime(600)
      )
      .subscribe(value => {
        this.isDebouncing = true;
        this.setAndEmitActivePage(this.validatePageNumber(value));
        this.isDebouncing = false;
      });
  }

  ngOnInit(): void {
    this.inputValue = this.displayPage.toString();
  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }

  private toInternalPage(displayPage: number): number {
    // Convert display (1-based) page to internal (0-based) page
    return displayPage - 1;
  }

  validatePageNumber(value: number | string): number {
    if (!value || value === '') {
      return 0;
    }

    if (typeof value === 'string') {
      let displayPage = parseInt(value, 10);

      if (value.startsWith('0') && value.length > 1) {
        displayPage = parseInt(value.substring(1), 10);
      }

      if (isNaN(displayPage) || displayPage <= 0) {
        return 0;
      }

      if (displayPage > this.totalPages) {
        return this.totalPages - 1;
      }

      return this.toInternalPage(displayPage);
    }

    if (value < 0) {
      return 0;
    }
    if (value >= this.totalPages) {
      return this.totalPages - 1;
    }

    return value;
  }

  handleInputChange(value: number) {
    // The input value will be the display value (1-based)
    // We need to convert it to internal value (0-based) before emitting
    if (value === null || value === undefined) {
      return;
    }

    const numericValue = value.toString();
    this.inputValue = numericValue;
    this.inputSubject.next(numericValue);
  }

  numberOnly(event: KeyboardEvent) {
    // This function makes sure you can only enter whitelisted characters.
    // This should be used on input[number].
    // Chrome will not allow you to enter other value then a number or forbiddenKeys.
    // That's why the forbiddenKeys are explicitly blocked.
    // Safari and Firefox allow all characters. They will only throw an error on validation.
    // So we block all keys except 0-9 and the allowedKeys.

    const forbiddenKeys = [',', '.', 'e', '+'];
    const allowedKeys = [
      'Backspace',
      'Delete',
      'Tab',
      'Escape',
      'Enter',
      'ArrowLeft',
      'ArrowRight',
      'ArrowUp',
      'ArrowDown',
      'Home',
      'End',
      '-',
      'Control',
      'Alt',
      'Meta',
      'Shift'
    ];

    if (allowedKeys.includes(event.key)) {
      return;
    }

    if (event.key < '0' || event.key > '9' || forbiddenKeys.includes(event.key)) {
      event.preventDefault();
      return;
    }
  }

  onFocus() {
    this.inputValue = '';
  }

  onBlur(input: NgModel) {
    if (this.isDebouncing) {
      return;
    }

    if (!this.inputValue) {
      this.inputValue = this.displayPage.toString();
      return;
    }

    const validatedPage = this.validatePageNumber(this.inputValue);
    this.setAndEmitActivePage(validatedPage);
    this.inputValue = this.displayPage.toString();
  }

  actionPrev() {
    this.setAndEmitActivePage(this.activePage - 1);
  }

  actionNext() {
    this.setAndEmitActivePage(this.activePage + 1);
  }

  setAndEmitActivePage(page: number) {
    this._activePage = page;
    this.inputValue = this.displayPage.toString();
    this.pageChange.emit(this._activePage); // Emit 0-based page
  }
}
