import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Appointment } from '../../models/appointment';
import { DateFormat } from '../../models/date-format';
import { LocaleService } from '../../services/locale.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AppointmentSelectionEvent } from '../../events/appointment-selection-event';

@Component({
  selector: 'app-calendar-pending-invitations',
  templateUrl: './calendar-pending-invitations.component.html'
})
export class CalendarPendingInvitationsComponent implements OnInit{
  @Input() appointments: Appointment[];
  @Output() onAppointmentSelect: EventEmitter<AppointmentSelectionEvent> = new EventEmitter();

  public dateFormat: DateFormat;
  public time24Hours: boolean;

  constructor(
    public localeService: LocaleService,
    public bsModalRef: BsModalRef,
  ) { }

  ngOnInit() {
    this.dateFormat = this.localeService.getLocalePreferences().dateFormat;
    this.time24Hours = this.localeService.getLocalePreferences().locale.time_24_hours;
  }

  appointmentSelectHandler(appointment: Appointment) {
    this.onAppointmentSelect.emit(new AppointmentSelectionEvent(null, appointment));
  }
}
